import {coordinateConverter, coordinateSystems} from "@/components/mixins/CoordinateConversionMixin";

export const geometryMixin = {
    methods: {
        continueRemoveFeature(type) {
            this.removeFeature = true
            this.showConfirmRemoveFeature = false
            this.activeFeature = this.newFeature
            this.removeActiveFeature(type)
        },
        cancelRemoveFeature() {
            this.showConfirmRemoveFeature = false
            this.toggleFeatures()
        },
        resetFeature() {
            this.activeFeature = ""
            this.newFeature = ""
        },
        checkForDrawnFeature(){
            if(this.drawnFigure){
                this.showConfirmRemoveFeature = true
            }else{
                this.activeFeature = this.newFeature
            }
        },
        removeActiveFeature(type){
            if(this.newPointId > 0){
                this.map.removeMapMarker(this.newPointId, type)
                this.newPointId = -1
            } else if(this.newPolyLineId > 0){
                this.map.disableLineEditing(this.newPolyLineId, type)
                this.newPolyLineId = -1
            } else if(this.newPolygonId > 0){
                this.map.disableAreaEditing(this.newPolygonId, type)
                this.newPolygonId = -1
            }
            this.drawnFigure = false
        },
        undoLastEdit(type) {
            let fetureLength
            if(this.addPointEnabled){
                this.map.removeMapMarker(this.newPointId, type)
                this.newPointId = -1
                this.drawnFigure = false
            } else if(this.addPolylineEnabled){
                this.map.removeLastPointFromPolyline(type, this.newPolyLineId)
                this.map.addPolylineAnchorPoints(type, this.newPolyLineId)
                fetureLength = this.map.getPolylineLength(this.newPolyLineId)
                if (fetureLength > 0) return
                this.newPolyLineId = -1
                this.drawnFigure = false
            } else if(this.addPolygonEnabled){
                this.map.removeLastAddedPointFromPolygon(this.newPolygonId, type)
                this.map.addAnchorPoints(this.newPolygonId, this.OTHER, true)
                fetureLength = this.map.getPolygonLength(this.newPolygonId, type)
                if (fetureLength > 0) return
                this.newPolygonId = -1
                this.drawnFigure = false
            }
        },
        setEnabledFeature: function(feature){
            this.newFeature = feature
            this.checkForDrawnFeature()
            this.toggleFeatures()
        },
        drawExistingFeature(markerStore, type){
            this.drawnFigure = true
            if(this.geometry.point){
                this.activeFeature = 'point'
                let point = this.geometry.point
                let icon = markerStore.getDragAnchorMarkerIcon()
                if(point.x){
                    point = {'lat':point.y, 'lon':point.x}
                }
                this.newPointId = this.newPointId < 0 ? Date.now() : this.newPointId
                this.map.showMapMarker(this.newPointId, type, point.lat,
                    point.lon, icon, true, 5, false)
                this.map.zoomToPosition(point.lat, point.lon)
            }else if(this.geometry.line_string){
                this.activeFeature = 'polyline'
                let linePoints = this.geometry.line_string.points
                let mapped = []
                linePoints.forEach(item => {
                    mapped.push({'lat': item[1], 'lng': item[0]})
                })
                mapped.forEach(point => {
                    if (this.newPolyLineId < 0) {
                        this.newPolyLineId = Date.now()
                        this.map.newPolyLine(type, this.newPolyLineId, point.lat, point.lng)
                    } else {
                        this.map.addPointToPolyLine(type, this.newPolyLineId, point.lat, point.lng)
                    }
                })
                this.map.addPolylineAnchorPoints(type, this.newPolyLineId)
                this.map.zoomToPosition(mapped[0].lat,mapped[0].lng)
            }else if(this.geometry.polygon){
                this.activeFeature = 'polygon'
                this.newPolygonId = Date.now()
                if(this.geometry.polygon.points){
                    this.map.drawPolygon(this.newPolygonId, this.geometry.polygon.points, type, '#829', '#b0cddc', 0.5, 4)
                    this.map.zoomToPosition(this.geometry.polygon.points[0][1], this.geometry.polygon.points[0][0])
                } else if(this.geometry.polygon.rings){
                    this.map.drawPolygon(this.newPolygonId, this.geometry.polygon.rings[0], type, '#829', '#b0cddc', 0.5, 4)
                    this.map.zoomToPosition(this.geometry.polygon.rings[0][0][1], this.geometry.polygon.rings[0][0][0])
                }
                this.map.addAnchorPoints(this.newPolygonId, type, true)
            } else if(this.geometry.lat && this.geometry.lng) {
                this.activeFeature = 'point'
                this.newPointId = Date.now()
                let point = this.geometry
                let icon = markerStore.getDragAnchorMarkerIcon()
                if(point.lat){
                    point = {'lat':point.lat, 'lon':point.lng}
                }
                this.map.showMapMarker(this.newPointId, type, point.lat,
                    point.lon, icon, true, 5, false)
                this.map.zoomToPosition(point.lat, point.lon)
            }
            this.toggleFeatures()
        },
        toggleFeatures(){
            this.addPolygonEnabled = false
            this.addPolylineEnabled = false
            this.addPointEnabled = false
            switch (this.activeFeature){
                case("point"):
                    this.addPointEnabled = true
                    break
                case("polyline"):
                    this.addPolylineEnabled = true
                    break
                case("polygon"):
                    this.addPolygonEnabled = true
            }
        },
        addPoints(type, coord) {
            if (this.addPointEnabled) {
                this.drawPoint(type, coord, this.map.getMarkerStore())
                this.drawnFigure = true
            }
            if (this.addPolylineEnabled) {
                this.drawPolyline(type, coord)
                this.drawnFigure = true
            }
            if (this.addPolygonEnabled) {
                this.drawPolygon(type, coord)
                this.drawnFigure = true
            }
        },
        addLineStringFeature(polylines, id = null, importedItemIndex = null, properties = null) {
            return {
                geometry: {
                    bbox: this.getPolylineBbox(polylines),
                    coordinates: polylines,
                    type: "LineString"
                },
                properties: {
                    ...properties,
                    id: id,
                    importedItemIndex: importedItemIndex
                },
                type: "Feature"
            }
        },
        addFeature(geometry, id = null, importedItemIndex = null, properties = null, type = 'polyline') {
            return {
                geometry: {
                    bbox: type === 'polyline' ? this.getPolylineBbox(geometry) : this.getPointBbox(geometry),
                    coordinates: geometry,
                    type: type === 'polyline' ? "LineString" : "Point"
                },
                properties: {
                    ...properties,
                    id: id,
                    importedItemIndex: importedItemIndex
                },
                type: "Feature"
            }
        },
        addFeatureCollection(geometry, id= null, importedItemIndex = null, properties = null, type = 'polyline') {
            return {
                features: [this.addFeature(geometry, id, importedItemIndex, properties, type)],
                type: "FeatureCollection"
            }
        },
        addEmptyFeatureCollection() {
            return {
                features: [],
                type: "FeatureCollection"
            }
        },
        addExistingFeature(geometry, properties = null, type = 'polyline') {
            return {
                geometry: {
                    bbox: type === 'polyline' ? this.getPolylineBbox(geometry) : this.getPointBbox(geometry),
                    coordinates: geometry,
                    type: type === 'polyline' ? "LineString" : "Point"
                },
                properties: {
                    ...properties,
                },
                type: "Feature"
            };
        },
        setAsLineString(coords) {
            return {
                line_string: {
                    points: coords.map((coord) => {
                        return [parseFloat(coord.lon), parseFloat(coord.lat)];
                    })
                }
            };
        },
        drawPoint(type, point) {
            let icon = this.map.getMarkerStore().getMeasureDistanceMarkerIcon(this.map.getMapType() === 'LEAFLET')
            if(this.newPointId > 0){
                this.map.removeMapMarker(this.newPointId, type)
            } else {
                this.newPointId = Date.now()
            }
            this.$nextTick(() => {
                this.map.showMapMarker(this.newPointId, type, point.lat,
                    point.lng, icon, true, 5, false)
                this.geometry = point
            })
        },
        drawPolyline: function (type, point) {
            // If not exist yet, create one and add to map
            if (this.newPolyLineId < 0) {
                this.newPolyLineId = Date.now()
                this.map.newPolyLine(type, this.newPolyLineId, point.lat, point.lng)
            } else {
                this.map.addPointToPolyLine(type, this.newPolyLineId, point.lat, point.lng)
            }
            this.map.editPolyline(type, this.newPolyLineId)
        },
        drawPolygon: function (type, point) {
            // If not exist yet, create one and add to map
            if (this.newPolygonId < 0) {
                this.newPolygonId = Date.now()
                this.map.newPolygon(this.newPolygonId, point.lat, point.lng, type)
            } else {
                this.map.addPointToPolygon(this.newPolygonId, point.lat, point.lng, type)
            }
            this.map.editArea(this.newPolygonId, type, true)
        },

        addFeatureToAssignment(){
            if(this.newPointId > 0){
                if (this.geometry.lat && this.geometry.lng) {
                    this.geometry = {'point': {
                            'lat': this.geometry.lat ? this.geometry.lat : this.geometry.y,
                            'lon': this.geometry.lng ? this.geometry.lng : this.geometry.x
                        }}
                }
                this.$emit('addDrawnGeometry', this.geometry)
                return true
            } else if(this.newPolyLineId > 0){
                this.geometry = {'line_string': {'points': this.getPolyline(this.OTHER)}}
                this.$emit('addDrawnGeometry', this.geometry)
                return true
            } else if(this.newPolygonId > 0){
                this.geometry = {'polygon':{'points': this.getPolygon()}}
                this.$emit('addDrawnGeometry', this.geometry)
                return true
            }
            return false
        },

        getPolygon: function () {
            let result = []
            let rings = this.map.getPolygonBoundaries(this.newPolygonId, this.OTHER)
            rings.forEach(ring =>  {
                let ringRes = ring.map(item => [item['lng'], item['lat']])
                result.push(ringRes)
            } )
            return result
        },

        getPolyline: function (type) {
            let original = this.map.getPolylineGeometry(type, this.newPolyLineId)
            return original ? original.map(item => [item[1], item[0]]) : null
        },
        getPoint: function (type = this.OTHER) {
            let original = this.map.getPointGeometry(this.newPointId, type)
            return original ? original : null
        },
        addGeometryByClickToast() {
            this.$bvToast.toast(this.$t('orders.add_road_info_by_click'), {
                title: this.$t('map.add_road_by_click'),
                toaster: 'b-toaster-bottom-center',
                id: 'add-geometry-toast-by-click',
                autoHideDelay: 5000,
                solid: true
            });
        },
        addGeometryToast() {
            this.$bvToast.toast(this.$t('map.add_road_info'), {
                title: this.$t('map.add_road'),
                toaster: 'b-toaster-bottom-center',
                id: 'add-geometry-toast',
                autoHideDelay: 5000,
                solid: true
            });
        },
        zoomToGeometry(geometryLayer) {
            let mapCenter = this.setMapCenterByGeometryType(geometryLayer)
            if(mapCenter && mapCenter.x) {
                this.map.zoomToPosition(mapCenter.y, mapCenter.x)
            }
        },
        setMapCenterByGeometryType(geometryLayer) {
            let geometryType = geometryLayer.geometry.features[0].geometry.type
            let mapCenter = {}
            switch (geometryType) {
                case 'LineString': {
                    let coordinates = geometryLayer.geometry.features[0].geometry.coordinates
                    mapCenter = {
                        y: Array.isArray(coordinates[0]) ? coordinates[0][1] : null,
                        x: Array.isArray(coordinates[0]) ? coordinates[0][0] : null,
                    }
                    break;
                }
                case 'Point': {
                    let coordinates = geometryLayer.geometry.features[0].geometry.coordinates
                    mapCenter = {
                        y: coordinates[1] ? coordinates[1] : null,
                        x: coordinates[0] ? coordinates[0] : null,
                    }
                    break;
                }
                case 'MultiLineString':
                case 'Polygon': {
                    let coordinates = geometryLayer.geometry.features[0].geometry.coordinates
                    mapCenter = {
                        y: Array.isArray(coordinates[0][0]) ? coordinates[0][0][1] : null,
                        x: Array.isArray(coordinates[0][0]) ? coordinates[0][0][0] : null,
                    }
                    break;
                }
            }
            return mapCenter
        },
        isBboxOutsideBbox(newBoundingBox, oldBoundingBox) {
            if (!oldBoundingBox || !this.isBBoxValid(oldBoundingBox)) {
                return true
            }
            return (
                newBoundingBox[0] < oldBoundingBox[0] ||
                newBoundingBox[1] < oldBoundingBox[1] ||
                newBoundingBox[2] > oldBoundingBox[2] ||
                newBoundingBox[3] > oldBoundingBox[3]
            )
        },
        isBBoxValid(bbox) {
            return (
                bbox &&
                bbox.length === 4 &&
                !isNaN(bbox[0]) &&
                !isNaN(bbox[1]) &&
                !isNaN(bbox[2]) &&
                !isNaN(bbox[3])
            )
        },
        getPolylineBbox(coordinates) {
            if(!coordinates) {
                return null
            }
            let minLat = Number.MAX_VALUE;
            let maxLat = -Number.MAX_VALUE;
            let minLng = Number.MAX_VALUE;
            let maxLng = -Number.MAX_VALUE;

            for (let i = 0; i < coordinates.length; i++) {
                let lat = coordinates[i][1];
                let lng = coordinates[i][0];

                minLat = Math.min(minLat, lat);
                maxLat = Math.max(maxLat, lat);
                minLng = Math.min(minLng, lng);
                maxLng = Math.max(maxLng, lng);
            }

            return [minLat, minLng, maxLat, maxLng];
        },
        getPolygonBbox(coordinates) {
            if (!coordinates) {
                return null;
            }

            let minLat = Number.MAX_VALUE;
            let maxLat = -Number.MAX_VALUE;
            let minLng = Number.MAX_VALUE;
            let maxLng = -Number.MAX_VALUE;

            // Iterate over the coordinates of the polygon's exterior ring
            coordinates[0].forEach(coord => {
                let lat = coord[1];
                let lng = coord[0];

                minLat = Math.min(minLat, lat);
                maxLat = Math.max(maxLat, lat);
                minLng = Math.min(minLng, lng);
                maxLng = Math.max(maxLng, lng);
            });

            // Return the bounding box as [minLat, minLng, maxLat, maxLng]
            return [minLat, minLng, maxLat, maxLng];
        },
        selectGeometryAssets(loadedGeometries, polygon, isPointInPolygon) {
            let selectedGeometryItems = []
            if(loadedGeometries.length > 0) {
                loadedGeometries.map((loadedGeometry) => {
                    if(loadedGeometry.visibility) {
                        if(loadedGeometry.geometry && loadedGeometry.geometry.features &&
                            loadedGeometry.geometry.features.length > 0) {
                            loadedGeometry.geometry.features.forEach(feature => {
                                if(feature.geometry && feature.geometry.coordinates &&
                                    feature.geometry.coordinates.length > 0 && feature.geometry.type === 'Point') {
                                    // Check if coordinate[1] and coordinate[0] are numbers and convert them if they are not
                                    const lat = Number(feature.geometry.coordinates[1]);
                                    const lng = Number(feature.geometry.coordinates[0]);

                                    // Check if lat and lng are valid numbers
                                    if (!isNaN(lat) && !isNaN(lng)) {
                                        // Use lat and lng in isPointInPolygon function
                                        if (isPointInPolygon(lat, lng, polygon)) {
                                            let geometryItem = {loadedGeometry: loadedGeometry, featureItem: feature}
                                            selectedGeometryItems.push(geometryItem);
                                            return true;
                                        }
                                    }
                                } else if(feature.geometry && feature.geometry.type === 'LineString') {
                                    feature.geometry.coordinates.forEach(coordinate => {
                                        // Check if coordinate[1] and coordinate[0] are numbers and convert them if they are not
                                        const lat = Number(coordinate[1]);
                                        const lng = Number(coordinate[0]);

                                        // Check if lat and lng are valid numbers
                                        if (!isNaN(lat) && !isNaN(lng)) {
                                            // Use lat and lng in isPointInPolygon function
                                            if (isPointInPolygon(lat, lng, polygon)) {
                                                // Check if the combination of importedItemIndex and id already exists in selectedGeometryItems
                                                const exists = selectedGeometryItems.some(item => {
                                                    return item.featureItem.properties.importedItemIndex === feature.properties.importedItemIndex &&
                                                        item.featureItem.properties.id === feature.properties.id;
                                                });

                                                // If the combination does not exist, push the feature into selectedGeometryItems
                                                if (!exists) {
                                                    let geometryItem = {loadedGeometry: loadedGeometry, featureItem: feature}
                                                    selectedGeometryItems.push(geometryItem);
                                                }
                                            }
                                        }
                                    });
                                } else if (feature.geometry && feature.geometry.type === 'Polygon') {
                                    feature.geometry.coordinates[0].forEach(coordinate => {
                                        // Extract lat and lng from the coordinate array
                                        const [lng, lat] = coordinate;

                                        // Check if lat and lng are valid numbers
                                        if (!isNaN(lat) && !isNaN(lng)) {
                                            // Use lat and lng in isPointInPolygon function
                                            if (isPointInPolygon(lat, lng, polygon)) {
                                                // Check if the combination of importedItemIndex and id already exists in selectedGeometryItems
                                                const exists = selectedGeometryItems.some(item => {
                                                    return item.featureItem.properties.importedItemIndex === feature.properties.importedItemIndex &&
                                                        item.featureItem.properties.id === feature.properties.id;
                                                });

                                                // If the combination does not exist, push the feature into selectedGeometryItems
                                                if (!exists) {
                                                    let geometryItem = {loadedGeometry: loadedGeometry, featureItem: feature}
                                                    selectedGeometryItems.push(geometryItem);
                                                }
                                            }
                                        }
                                    });
                                }
                            })
                        }
                    }
                })
            }
            return selectedGeometryItems
        },
        getPointBbox(coordinate) {
            if(!coordinate) {
                return null
            }
            let lat = coordinate[1];
            let lng = coordinate[0];

            return [lat, lng, lat, lng];
        },

        transformCoordinates: function (coordinates, coordinateSystemToTransform) {
            // Helper function to handle different geometry types
            if (typeof coordinates[0] === 'number' || typeof coordinates[0] === 'string') {
                // It's a single coordinate pair, like in a Point
                return coordinateSystemToTransform === coordinateSystems.TM35_FIN ? coordinateConverter.methods.wgs84ToTm35Fin(coordinates[1], coordinates[0]) : coordinates;
            } else if (Array.isArray(coordinates[0])) {
                // It's an array of coordinates, like in a LineString or Polygon
                return coordinates.map(coord => this.transformCoordinates(coord, coordinateSystemToTransform));
            }
        },

        transformBbox: function (bbox, coordinateSystemToTransform) {
            // Assuming bbox is an array of 4 or 6 numbers: [minX, minY, maxX, maxY, (minZ, maxZ)]
            let transformedBbox = [];

            // Convert the minX, minY and maxX, maxY points
            if (coordinateSystemToTransform === coordinateSystems.TM35_FIN) {
                const [minX, minY] = coordinateConverter.methods.wgs84ToTm35Fin(bbox[1], bbox[0]);
                const [maxX, maxY] = coordinateConverter.methods.wgs84ToTm35Fin(bbox[3], bbox[2]);
                transformedBbox = [minX, minY, maxX, maxY];

                // If bbox includes Z coordinates (3D), add them unaltered (assuming they're unchanged)
                if (bbox.length === 6) {
                    transformedBbox.push(bbox[4], bbox[5]);
                }
            } else {
                // If the coordinate system is WGS84, return the bbox as-is
                transformedBbox = bbox;
            }

            return transformedBbox;
        },

        async downloadGeoJson(layer, coordinateSystem) {
            if (layer) {
                let geoJsonData = this.getGeometryAsGeoJson(layer, coordinateSystem);
                if (geoJsonData) {
                    // Generate GeoJSON and download
                    let blob = new Blob([JSON.stringify(geoJsonData)], { type: 'application/json;charset=utf-8;' });
                    let url = URL.createObjectURL(blob);
                    let pom = document.createElement('a');
                    pom.href = url;
                    let downloadFileName = layer.name ? layer.name + '.geojson' : 'Routa_geometry.geojson';
                    pom.setAttribute('download', downloadFileName);
                    pom.click();
                }
            }
        },

        getGeometryAsGeoJson: function (layer, coordinateSystem) {
            let featureCollection = this.addEmptyFeatureCollection();

            // Check if layer.geometry.features or layer.features exists
            let features = (layer.geometry && layer.geometry.features) ? layer.geometry.features : layer.features;

            // Ensure features exist before processing
            if (features && features.length > 0) {
                features.forEach(feature => {
                    let transformedFeature = JSON.parse(JSON.stringify(feature));  // Create a copy of the feature to avoid mutating the original

                    if (transformedFeature.geometry) {
                        // Transform geometry coordinates
                        if (transformedFeature.geometry.coordinates) {
                            transformedFeature.geometry.coordinates = this.transformCoordinates(
                                transformedFeature.geometry.coordinates, coordinateSystem
                            );
                        }

                        // Transform bbox if it exists
                        if (transformedFeature.geometry.bbox) {
                            transformedFeature.geometry.bbox = this.transformBbox(
                                transformedFeature.geometry.bbox, coordinateSystem
                            );
                        }
                    }

                    featureCollection["features"].push(transformedFeature);
                });
            }

            return featureCollection;
        },

        isLineString(geometry) {
            return geometry &&
                geometry.features &&
                geometry.features.length > 0 &&
                geometry.features[0].geometry &&
                geometry.features[0].geometry.type &&
                (geometry.features[0].geometry.type === 'LineString' || geometry.features[0].geometry.type === 'MultiLineString')
        },
        getHighestArrayValue(array, key) {
            let arr = array.map(item => item[key]).filter(number => number !== undefined);
            let largest = Math.max(...arr)
            return isFinite(largest) ? largest : 0
        },
    }
}
