<template>
        <b-row v-if="areaPrice" :class="{ 'error-border-color': errorRow }" class="nopads mt-2 ">
            <div class="col-3 nopads pr-2">
                <b-form-select
                    id="area"
                    v-model="areaPrice.area.id"
                    size="sm"
                    class="sm-3"
                >
                    <option
                        v-for="area in areas"
                        :key="area.id"
                        :value="area.id"
                        @change="updateEvent"
                    >
                        {{ area.name }}
                    </option>
                </b-form-select>
            </div>
            <div class="col-3 nopads pr-2">
                <b-form-select
                    id="area"
                    v-model="areaPrice.task_type.id"
                    @change="updateEvent"
                    size="sm"
                    class="sm-3"
                >
                    <option
                        v-for="taskType in taskTypes"
                        :key="taskType.id"
                        :value="taskType.id"
                    >
                        {{ taskType.name }}
                    </option>
                </b-form-select>
            </div>
            <div class="col-3 nopads pl-2 pr-2">
                <b-input-group size="sm" :append="currency">
                    <b-form-input
                        id="price"
                        v-model="priceValue"
                        type="number"
                        @blur="updateEvent"
                    />
                </b-input-group>
            </div>
        </b-row>
</template>

<script>

export default {
    name: 'AreaPriceSelector',
    props: {
        index: {
            type: Number
        },
        errorRow: {
            type: Boolean,
            default: false
        },
        priceItem: {
            type: Object,
            default: null
        },
        areas: {
            type: Array,
            default() {
                return []
            }
        },
        taskTypes: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            areaPrice: null,
            priceValue: 0
        }
    },
    computed: {
        currency() {
            return process.env.VUE_APP_CURRENCY || '€'
        }
    },
    mounted: function () {
        this.areaPrice = this.priceItem
        this.priceValue = this.areaPrice.price ? this.areaPrice.price / 100.0 : 0
    },

    methods: {
        updateEvent() {
            if (this.priceValue) {
                this.areaPrice.price = (this.priceValue * 100).toFixed(1)
            } else {
                this.areaPrice.price = null
            }
            this.$emit('priceUpdated', this.index, this.areaPrice)
            this.$emit('updateEvent')
        }
    }
}
</script>
