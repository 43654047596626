<template>
<div class="col-12 comment-editor nopads">
    <b-row v-if="photo">
        <div style="height: 15em" class="mt-1 mb-2">
            <b-img
                :src="photo"
                :fluid="true"
                center
                class="m-auto"
                style="border: none; height: 100% !important;"
            />
        </div>
    </b-row>
    <b-row class="nopads">
        <b-form-textarea
            class="col-12"
            v-model="comment"
            :placeholder="$t('comments.write_comment')"
            :rows="3"/>
    </b-row>
    <b-row class="nopads pt-2 pb-2">
        <b-col sm class="nopads pr-1">
            <b-form-file
                v-model="file"
                accept="image/jpeg, image/jpg, image/png"
                size="sm"
                @change="onFileChange"
                :browse-text="$t('common.add_photo')"
                :placeholder="$t('equipment.photo_placeholder')"
            />
        </b-col>
        <b-col sm class="nopads pr-1">
            <div class="col-12 nopads text-right">
                <b-button
                    variant="secondary"
                    class="form-button"
                    size="sm"
                    @click="resetData">
                    {{ $t('common.clear')}}
                </b-button>
                <b-button
                    variant="success"
                    class="form-button"
                    size="sm"
                    :disabled="loading || (!comment || comment.length < 1)"
                    @click="submitComment">
                    {{ $t('common.send')}}
                </b-button>
            </div>
        </b-col>
    </b-row>
    <div
        v-if="loading"
        id="loader"
        class="spinner"
    />
</div>

</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'CommentEditor',
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            comment: '',
            photo: null,
            file: null,
            loading: false
        }
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        topic: {
            type: Number,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        type: {
            type: Number,
            required: false
        },
        customApiUrl: {
            type: String,
            required: false,
            default: null
        }
    },

    methods: {

        submitComment() {
            if (this.comment) {
                const comment = {
                    topic: this.topic,
                    record_id: this.id,
                    type: this.type,
                    comment: this.comment,
                    photo: this.photo
                }
                this.loading = true
                this.restAdd(this.customApiUrl ? this.customApiUrl : this.commentUrl, comment, this.success, this.fail)
            }
        },

        success() {
            this.loading = false
            this.$emit('commentAdded')
            this.resetData()
        },

        fail() {
            this.loading = false
            // TODO - Show error to user
        },

        resetData() {
            this.comment = null
            this.file = null
            this.photo = null

        },

        onFileChange(event) {
            const file = event.target.files[0]; // Get the first selected file
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.photo = e.target.result; // Base64 string
                };
                reader.onerror = () => {
                    // TODO - Inform user about error
                };
                reader.readAsDataURL(file); // Read file as Base64
            } else {
                this.photo = null
            }
        },
    }
}
</script>
<style>

.comment-editor {
    border-top: 1px solid #d9d9d9;
    margin-top: .35em;
    padding-top: .35em;
    background: #ffffff;
    margin-bottom: .25em;
}

</style>
