<template>
    <div class="content-container">
        <b-navbar variant="faded">
            <div class="header">
                <span class="title">
                    {{ $t('license_report.title') }}
                </span>
            </div>
        </b-navbar>
        <div class="search-form">
        <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-row>
            <div class="col-sm-6">
                <span>{{ $t('license_report.date') }}</span>
                <b-form-select
                    v-model="month"
                    size="sm"
                    class="mb-2"
                    :options="months"
                >
                </b-form-select>
            </div>
            <div class="col-sm-6">
                <span>{{ $t('license_report.year') }}</span>
                <b-form-select
                    v-model="year"
                    size="sm"
                    class="mb-2"
                    :options="years"
                >
                </b-form-select>
            </div>
        </b-row>
        <b-row v-if="isAdmin" class="nopads pl-3 pr-3">
            <span >
                {{ $t('users.license_holder_title') }}
            </span>
            <company-selector
                v-if="isAdmin"
                v-model="company"
                :placeholder="this.$t('users.license_holder_title')"
            />
        </b-row>
        <div class="button-container pr-3">
            <b-button
                class="result-button"
                @click.stop="fetchUsers"
                variant="primary"
            >
                {{ $t('common.search_verb') }}
            </b-button>
        </div>
        </div>

        <div class="header col-sm-12 button-container pt-1">
            <font-awesome-icon
                v-if="users.length > 0"
                icon="print"
                class="print-icon"
                @click.stop="print"
            />
        </div>
        <div id="printContainer"
             class="report"
             v-if="users.length > 0">
            <h1>{{ $t('license_report.title') }}</h1>
            <div class="hint-text pb-2">
                {{ $t('license_report.parenthesis_description') }}
            </div>
            <table class="table table-sm table-fixed" style="font-size: .9em">
                <tr>
                    <td class="item-detail-title">
                        {{ $t('users.company_super_user')  }}
                    </td>
                    <td class="item-detail-text">
                        {{ this.superusers[0] }}
                        <span v-if="superusers[1] > 0">
                            ({{this.superusers[1]}})
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="item-detail-title">
                        {{ $t('users.observer') }}
                    </td>
                    <td class="item-detail-text">
                        {{ this.observers[0] }}
                        <span v-if="observers[1] > 0">
                            ({{this.observers[1]}})
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="item-detail-title">
                        {{ $t('users.worker') }}
                    </td>
                    <td class="item-detail-text">
                        {{ this.workers[0] }}
                        <span v-if="workers[1] > 0">
                            ({{workers[1]}})
                        </span>
                    </td>
                </tr>
            </table>
            <h2>{{ $t('license_report.title_users') }}</h2>
            <div
                class="col-sm-12 list-content result-table"
            >
                <license-report-table
                    :users="users"
                />
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin';
import {Roles, userHelper} from "../mixins/UserMixin";
import CompanySelector from '../company/CompanySelector';
import LicenseReportTable from './LicenseReportTable';
import {printHelper} from "../mixins/PrintMixin";
import {Printd} from 'printd'
import {EventBus} from "@/event-bus";

export default {
    name: 'LicenseReport',
    components: {CompanySelector, LicenseReportTable},
    mixins: [restApi, userHelper, printHelper],
    props: {
        isAdmin: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        this.d = new Printd()
    },
    data() {
        return {
            users: [],
            company: null,
            superusers: [0, 0],
            observers: [0, 0],
            workers: [0, 0],
            months: [
                { value: 0, text: '01' },
                { value: 1, text: '02' },
                { value: 2, text: '03' },
                { value: 3, text: '04' },
                { value: 4, text: '05' },
                { value: 5, text: '06' },
                { value: 6, text: '07' },
                { value: 7, text: '08' },
                { value: 8, text: '09' },
                { value: 9, text: '10' },
                { value: 10, text: '11' },
                { value: 11, text: '12' }
            ],
            years: [
                {value: 2022, text: '2022'},
                {value: 2023, text: '2023'},
                {value: 2024, text: '2024'},
                {value: 2025, text: '2025'}
            ],
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            loading: false
        }
    },
    computed: {
        userRoles() {
            const roles = JSON.parse(JSON.stringify(Roles));
            delete roles.ADMIN;
            delete roles.PRO_APP_USER;
            delete roles.MAP_VIEWER;
            return roles;
        }
    },
    methods: {
        print: function () {
            if (!this.loading) {
                this.d.print(document.getElementById('printContainer'), this.printStyle)
            }
        },
        initRoleSummary() {
            this.superusers = [0, 0]
            this.observers = [0, 0]
            this.workers = [0, 0]
            this.users.forEach(user => {
                if (user.superuser) {
                    this.superusers[0]++
                }
                if (user.observer) {
                    this.observers[0]++
                    if (user.worker) {
                        this.observers[1]++
                    }
                } else if (user.worker) {
                    this.workers[0]++
                }
            })
        },

        async fetchUsers() {
            let date = new Date()
            date.setDate(1)
            date.setMonth(this.month)
            date.setFullYear(this.year)
            if(this.isAdmin && !this.company) {
                EventBus.$emit('show-alert', this.$t('work_time.error_company_missing'))
                return;
            }
            this.loading = true;
            const { err, ...response } = await this.fetchLicenseReportUsers(date.toISOString(), this.company && this.company.id).catch((err) => ({err}));
            if (err) {
                EventBus.$emit('show-alert', this.$t('common.error'))
                this.users = []
                this.loading = false;
                return;
            }
            this.users = response.data
            this.loading = false;
            this.initRoleSummary()
        },
    }
}
</script>
