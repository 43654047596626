<template>
    <div v-if="tripCosts.length > 0" class="nopads">
        <span class="section-title">{{ $t('contract_reports.trip_costs') }}</span>
        <!-- Summary -->
        <b-row class="nopads">
            <div class="col-12 text-center nopads">
                <table class="table table-sm">
                    <tr class="border-horizontal">
                        <td class="table-title table-caps">{{$t('contract_reports.order')}}</td>
                        <td class="table-title table-caps text-right">h</td>
                        <td class="table-title table-caps text-right">km</td>
                        <td class="table-title table-caps text-right">{{$t('orders.amount')}}</td>
                        <td class="table-title table-caps text-right">{{$t('contract_reports.order_realized_percent')}}</td>
                        <td class="table-value table-caps text-right">{{ currency }}</td>
                    </tr>
                  <tr v-for="(item, index) in tripCosts" v-bind:key="index">
                      <td class="table-title">{{item.order_id}} {{item.order_name}}, {{ item.executor_name }}</td>
                      <td class="table-value">{{ (item.total_time / 60).toFixed(1) }}</td>
                      <td class="table-value">{{ (item.work_distance + item.resettle_distance).toFixed(1) }}</td>
                      <td class="table-value">{{ item.ordered_amount ? item.ordered_amount + ' ' + unitToText(item.pricing_model.unit1) : '' }}</td>
                      <td class="table-value">
                          <span :class="{ 'red-text': calculateOrderRealized(item) > 100 }">
                            {{ calculateOrderRealized(item) + (calculateOrderRealized(item) > 0 ? ' %' : '') }}
                          </span>
                      </td>
                      <td class="table-value">{{ (item.cost).toFixed(2).toLocaleString() }}</td>
                  </tr>
                  <tr class="border-horizontal">
                      <td class="table-title table-caps">{{$t('common.total')}}</td>
                      <td class="table-value table-caps"/>
                      <td class="table-value table-caps"/>
                      <td class="table-value table-caps"/>
                      <td class="table-value table-caps"/>
                      <td class="table-value table-caps">{{ total.toFixed(2).toLocaleString() }} {{ currency }}</td>
                  </tr>
                </table>
            </div>
        </b-row>
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import {priceHelper} from "@/components/mixins/PricingMixin";

export default {
    name: "ContractReportTripCosts",
    mixins: [restApi, priceHelper],
    props: {
        value: {
            type: Number,
            default: null
        },
        searchParams: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data: function () {
        return {
            tripCosts: [],
            total: 0
        }
    },
    computed: {
        currency() {
            return process.env.VUE_APP_CURRENCY || '€'
        },
    },
    watch: {
        searchParams: function () {
            this.fetchTripCosts()
        }
    },
    mounted() {
        this.fetchTripCosts()
    },
    methods: {
        fetchTripCosts: function () {
            if (this.searchParams && this.searchParams.dateTo && this.searchParams.dateFrom && this.searchParams.contract) {
                this.trip = []
                this.$emit('loading', true)
                let params = {
                    from: this.searchParams.dateFrom,
                    to: this.searchParams.dateTo,
                    contract: this.searchParams.contract
                }
                this.restFetchParams(this.contractTripCostUrl, params, this.handleSuccess, this.handleFail)
            }
        },
        handleSuccess: function (response) {
            this.$emit('loading', false)
            this.tripCosts = response.data
            this.calculateSummaries()
        },
        handleFail: function () {
            this.$emit('loading', false)
        },
        calculateOrderRealized(item) {
            if(item && item.pricing_model) {
                let percent;
                switch (parseInt(item.pricing_model.unit1)) {
                    // € / km
                    case 1:
                        if(item.pricing_model.unit2 && item.pricing_model.unit2 === "107") {
                            percent = ((item.work_distance / item.ordered_amount) * 100).toFixed(1)
                        } else {
                            percent = (((item.resettle_distance + item.work_distance) / item.ordered_amount) * 100).toFixed(1)
                        }
                        break
                    // € / h
                    case 2:
                        if(item.pricing_model.unit2 && item.pricing_model.unit2 === "107") {
                            percent = ((((item.work_duration / 60) / item.ordered_amount)) * 100).toFixed(1)
                        } else {
                            percent = ((((item.duration / 60) / item.ordered_amount)) * 100).toFixed(1)
                        }
                        break
                    // € / road km
                    case 4:
                        percent = ((item.covered_road_distance / item.ordered_amount) * 100).toFixed(1)
                        break
                    // € / m2
                    case 7:
                        percent = ((item.square_meters / item.ordered_amount) * 100).toFixed(1)
                        break
                    // € / m
                    case 9:
                        if(item.pricing_model.unit2 && item.pricing_model.unit2 === "107") {
                            percent = (((item.work_distance * 1000) / item.ordered_amount) * 100).toFixed(1)
                        } else {
                            percent = ((((item.resettle_distance + item.work_distance) * 1000) / item.ordered_amount) * 100).toFixed(1)
                        }
                        break
                    // € / road m
                    case 10:
                        percent = (((item.covered_road_distance * 1000) / item.ordered_amount) * 100).toFixed(1)
                        break
                }
                if(percent && percent !== 'NaN') {
                    return percent
                }
            }
            return ''
        },
        calculateSummaries: function () {
            this.total = 0
            this.tripCosts.forEach(cost => {
                this.total += cost.cost
            })
            this.$emit('input', this.total)
        },
    }
}
</script>

<style scoped>

.table-title {
    font-size: .9em;
    text-align: left;
    font-weight: bold;
}

.table-value {
    text-align: right;
}

.red-text {
    color: red; /* or any other styles to make the row red */
}

.table-caps {
    background: #ececec;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
}

</style>
