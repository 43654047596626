<template>
    <div class="nopads">
        <div
            id="printContainer"
            class="nopads"
            v-if="searchParams"
        >
            <b-row>
                <b-col class="nopads report-header" cols="10">
                    <span class="section-title pb-0" style="display: block">
                        {{ $t('contract_reports.contract_costs') }} ({{ totalCost.toFixed(2).toLocaleString(this.getLanguage()) }} {{ currency }})
                    </span>
                    <span class="tip-light">{{ $t('contract_reports.trips_hint') }}</span>
                </b-col>
                <b-col class="nopads">
                    <font-awesome-icon
                        icon="arrow-down"
                        class="pointer right no-focus arrow-icon dont-print"
                        v-bind:class="{rotated : costsVisible}"
                        v-b-toggle.costDetails
                        @click.stop="costsVisible = !costsVisible"/>
                </b-col>
            </b-row>
            <b-collapse id="costDetails">
                <div class="nopads pl-1 pr-1 pb-4">
                    <!-- Trip costs -->
                    <contract-report-trip-costs
                        v-if="reportSections.tripCosts"
                        v-model="tripCosts"
                        :searchParams="searchParams"
                        @loading="handleLoading"
                    />
                    <!-- Area costs -->
                    <contract-report-area-work-costs
                        v-if="reportSections.areaCosts"
                        v-model="areaCosts"
                        :searchParams="searchParams"
                        @loading="handleLoading"
                    />
                    <!-- Additional costs -->
                    <contract-report-additional-costs
                        v-if="reportSections.additionalCosts"
                        v-model="additionalCosts"
                        :searchParams="searchParams"
                        @loading="handleLoading"
                        @editCost="editCost"
                    />
                    <!-- Working hour costs and hours for contract and task types -->
                    <contract-report-work-hour-costs
                        v-if="reportSections.workingHourCosts"
                        v-model="workCosts"
                        :search-params="searchParams"
                        @loading="handleLoading"/>
                </div>
            </b-collapse>
            <hr class="report-divider nopads"/>
        </div>
        <base-modal
            v-if="additionalCost"
            @cancel="additionalCost = null"
            style="z-index: 50"
        >
            <additional-cost-editor
                :additional-cost="additionalCost"
                @close="additionalCost = null"
                @closeAndUpdate="closeAndUpdate"
            />
        </base-modal>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {printHelper} from "@/components/mixins/PrintMixin";
import {Printd} from 'printd';
import ContractReportAdditionalCosts from "@/components/contractcosts/ContractReportAdditionalCosts";
import ContractReportTripCosts from "@/components/contractcosts/ContractReportTripCosts";
import {utility} from "@/components/mixins/Utils";
import ContractReportWorkHourCosts from "@/components/contractcosts/ContractReportWorkHourCosts";
import ContractReportAreaWorkCosts from "@/components/contractcosts/ContractReportAreaWorkCosts";
import BaseModal from "@/components/BaseModal";
import AdditionalCostEditor from "@/components/additionalcost/AdditionalCostEditor";

export default {
    name: "ContractCosts",
    components: {
        AdditionalCostEditor,
        BaseModal,
        ContractReportAreaWorkCosts,
        ContractReportWorkHourCosts,
        ContractReportTripCosts,
        ContractReportAdditionalCosts
    },
    mixins: [restApi, timeUtils, printHelper, utility],
    props: {
        isObserver: Boolean,
        searchParams: {
            Type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: 0,
            reports: [],
            trips: [],
            costsVisible: false,
            tripParams: null,
            tripsVisible: false,
            tripCosts: 0,
            areaCosts: 0,
            additionalCosts: 0,
            workCosts: 0,
            additionalCost: null,
            reportSections: {
                additionalCosts: true,
                tripCosts: true,
                workingHourCosts: true,
                areaCosts: true
            }
        }
    },
    computed: {
        totalCost() {
            return this.tripCosts + this.workCosts + this.additionalCosts + this.areaCosts
        },
        currency() {
            return process.env.VUE_APP_CURRENCY || '€'
        }
    },
    mounted() {
        this.d = new Printd()
    },
    methods: {
        print: function () {
            if (!this.loading) {
                this.d.print(document.getElementById('printContainer'), this.printStyle)
            }
        },
        handleLoading: function (loading) {
            if (loading) {
                this.loading++
            } else if (this.loading > 0) {
                this.loading--
            }
        },
        editCost(item) {
            this.additionalCost = item
        },
        closeAndUpdate() {
            this.additionalCost = null
            this.fetchAdditionalCosts()
        }
    }
}
</script>

<style>
.borderless {
    border-color: #FFFFFF !important;
}

.total-cost {
    color: #9e9d9d;
    font-size: 1.5em;
    font-weight: normal;
    line-height: 2.5em;
    vertical-align: center;
    border: none !important;
}

</style>
