<template>
    <div
        class="details-container"
        @click.stop="$emit('close')"
    >
        <div
            class="details dynamic_width nopads"
            @click.stop="cancelClick($event)"
        >
            <div class="trip-results" style="height:70vh !important;">
                <!-- Map for showing trip here -->
                <map-container
                    v-if="mounted"
                    ref="mapContainer"
                    :find-user="false"
                    @onMapChanged="mapChanged"
                    @onMarkerTap="markerTap"
                    @onMultiMarkerTap="onMultiMarkerTap"
                />
            </div>
            <b-row>
                <div class="col-12 nopads align-right">
                    <b-button class="form-button" size="sm" variant="secondary" @click="$emit('close')" style="z-index: 50;">
                        {{ $t('common.close') }}
                    </b-button>
                </div>
            </b-row>
            <b-modal
                ref="selectMarkerModal"
                centered
                hide-footer
                :title="$t('map.select_marker_modal')"
            >
                <map-item-selector
                    v-if="map"
                    :marker-store="map.getMarkerStore()"
                    :markers="markerSelections"
                    @mapItemSelected="markerTap"/>
            </b-modal>
            <div
                v-if="loading > 0"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>

<script>
import {mapHelper} from '@/components/mixins/MapMixin'
import MapContainer from "@/components/map/MapContainer";
import MapItemSelector from "@/components/map/MapItemSelector";
import {spreaderDataMixin} from "@/components/mixins/SpreaderDataMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";


export default {
    name: 'SpreaderDataMap',
    components: {MapItemSelector, MapContainer},
    mixins: [mapHelper, spreaderDataMixin, timeUtils],
    props: {
        spreaderData: {
            type: Object,
            default: null
        },
        spreaderAllData: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            map: null,
            loading: false,
            mounted: false,
            markerSelections: []
        }
    },
    watch: {
      map() {
        this.$nextTick(function () {
          this.initView()
        })
      }
    },
    beforeDestroy() {
        this.hideItems()
    },
    mounted() {
        this.$nextTick(function () {
            this.mounted = true
        })
        this.initView()
    },
    methods: {
        mapChanged(map) {
            this.map = map
        },

        initView: function () {
            if (this.map) {
                this.markerStore = this.map.getMarkerStore()
                this.updateView()
                this.map.resizeMap()
            }
        },

        markerTap(data) {
            let pointItem;
            let foundItem;
            this.closeMarkerModal()
            if(this.spreaderData) {
                pointItem = this.spreaderData.point_data.find(item => data.id === item.id)
            } else if(this.spreaderAllData && this.spreaderAllData.length > 0) {
                for (let item of this.spreaderAllData) {
                    // Find the matching pointItem
                    pointItem = item.point_data.find(point => data.id === point.id);

                    if (pointItem) {
                        foundItem = item;
                        break; // Exit the loop once a match is found
                    }
                }
            }
            let bubbleData = []
            bubbleData[this.$t('spreader_data.spreading_width_dry')] = `${pointItem.spreading_width_dry} cm`

            // Some cases material (liquid and dry) names may overlap, so we need to indicate is the material dry or liquid
            // When material names overlap it will overwrite the previous value
            bubbleData[`${this.getMaterialName(this.spreaderData ? this.spreaderData.material_dry : foundItem.material_dry)} (${this.$t('spreader_data.dry')})`] =
                `${pointItem.material_dry_amount} ${this.spreaderData ? this.spreaderData.material_dry_unit : foundItem.material_dry_unit}`

            bubbleData[this.$t('spreader_data.spreading_width_liquid')] = `${pointItem.spreading_width_liquid} cm`

            bubbleData[`${this.getMaterialName(this.spreaderData ? this.spreaderData.material_liquid : foundItem.material_liquid)} (${this.$t('spreader_data.liquid')})`] =
                `${pointItem.material_liquid_amount} ${this.spreaderData ? this.spreaderData.material_liquid_unit : foundItem.material_liquid_unit}`

            this.map.showInfoBubble(this.SPREADER_DATA, data.id, this.toLocalTime(pointItem.time), bubbleData, pointItem.position.y, pointItem.position.x)
        },

        onMultiMarkerTap(data) {
            let allPointData = this.spreaderData ? this.spreaderData.point_data : this.spreaderAllData.flatMap(item => item.point_data);
            const sources = {
                [this.SPREADER_DATA]: () => allPointData
            };
            const elements = data
                .map(data => {
                    const src = sources[data.type];
                    if (src == null) {
                        return null;
                    }
                    let element = {
                        type: data.type,
                        id: data.id,
                    }

                    element.data = src().find(el => el.id === data.id);
                    return element
                })
                .filter(el => el != null);

            this.$refs.selectMarkerModal.show();
            this.markerSelections = elements;
        },
        closeMarkerModal() {
            this.$refs.selectMarkerModal.hide();
            this.markerSelections = []
        },

        updateView() {
            this.map.resizeMap()
            if (this.spreaderData && this.spreaderData.point_data && this.spreaderData.point_data.length > 0) {
                this.spreaderData.point_data.forEach(item => {
                    let icon = this.map.getMarkerStore().getSolidCircleIcon()
                    this.map.showMapMarker(item.id, this.SPREADER_DATA, item.position.y, item.position.x, icon)
                })
                this.map.zoomToPosition(this.spreaderData.point_data[0].position.y, this.spreaderData.point_data[0].position.x, 13)
            } else if(this.spreaderAllData) {
                this.spreaderAllData.forEach(item => {
                    if(item.point_data) {
                        item.point_data.forEach(point => {
                            let icon = this.map.getMarkerStore().getSolidCircleIcon()
                            this.map.showMapMarker(point.id, this.SPREADER_DATA, point.position.y, point.position.x, icon)
                        })
                    }
                })
                this.map.zoomToPosition(this.spreaderAllData[0].point_data[0].position.y, this.spreaderAllData[0].point_data[0].position.x, 13)
            }
        },

        hideItems: function () {
          if (this.map) {
            this.map.removeMapItemsByType(this.SPREADER_DATA)
            this.$emit('close')
          }
        },

        cancelClick: function (e) {
            e.stopPropagation()
        },
    }
}
</script>
