<template>
    <div class="col-sm-12 list-container">
        <transition name="fade">
            <div
                v-if="results"
                id="accordion"
                class="col-sm-12 list-content result-table"
            >
                <b-table
                    hover
                    show-empty
                    small
                    class="pointer"
                    :items="items"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    @row-clicked="emitDetails"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('common.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(event_time)="row">
                        {{ toLocalTime(row.value) }}
                    </template>
                    <template v-slot:cell(event_type)="row">
                        {{ getDangerTypeName(row.value) }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {dangerHelper} from '../mixins/DangerMixin'

export default {
    name: 'DangerReportList',
    mixins: [timeUtils, dangerHelper],
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            sortBy: 'start_time',
            sortDesc: true,
            fields: [
                {key: 'id', label: this.$t('danger_report.report_id'), sortable: true},
                {key: 'reporter', label: this.$t('danger_report.reporter'), sortable: true},
                {key: 'reporting_company', label: this.$t('danger_report.reporting_company'), sortable: true},
                {key: 'event_time', label: this.$t('danger_report.event_time'), sortable: true},
                {key: 'event_type', label: this.$t('danger_report.event_type'), sortable: true},
                // {key: 'location', label: this.$t('danger_report.location'), sortable: true},
                {key: 'contract', label: this.$t('danger_report.contract'), sortable: true},
                {key: 'description', label: this.$t('danger_report.description'), sortable: true},
                {key: 'handled', label: this.$t('danger_report.handled'), sortable: true},
                {key: 'handled_by', label: this.$t('danger_report.handled_by'), sortable: true},
                {key: 'handling_note', label: this.$t('danger_report.note'), sortable: false},
            ],
            items: []
        }
    },
    watch: {
        results: function () {
            this.initTableItems()
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    methods: {
        initTableItems: function () {
            this.items = []
            this.results.forEach(function (item) {
                this.items.push(
                    {
                        id: item.id,
                        event_time: item.event_time,
                        reporting_company: item.reporting_company.name,
                        event_type: item.event_type,
                        reporter: item.reporter.first_name + ' ' + item.reporter.last_name,
                        description: item.description,
                        // name or ID?
                        contract: item.contract ? item.contract.name : "",
                        location: item.location,
                        handled: item.handled ? this.$t('common.yes') : this.$t('common.no'),
                        handled_by: item.handled_by ? item.handled_by.last_name + ' ' + item.handled_by.first_name : '-',
                        handling_note: item.handling_note
                    }
                )
            }, this)
        },
        emitDetails: function (details) {
            let report = this.results.find(item => item.id === details.id)
            this.$emit('details', report)
        }
    }
}
</script>
