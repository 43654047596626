<template>
    <div v-if="costs && costs.length > 0" class="nopads pt-3">
        <span class="section-title">{{ $t('contract_reports.working_hour_costs') }}</span>
        <!-- Summary -->
        <b-row class="nopads">
            <div class="col-12 nopads">
                <table class="table table-sm mb-0">
                    <!-- TODO - Order division for costs -->
                    <tr class="border-horizontal">
                        <td class="table-title table-caps">{{$t('common.total')}}</td>
                        <td class="table-value table-caps">{{ totalDuration.toFixed(2) }} <span style="text-transform: none">h</span></td>
                        <td class="table-value table-caps">{{ totalCost.toFixed(2) }} {{ currency }}</td>
                    </tr>
                </table>
            </div>
        </b-row>
        <!-- Work time -->
        <b-row class="nopads">
            <b-table
                class="cost-table pt-0 mt-0"
                v-if="tableItems && tableItems.length > 0"
                small
                :items="tableItems"
                :fields="tableFields"
            >
                <template v-slot:cell(order)="row">
                    {{ row.item.order.id }} {{ getOrderAndExecutorStr(row.item.order)}}
                </template>
                <template v-slot:cell(hourly_price)="row">
                    {{ isNaN(row.item.hourly_price) ?  "-" : (row.item.hourly_price / 100).toFixed(2)  }} {{ currency }}/ h
                </template>
                <template v-slot:cell(duration)="row">
                    {{ row.item.duration.toFixed(2)  }} {{ currency }}/ h
                </template>
                <template v-slot:cell(total)="row">
                    {{ isNaN(row.item.hourly_price) ?  "0" : (row.item.duration * row.item.hourly_price / 100).toFixed(2) }} {{ currency }}
                </template>
            </b-table>
        </b-row>
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import {orderMixin} from "@/components/mixins/OrderMixin";

export default {
    name: "ContractReportWorkHourCosts",
    mixins: [restApi, orderMixin],
    props: {
        value: {
            type: Number,
            default: null
        },
        searchParams: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data: function () {
        return {
            costs: [],
            tableItems: [],
            tableFields: [
                {key: 'order', label: this.$t('contract_reports.order'), sortable: false},
                {key: 'hourly_price', label: this.$t('contract_reports.hourly_price', {currency: process.env.VUE_APP_CURRENCY}), sortable: false},
                {key: 'duration', label: this.$t('contract_reports.duration'), sortable: false},
                {key: 'total', label: this.$t('common.total'), sortable: false},
            ],
            totalCost: 0,
            totalDuration: 0,
        }
    },
    computed: {
        currency() {
            return process.env.VUE_APP_CURRENCY || '€'
        }
    },
    watch: {
        searchParams: function () {
            this.fetchCosts()
        }
    },
    mounted() {
        this.fetchCosts()
    },
    methods: {
        fetchCosts: function () {
            this.costs = []
            if (this.searchParams && this.searchParams.dateTo && this.searchParams.dateFrom && this.searchParams.contract) {
                this.$emit('loading', true)
                let params = {
                    from: this.searchParams.dateFrom,
                    to: this.searchParams.dateTo,
                    contract: this.searchParams.contract
                }
                this.restFetchParams(this.contractWorkCostUrl, params, this.handleSuccess, this.handleFail)
            }
        },
        handleSuccess: function (response) {
            this.$emit('loading', false)
            this.costs = response.data
            this.calculateSummaries()
        },
        handleFail: function () {
            this.$emit('loading', false)
        },
        calculateSummaries: function () {
            this.totalCost = 0
            this.totalDuration  = 0
            this.tableItems = []
            this.costs.forEach(cost => {
                let tableItem = this.tableItems.find(item => item.order.id === cost.order.id)
                if (tableItem) {
                    tableItem.duration += cost.duration
                } else {
                    this.tableItems.push(cost)
                }
                if (!isNaN(cost.hourly_price)) {
                    this.totalCost += cost.hourly_price * cost.duration
                }
                this.totalDuration += cost.duration
            })
            this.totalCost = this.totalCost / 100
            this.$emit('input', this.totalCost)
        }
    }
}
</script>

<style scoped>

.table-title {
    font-size: .9em;
    text-align: left;
    font-weight: bold;
}

.table-value {
    text-align: right;
}

.table-caps {
    background: #ececec;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
}

/deep/ .cost-table th {
    font-style: italic;
}

</style>
