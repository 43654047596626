<template>
    <div class="col-sm-12 list-container">
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
        <span class="item-title-add">
          {{ $t('common.add_new') }}
        </span>
                <font-awesome-icon
                    icon="calendar-check"
                    class="card-body-icon"
                />
            </div>
        </div>

        <transition name="fade">
            <div
                class="col-sm-12 pb-2 result-table"
            >
                <b-table
                    :small="true"
                    :responsive="true"
                    :items="results"
                    :fields="fields"
                    :bordered="true"
                    hover
                    class="pointer"
                    @row-clicked="emitEdit"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(vehicle_types)="row">
                        <div v-for="(type, index) in row.item.vehicle_types" v-bind:key="index" class="nopads">
                          {{ getVehicleTypeName(type.vehicle_type) }}
                        </div>
                    </template>
                    <template v-slot:cell(color)="row">
                        <div
                            class="nopads"
                            style="height: .7em; width: 0.7em; border-radius: 0.35em; border: solid #bcbcbc 1px; margin-left: 1em;"
                            :style="{ background: row.item.color}"
                        />
                    </template>
                    <template v-slot:cell(bicycle_road_task_type)="row">
                        {{ row.item.bicycle_road_task_type === true ? $t('common.yes') : ''}}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {vehicleHelper} from "@/components/mixins/VehicleMixin";

export default {
    name: 'TasktypeList',
    mixins: [workManagementHelper, vehicleHelper],
    data() {
        return {
            fields: [
                {key: 'id', label: 'Id', sortable: true},
                {key: 'name', label: this.$t('orders.task_type'), sortable: true},
                {key: 'vehicle_types', label: this.$t('tasktypes.vehicle_types'), sortable: false},
                {key: 'bicycle_road_task_type', label: this.$t('tasktypes.bicycle_road_task_type'), sortable: true},
                {key: 'color', label: this.$t('tasktypes.color'), sortable: false}
            ]
        }
    },
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            },
        },
        isAdmin: Boolean
    },
    methods: {
        emitEdit(item) {
            this.$emit('edit', item)
        }
    }
}
</script>
