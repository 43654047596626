<template>
    <b-container fluid
                 class="details-container"
                 style="z-index: 500 !important;"
    >
            <b-form class="col-sm-12 editor-form">
                <div class="col-sm-12 form-title editor-title">
                    <h2 class="form-title editor-title">
                        {{ $t('areas.add_work_record') }}
                    </h2>
                </div>
                <b-row v-if="!areaId || areaId < 1" class="nopads pl-3 pr-3">
                    <area-filter
                        ref="areaFilter"
                        :is-admin="false"
                        @areaSet="setArea"
                    />
                </b-row>
                <div class="col-sm-12">
                    <span class="span-title">{{ $t('areas.work_time') }}</span>
                    <datetime
                        class="ml-1 mr-1"
                        style="font-size: .9em"
                        v-model="workTime"
                        type="datetime"
                        format="dd.MM.yyyy HH:mm"
                        :placeholder="$t('areas.set_work_time')"
                    />
                </div>
                <div class="col-sm-12">
                    <span class="span-title">{{ $t('areas.contract') }}</span>
                        <open-contract-filter
                            :to="workTime"
                            :from="workTime"
                            :show-company="false"
                            :show-title="false"
                            :showOnlyMyContractsOption="true"
                            @loading="loading=true"
                            @loadingCompleted="loading=false"
                            @contractSelected="setContract"
                        />
                </div>
                <div class="col-sm-12 pb-2">
                    <span class="span-title">{{ $t('areas.task_type') }}</span>
                    <contract-order-filter
                        class="pr-1 pl-1"
                        v-model="order"
                        :contract-id="contract ? contract.id : null"
                        :show-title="false"
                    />
                </div>
                <div class="col-sm-12 button-container pb-3">
                    <b-button
                        variant="danger"
                        class="result-button"
                        @click.stop="close"
                    >
                        {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        variant="success"
                        :disabled="!formAreaId || formAreaId < 1 || loading"
                        class="result-button"
                        @click.stop="submitAreaWork"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
    </b-container>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from "../../mixins/TimeUtils";
import {EventBus} from '@/event-bus'
import {orderMixin} from "@/components/mixins/OrderMixin";
import OpenContractFilter from "../../contract/OpenContractFilter";
import ContractOrderFilter from "../../order/ContractOrderFilter";
import AreaFilter from "../AreaFilter";

export default {
    name: 'AreaWorkEditor',
    components: {AreaFilter, ContractOrderFilter, OpenContractFilter},
    mixins: [restApi, orderMixin, timeUtils],
    props: {
        user: {
            type: Object,
            default: null
        },
        areaId: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            loading: false,
            workTime: null,
            customer: null,
            contractor: null,
            contract: null,
            contracts: [],
            order: null,
            formAreaId: null
        }
    },
    mounted() {
        this.formAreaId = this.areaId
    },
    methods: {
        setContractor: function (contractor) {
            this.contractor = contractor
        },

        setContract: function (contract) {
            this.contract = contract
        },
        setDefaultValues: function () {
            let params = {
                end_time: this.workTime,
                start_time: this.workTime
            }
            if(this.contract && this.contract.id) {
                params.contract = {id: this.contract.id}
            }
            if(this.order && this.order.id) {
                params.work_order = {id: this.order.id}
            }
            if(this.formAreaId) {
                params.area = {id: this.formAreaId}
            }
            if(!params.contract) {
                EventBus.$emit('show-alert', this.$t('orders.select_contract'))
                return undefined
            }
            if(!params.work_order) {
                EventBus.$emit('show-alert', this.$t('orders.select_order'))
                return undefined
            }
            if(!params.area) {
                EventBus.$emit('show-alert', this.$t('orders.alert_add_failed'))
                return undefined
            }
            return params
        },
        submitAreaWork: function () {
            let json = this.setDefaultValues()
            if(json) {
                this.loading = true
                this.restAdd(this.areaWorkUrl, json, this.success, this.fail)
            }
        },
        success: function () {
           this.loading = false
           this.$emit('closeAndUpdate')
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },
        close: function () {
            this.$emit('close')
        },
        setArea(area) {
            this.formAreaId = area
        }
    }
}
</script>
<style scoped>
</style>

