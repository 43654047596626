<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="list-content" :class="{nopads : noPadding}">
        <b-table
            small
            :items="results"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @row-clicked="rowClicked"
            class="pointer"
        >
            <template v-slot:cell(created_at)="row">
                {{ getDateString(row.value) }}
            </template>
            <template v-slot:cell(user)="row">
                {{ row.value ? row.value.last_name + ' ' + row.value.first_name : '' }}
            </template>
            <template v-slot:cell(contract)="row">
                {{ getContractInfo(row.item) }}
            </template>
            <template v-slot:cell(work_order)="row">
                {{ getOrderInfo(row.value) }}
            </template>
            <template v-slot:cell(type)="row">
                {{ getAdditionalCostTypeName(row.value) }}
            </template>
            <template v-slot:cell(status)="row">
                {{ getAdditionalCostStatusName(row.value) }}
            </template>
            <template v-slot:cell(cost)="row">
                {{ (row.item.unit_price * row.item.amount).toFixed(2) }} {{ currency }}
            </template>
        </b-table>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {additionalCostHelper} from "@/components/mixins/AdditionalCostMixin";

export default {
    name: 'additional-cost-list',
    mixins: [timeUtils, additionalCostHelper],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        noPadding: {
            type: Boolean,
            default: false
        },
        tableSortable: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            sortBy: 'created_at',
            sortDesc: false,
            fields: [
                {key: 'created_at', label: this.$t('additional_cost.created_at'), sortable: this.sortable},
                {key: 'type', label: this.$t('additional_cost.type'), sortable: this.sortable},
                {key: 'amount', label: this.$t('additional_cost.amount'), sortable: this.sortable},
                {key: 'unit', label: this.$t('additional_cost.unit'), sortable: this.sortable},
                {key: 'unit_price', label: this.$t('additional_cost.unit_price'), sortable: this.sortable},
                {key: 'contract', label: this.$t('additional_cost.contract'), sortable: this.sortable},
                {key: 'work_order', label: this.$t('additional_cost.order'), sortable: this.sortable},
                {key: 'user', label: this.$t('additional_cost.user'), sortable: this.sortable},
                {key: 'status', label: this.$t('additional_cost.status'), sortable: this.sortable},
                {key: 'info', label: this.$t('common.info'), sortable: this.sortable},
                {key: 'cost', label: this.$t('additional_cost.cost'), sortable: this.sortable}
            ]
        }
    },
    computed: {
        currency() {
            return process.env.VUE_APP_CURRENCY || '€'
        }
    },
    methods: {
        getContractInfo: function (item) {
            if (item.contract && item.contract.name) {
                return item.contract.name + " (" + item.contract.customer.name + " - " + item.contract.contractor.name + ")"
            } else if (item.work_order && item.work_order.contract) {
                return item.work_order.contract.name + " (" + item.work_order.contract.customer.name + " - " + item.work_order.contract.contractor.name + ")"
            } else {
                return ''
            }
        },
        getOrderInfo: function (order) {
            if (order && order.task_type) {
                return order.task_type.name
            } else {
                return ''
            }
        },
        rowClicked: function (item) {
            this.$emit("rowClicked", item)
        }
    }
}
</script>
