<template>
    <div v-if="results && results.length > 0" class="nopads">
        <b-row>
            <b-col class="nopads report-header">
                <b-row class="nopads">
                    <b-col class="nopads">
                        <span class="section-title" style="display: block">{{ $t('sports.ski_report_title') }}</span>
                    </b-col>
                    <b-col>
                        <div class="button-container">
                            <b-button
                                variant="outline-success"
                                class="result-button float-right dont-print hidden"
                                :disabled="results.length < 1"
                                @click="exportExcel"
                            >
                                {{ $t('trip.download_excel') }}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

            </b-col>
        </b-row>

        <div class="col-sm-12 list-content result-table" v-if="results">
            <b-table
                id="resultTable"
                ref="resultTable"
                small
                :items="results"
                :fields="fields"
                @row-clicked="showSkiWork"
            >
                <template v-slot:cell(time)="row">
                    {{ row.value ? toLocalTime(row.value) : '' }}
                </template>
                <template v-slot:cell(contract)="row">
                    {{ row.value ? row.value.name : '' }}
                </template>
                <template v-slot:cell(addition)="row">
                    {{ row.item.added_by ? $t('areas.manual_addition') : $t('areas.tracking') }}
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import {timeUtils} from "../../mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";

export default {
    name: 'SportWorkList',
    mixins: [timeUtils, restApi],
    props: {
        params: {
            type: Object,
            default() {
                return null
            }
        },
        showTitle: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            sortBy: 'created',
            sortDesc: true,
            sortDirection: 'asc',
            results: [],
            loading: false,
            fields: [
                {key: 'time', label: this.$t('areas.time'), sortable: true, sortByFormatted: true},
                {key: 'track_name', label: this.$t('sports.route'), sortable: true, sortByFormatted: true},
                {key: 'link_id', label: this.$t('sports.link'), sortable: true, sortByFormatted: true},
                {key: 'contract_name', label: this.$t('areas.work_contract'), sortable: true, sortByFormatted: true},
                {key: 'task_type_name', label: this.$t('areas.work_task'), sortable: true, sortByFormatted: true},
                {key: 'addition', label: this.$t('areas.addition'), sortable: true, sortByFormatted: true}
            ],
        }
    },
    watch: {
        params() {
            this.fetchWorkRecords()
        }
    },
    mounted() {
        this.fetchWorkRecords()
    },
    methods: {
        fetchWorkRecords() {
            this.results = []
            if (this.params) {
                // Turn single contract to array!
                if (this.params.contract) {
                    if (this.params.contracts) {
                        this.params.contractIds.push(this.params.contract)
                    } else {
                        this.params.contractIds = [this.params.contract]
                    }
                    this.params.contract = null
                }
                if(this.params.dateFrom) {
                    this.params.from = this.params.dateFrom
                }
                if(this.params.dateTo) {
                    this.params.to = this.params.dateTo
                }
                this.loading = true
                this.restFetchParams(this.skiingTrackWorkUrl, this.params, this.success, this.fail)
            }
        },

        success: function (response) {
            this.loading = false
            this.results = response.data
        },

        showSkiWork: function (row) {
            if (!this.loading) {
                let selectedItem = this.results.find(item => item.id === row.id)
                if (selectedItem){
                    this.$emit('rowSelected', selectedItem)
                }
            }
        },

        fail: function () {
            this.loading = false
        },

    }
}
</script>

<style scoped>
</style>
