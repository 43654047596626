<template>
    <div ref="container">
        <leaflet-map
                id="leaflet_map"
                ref="leafletMap"
                :find-user="findUser"
                :center="center"
                :ignoreTraceTap="ignoreTraceTap"
                @onMapClicked="mapClicked"
                @onPolylineTap="onPolylineTap"
                @onMapInfoClosed="onMapInfoClosed"
                @onZoomLevelChange="onZoomLevelChange"
                @onBoundingBoxChange="onBoundingBoxChange"
                @onPolylineHover="onPolylineHover"
                @onPolylineHoverExit="onPolylineHoverExit"
                @onPointHover="onPointHover"
                @onPointHoverExit="onPointHoverExit"
                @onMarkerTap="onMarkerTap"
                @onMultiMarkerTap="onMultiMarkerTap"
                @onMarkerPointerEnter="onMarkerPointerEnter"
                @onDrag="onDrag"
                @onDragEnd="onDragEnd"
                @editAreaEvent="onEditArea"
                @onGeoJsonClicked="onGeoJsonClicked"
                @onGeoJsonHoverEvent="onGeoJsonHoverEvent"
                @onGeoJsonHoverExitEvent="onGeoJsonHoverExitEvent"
                @pointerCoordinateMove="pointerCoordinateMove"
                :menuItems="menuItems"
                :menu-enabled="menuEnabled"
        />
    </div>
</template>

<script>
import LeafletMap from "@/components/map/LeafletMap";
import {restApi} from '../mixins/RestApiMixin'
import {mapHelper} from "../mixins/MapMixin";

export default {
    name: 'MapContainer',
    mixins: [restApi, mapHelper],
    components: {
        LeafletMap
    },
    props: {
      menuItems: {
        type: Array,
        default: () => []
      },
      center: {
        type: Object,
        default: null
      },
      findUser: {
        type: Boolean,
        default: function () {
          return false
        }
      },
      ignoreTraceTap: {
            type: Boolean,
            default: function () {
                return true
            }
      },
      menuEnabled: {
        type: Boolean,
        default: function () {
          return true
        }
      }
    },

    mounted() {
        this.map = this.$refs.leafletMap
        this.$emit('onMapChanged', this.getMap());
    },

    beforeDestroy() {
        this.updateMapSettings({ center: this.getMap().getMapCenter() });
        this.saveMapSettings()
    },

    watch: {
        center: function () {
            if (this.center) {
                this.mapCenter = this.center
            }
        }
    },


    methods: {
        mapClicked: function (coord) {
            this.$emit('onMapClicked', coord)
        },

        onPolylineTap: function (data) {
          this.$emit('onPolylineTap', data)
        },

        onPolylineHover: function (data, latlng, index) {
            this.$emit('onPolylineHover', data, latlng, index)
        },

        onPolylineHoverExit: function (data) {
            this.$emit('onPolylineHoverExit', data)
        },

        onMarkerPointerEnter: function (data) {
          this.$emit('onMarkerPointerEnter', data)
        },

        onBoundingBoxChange: function(data) {
            this.$emit('onBoundingBoxChange', data)
        },

        onZoomLevelChange: function (data) {
            this.$emit('onZoomLevelChange', data)
        },

        onMarkerTap: function (data) {
          this.$emit('onMarkerTap', data)
        },

        onMultiMarkerTap(data) {
            this.$emit('onMultiMarkerTap', data)
        },

        onDragEnd: function (data) {
            this.$emit('onDragEnd', data)
        },

        onDrag: function (data) {
            this.$emit('onDrag', data)
        },

        onMapInfoClosed: function () {
          this.$emit('onMapInfoClosed')
        },

        onEditArea: function (area) {
            this.$emit('editAreaEvent', area)
        },

        onPointHover(data, latlng, index) {
            this.$emit('onPointHover', data, latlng, index)
        },

        onPointHoverExit(data) {
            this.$emit('onPointHoverExit', data)
        },

        onGeoJsonClicked(feature, layer, index, importedItemIndex) {
            this.$emit('onGeoJsonClicked', feature, layer, index, importedItemIndex)
        },

        onGeoJsonHoverEvent(feature, layer, index, importedItemIndex, currentCoordinates) {
            this.$emit('onGeoJsonHoverEvent', feature, layer, index, importedItemIndex, currentCoordinates)
        },

        onGeoJsonHoverExitEvent(feature, layer, index, importedItemIndex, currentCoordinates) {
            this.$emit('onGeoJsonHoverExitEvent', feature, layer, index, importedItemIndex, currentCoordinates)
        },

        pointerCoordinateMove(coord) {
            this.$emit('pointerCoordinateMove', coord)
        },

        hideMapControls() {
            this.getMap().hideMapControls()
        },

        addGeoJsonObjects: function (layer, focusOnLayer = true, color){
            this.getMap().addGeoJsonObjects(layer, focusOnLayer, color)
        },
        hideImportedObjects: function () {
            this.getMap().hideGeoJsonObjects()
        },
        hideMapInfo: function () {
            this.getMap().hideMapInfoCallback()
        },

        getMap() {
            return this.map
        }
    }
}
</script>
