<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div v-if="showTitle && !loading" class="col-sm-12 form-title editor-title">
                <b-row
                    v-if="orderUpdate.id > 0"
                    class="nopads">
                    <h2 class="col-sm-6 nopads pt-2 pb-2 form-title editor-title">
                        {{ $t('orders.edit') }}
                    </h2>
                    <span v-if="orderUpdate.id > 0" class="col-sm-6 nopads pt-2 pb-2 pr-2 attention text-bold pl-1 text-right" style="font-size: .9em">
                        {{!geomLoading && isOrderUpdateEdited ? $t('orders.order_edited') : null}}
                    </span>
                </b-row>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('orders.add') }}
                </h2>
            </div>

            <!-- OrderWizard -->
            <order-wizard
                v-if="!orderUpdate.contract && !loading"
                :user="user"
                :initial-contract-id="order.contract != null ? order.contract.id : undefined"
                @contractSet="setContract"
            />

            <div
                v-if="orderUpdate.contract"
                class="col-sm-12 form-data"
            >
                <b-row class="nopads">
                    <div v-if="orderUpdate.id > 0" class="col-sm-6 nopads">
                        <span class="span-title">
                          {{ $t('orders.id') }}
                        </span>
                        <span
                            v-if="orderUpdate.id"
                            class="span-text"
                        >
                          <strong>
                            {{ orderUpdate.id }}
                          </strong>
                        </span>
                        <span v-else>
                          -
                        </span>
                    </div>
                    <div class="nopads" :class="`${orderUpdate.id > 0 ? 'col-sm-6' : 'col-sm-12'}`">
                        <span class="span-title">
                          {{ $t('orders.contract') }}
                        </span>
                        <span
                            v-if="orderUpdate.contract"
                            class="span-text"
                        >
                          <strong>
                            {{ orderUpdate.contract_name }}
                          </strong>
                        </span>
                        <span v-else>
                          -
                        </span>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-6 nopads">
                        <span class="span-title">
                            {{ $t('orders.customer') }}
                        </span>
                        <span
                            v-if="orderUpdate.customer_name"
                            class="span-text"
                        >
                            <strong>
                              {{ orderUpdate.customer_name }}
                            </strong>
                        </span>
                        <span v-else>
                            -
                        </span>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="span-title">
                            {{ $t('orders.contractor') }}
                        </span>
                        <span
                            v-if="orderUpdate.contractor_name"
                            class="span-text"
                        >
                            <strong>
                              {{ orderUpdate.contractor_name }}
                            </strong>
                        </span>
                        <span v-else>
                            -
                        </span>
                    </div>
                </b-row>
                <div
                    v-if="orderUpdate.id < 1"
                    class="col-sm-12 nopads"
                >
                    <!-- Task type selection - only in add mode -->
                    <span class="span-title">
                      {{ $t('orders.task_type') }}
                    </span>
                    <b-form-select
                        id="tasks"
                        v-model="orderUpdate.contract_task_type.id"
                        size="sm"
                        class="mb-3"
                        :disabled="orderUpdate.contract === undefined"
                        @change="prefillUnit"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('orders.select_task_type') }}
                            </option>
                        </template>
                        <option
                            v-for="option in contractTaskTypes"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.task_type.name }}, {{ option.amount }} {{ getUnitText(option.unit) }}
                        </option>
                    </b-form-select>
                </div>
                <div v-else>
                    <div class="col-sm-6 nopads">
                        <span class="span-title">
                          {{ $t('orders.contract') }}
                        </span>
                        <span class="span-text">
                            <strong>
                                {{ orderUpdate.contract_name }}
                            </strong>
                        </span>
                    </div>
                    <div class="col-sm-6 nopads">
                        <span class="span-title">
                          {{ $t('orders.task_type') }}
                        </span>
                        <span class="span-text">
                          <strong>
                            {{ orderUpdate.contract_task_type_name }}, {{
                                  orderUpdate.ordered_amount
                              }} {{ getUnitText(orderUpdate.unit) }}
                          </strong>
                        </span>
                    </div>
                </div>


                <!-- Edit fields open in both add and edit mode -->
                <hr/>
                <h4 class="form-header">{{ $t('orders.basic_info')}}</h4>
                <span class="tip-light"> {{ $t('orders.state_tip') }}</span>
                <b-row class="nopads pb-2">
                    <div class="col-sm-4 nopads pr-1">
                        <span class="span-title">{{ $t('orders.status') }}</span>
                        <!-- TODO - Hint text of vehicle visibility -->
                        <b-form-select
                            id="taskState"
                            v-model="orderUpdate.status"
                            size="sm"
                        >
                            <option :value="3">
                                {{ $t('orders.status_plan') }}
                            </option>
                            <option :value="4">
                                {{ $t('orders.status_stored') }}
                            </option>
                            <option :value="1">
                                {{ $t('orders.status_open') }}
                            </option>
                            <option :value="2">
                                {{ $t('orders.status_in_progress') }}
                            </option>
                            <option :value="10">
                                {{ $t('orders.status_completed') }}
                            </option>
                            <option :value="20">
                                {{ $t('orders.status_cancelled') }}
                            </option>
                            <option :value="30">
                                {{ $t('orders.status_approved') }}
                            </option>
                            <option :value="40">
                                {{ $t('orders.status_invoiced') }}
                            </option>
                            <option :value="closed">
                                {{ $t('orders.status_closed') }}
                            </option>
                            <option :value="aborted">
                                {{ $t('orders.status_aborted') }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1">
                        <span class="span-title">
                            {{ $t('orders.object_number') }}
                        </span>
                        <b-form-input
                            id="objectNumberAmount"
                            v-model="orderUpdate.object_number"
                            type="number"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-4 nopads pr-1">
                        <span class="span-title">
                          {{ $t('orders.responsible') }}
                        </span>
                        <b-form-select
                            id="respPerson"
                            v-model="orderUpdate.resp_person.id"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('orders.select_person') }}
                                </option>
                            </template>
                            <option
                                v-for="option in persons"
                                :key="option.id"
                                :value="option.id"
                            >
                                {{ option.last_name }}, {{ option.first_name }}
                            </option>
                        </b-form-select>
                    </div>
                </b-row>
                <b-row class="nopads pb-2" v-if="orderUpdate && (orderUpdate.status === closed || orderUpdate.status === aborted)">
                    <div class="col-sm-4 nopads pr-1">
                        <span class="span-title">{{ $t( orderUpdate.status === closed ? 'orders.close_time' : 'orders.abort_time') }}</span>
                <datetime
                    v-model="orderUpdate.complete_time"
                    type="datetime"
                    size="sm"
                    format="dd.MM.yyyy HH:mm"
                    :placeholder="$t('observations_videos.search_input_format')"
                />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <span class="span-title">{{ $t('orders.info') }}</span>
                    <b-form-textarea
                        id="info"
                        v-model="orderUpdate.info"
                        :rows="3"
                        :max-rows="6"
                    />
                </b-row>
                <h4 class="form-header">{{ $t('orders.executor') }}</h4>
                <span class="tip-light"> {{ $t('orders.executor_tip') }}</span>
                <b-form
                    v-if="orderUpdate.executors.length > 0"
                    inline
                >
                    <div class="col-sm-12 inspectable-container">
                        <div
                            v-for="item in orderUpdate.executors"
                            :key="item.id"
                            :class="{'d-inline' : true}"
                        >
                            <span
                                class="inspectables inspectables-selected"
                                @click="updateExecutorSelection(item.company)"
                            >
                              {{ item.company.name }}
                              <strong>
                                X
                              </strong>
                            </span>
                        </div>
                    </div>
                </b-form>
                <div
                    v-else
                    class="col-sm-12 small-pad"
                >
                    <span>
                      {{ $t('orders.no_executors') }}
                    </span>
                </div>
                <span
                    v-if="orderUpdate.executors.length !== executors.length"
                    class="span-title pt-2"
                >
                    {{ $t('orders.add_executor') }}
                </span>
                <div class="col-sm-12 row inspectable-container nopads pb-3">
                    <div
                        v-for="item in executors"
                        :key="item.id"
                        class="col-sm-6 col-md-4 col-lg-3 col-xl-2 inline inspectables nopads"
                        :class="{ 'inspectables-disabled' : isSelected(item.id) }"
                        @click="updateExecutorSelection(item)"
                    >
                        {{ item.name }}
                    </div>
                </div>

                <!-- Plan section-->
                <hr class="mb-1 mt-1"/>
                <b-row class="nopads pb-2">
                    <b-col class="nopads" cols="10">
                        <h4 class="form-header">{{ $t('orders.plan') }}</h4>
                    </b-col>
                    <b-col class="nopads pt-2">
                        <font-awesome-icon
                            icon="arrow-down"
                            class="pointer right no-focus arrow-icon dont-print"
                            v-bind:class="{rotated : planVisible}"
                            v-b-toggle.planFeatures
                            @click.stop="planVisible = !planVisible"/>
                    </b-col>
                </b-row>
                <b-collapse id="planFeatures">
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads pr-1">
                        <span class="span-title">
                          {{ $t('orders.planned_start') }}
                        </span>
                            <b-form-input
                                id="start"
                                type="date"
                                :value="timestampToDateInput(orderUpdate.planned_start)"
                                size="sm"
                                @change="updateStartDate"
                            />
                        </div>
                        <div class="col-sm-6 nopads pr-1">
                        <span class="span-title">
                          {{ $t('orders.planned_ready') }}
                        </span>
                            <b-form-input
                                id="end"
                                type="date"
                                :value="timestampToDateInput(orderUpdate.planned_ready)"
                                size="sm"
                                @change="updateEndDate"
                            />
                        </div>
                    </b-row>
                    <b-row class="nopads pb-4">
                        <div class="col-sm-6 nopads pr-1">
                        <span class="span-title">
                          {{ $t('orders.amount') }} ({{ orderUpdate.unit ? getUnitText(orderUpdate.unit) : '-' }})
                        </span>
                            <b-form-input
                                id="orderedAmount"
                                v-model="orderUpdate.ordered_amount"
                                type="number"
                                size="sm"
                            />
                        </div>
                        <!-- Realized amount not relevant when adding new -->
                        <div class="col-sm-6 nopads pr-1">
                        <span class="span-title">
                            {{ $t('orders.realized_amount') }}  ({{ orderUpdate.unit ? getUnitText(orderUpdate.unit) : '-' }})
                        </span>
                            <b-form-input
                                id="realizedAmount"
                                v-model="orderUpdate.realized_amount"
                                type="number"
                                size="sm"
                            />
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <span class="span-title">{{ $t('orders.plan_info') }}</span>
                        <b-form-textarea
                            id="plan"
                            v-model="orderUpdate.plan"
                            :rows="3"
                            :max-rows="6"
                        />
                    </b-row>
                </b-collapse>

                <!-- ELY Section -->
                <div v-if="contract && contract.harja_contract_id" class="nopads">
                    <hr class="mb-1 mt-1"/>
                    <b-row class="nopads pb-2">
                        <b-col class="nopads" cols="10">
                            <h4 class="form-header">{{ $t('orders.external_section') }}</h4>
                        </b-col>
                        <b-col class="nopads pt-2">
                            <font-awesome-icon
                                icon="arrow-down"
                                class="pointer right no-focus arrow-icon dont-print"
                                v-bind:class="{rotated : elyVisible}"
                                v-b-toggle.extFeatures
                                @click.stop="elyVisible = !elyVisible"/>
                        </b-col>
                    </b-row>
                    <b-collapse id="extFeatures">
                        <b-row class="nopads pb-4">
                            <!-- External upload switch -->
                            <div class="col-sm-6 nopads">
                                <span class="span-title">{{ $t('orders.external_upload_header')}}</span>
                                <b-row class="nopads">
                                    <b-form-checkbox
                                        class="pt-1"
                                        size="sm"
                                        v-model="orderUpdate.external_upload"
                                    >
                                        {{ $t('orders.external_upload_checkbox_text')}}
                                    </b-form-checkbox>
                                </b-row>
                                <span class="tip-light">{{ $t('orders.external_upload_hint')}}</span>
                            </div>
                            <div class="col-sm-6 nopads pr-1">
                                <span class="span-title">
                                    {{ $t('orders.external_task_id') }}
                                </span>
                                <div class="nopads" v-if="orderUpdate.external_tasks !== null">
                                    <b-row
                                       v-for="(eTask, index) in orderUpdate.external_tasks"
                                       v-bind:key="eTask.id"
                                       style="border-bottom: #d9d9d9 1px solid"
                                       class="nopads pt-1 pb-1">
                                            <div class="col-10 nopads">
                                                <span
                                                    class="nopads col-10 pr-2"
                                                style="font-size: .9em">
                                                    {{ eTask.external_task_id }} - {{ eTask.external_task_descr }}
                                                </span>
                                            </div>
                                            <div class="col-2 nopads text-right">
                                                <b-button
                                                    :id="'removeGeometry' + eTask.id"
                                                    variant="danger"
                                                    size="sm"
                                                    class="pt-1 pb-1 mx-1"
                                                    @click.stop="removeExtTask(index)"
                                                >
                                                    <i class="fa fa-trash" style="font-size: .8em; padding: 0; line-height: 1em;"/>
                                                </b-button>
                                            </div>
                                </b-row>
                                </div>
                                <b-row v-else class="nopads mb-1">
                                    <span class="col-10">-</span>
                                </b-row>
                                <b-row class="nopads mt-2">
                                    <span class="col-9 nopads pr-2 tip-light">{{ $t('orders.external_task_hint')}}</span>
                                    <div class="col-3 text-right pt-2 nopads">
                                        <b-button
                                            variant="info"
                                            size="sm"
                                            @click.stop="fetchExtTasks"
                                        >
                                            {{ $t('common.add') }}
                                        </b-button>
                                    </div>
                                </b-row>
                            </div>
                        </b-row>
                        <b-row class="nopads pb-4">
                            <!-- External upload switch -->
                            <div class="col-sm-6 nopads">
                                <span class="span-title">{{ $t('orders.external_inspection_header')}}</span>
                                <b-row class="nopads">
                                    <b-form-checkbox
                                        class="pt-1"
                                        size="sm"
                                        v-model="orderUpdate.generate_external_reports"
                                    >
                                        {{ $t('orders.external_generate_report_checkbox_text')}}
                                    </b-form-checkbox>
                                </b-row>
                                <span class="tip-light">{{ $t('orders.external_inspection_hint')}}</span>
                            </div>
                        </b-row>
                    </b-collapse>
                </div>

                <!-- Order geometry -->
                <hr class="mb-1 mt-1"/>
                <b-row class="nopads pb-2">
                    <b-col class="nopads" cols="8">
                        <h4 class="form-header">{{ $t('orders.geometry')}}</h4>
                        <span class="tip-light">{{ $t('orders.geometry_hint')}}</span>
                    </b-col>
                    <b-col class="nopads pt-4">
                        <div class="button-container" v-if="!geomLoading">
                            <b-button
                                v-if="geometrySet"
                                variant="danger"
                                class="form-button"
                                :disabled="loading"
                                size="sm"
                                @click.stop="removeOrderGeometry"
                            >
                                {{ $t('orders.remove_geometry') }}
                            </b-button>
                            <b-button
                                v-if="geometrySet"
                                variant="info"
                                class="form-button"
                                :disabled="loading"
                                size="sm"
                                @click.stop="showOrderGeometry"
                            >
                                {{ $t('orders.show_geometry') }}
                            </b-button>
                            <b-button
                                v-if="!geometrySet"
                                variant="success"
                                class="form-button"
                                :disabled="loading"
                                @click.stop="addOrderGeometry"
                            >
                                {{ $t('orders.add_geometry') }}
                            </b-button>
                        </div>
                        <div class="button-container" style="font-size: .9em; color: #BCBCBC" v-else>{{$t('common.loading')}}</div>
                    </b-col>
                </b-row>
                <!-- Material reporting section-->
                <hr class="mb-1 mt-1"/>
                <b-row class="nopads pb-2">
                    <b-col class="nopads" cols="10">
                        <h4 class="form-header">
                            {{$t('orders.material_reporting')}} ({{ orderUpdate.material_reporting ? $t('common.in_use') : $t('common.inactive')}})
                        </h4>
                        <span class="tip-light">{{ $t('orders.material_reporting_hint')}}</span>
                    </b-col>
                    <b-col class="nopads pt-2">
                        <font-awesome-icon
                            icon="arrow-down"
                            class="pointer right no-focus arrow-icon dont-print"
                            v-bind:class="{rotated : materialsVisible}"
                            v-b-toggle.materialsFeat
                            @click.stop="materialsVisible = !materialsVisible"/>
                    </b-col>
                </b-row>
                <b-collapse id="materialsFeat">
                    <b-row class="nopads pb-4">
                        <div class="col-sm-3 nopads pr-1">
                            <b-form-checkbox
                                class="pt-4"
                                size="sm"
                                v-model="orderUpdate.material_reporting"
                            >
                                {{ $t('orders.material_reporting')}}
                            </b-form-checkbox>
                        </div>
                        <div class="col-sm-6 nopads pr-1 pl-1">
                            <span class="span-title">
                                {{ $t('orders.material_prefill') }}
                            </span>
                            <b-form-input
                                id="realizedAmount"
                                v-model="orderUpdate.material_prefill"
                                :placeholder="$t('orders.material_prefill_placeholder')"
                                :disabled="!orderUpdate.material_reporting"
                                type="text"
                                size="sm"
                            />
                        </div>
                        <div class="col-sm-3 nopads pl-1 pr-1">
                            <span class="span-title">
                                {{ $t('orders.material_report_unit') }}
                            </span>
                            <b-form-input
                                id="realizedAmount"
                                v-model="orderUpdate.material_report_unit"
                                :placeholder="$t('orders.material_report_unit_placeholder')"
                                :disabled="!orderUpdate.material_reporting"
                                type="text"
                                size="sm"
                            />
                        </div>
                    </b-row>
                </b-collapse>

                <!-- Cost section -->
                <div v-if="allowPricingEditing" class="nopads">
                    <hr class="mb-1 mt-1"/>
                    <b-row class="nopads pb-2">
                        <b-col class="nopads" cols="10">
                            <h4 class="form-header">{{ $t('orders.pricing_info')}}</h4>
                            <span class="tip-light" style="color: #1a7cd2 !important;">{{ $t('orders.price_hint')}}</span>
                        </b-col>
                        <b-col class="nopads pt-2">
                            <font-awesome-icon
                                v-if="allowPricingEditing()"
                                icon="arrow-down"
                                class="pointer right no-focus arrow-icon dont-print"
                                v-bind:class="{rotated : costsVisible}"
                                v-b-toggle.costFeatures
                                @click.stop="costsVisible = !costsVisible"/>
                        </b-col>
                    </b-row>
                    <b-collapse id="costFeatures">
                        <b-row v-if="allowPricingEditing()" class="nopads">
                            <div class="col-sm-6 nopads pr-1">
                                <pricing-model-filter
                                    v-model="pricingModel"
                                />
                            </div>
                            <div class="col-sm-6 nopads pl-1">
                                <div
                                    v-if="selectPriceList"
                                    class="nopads" >
                                    <span class="span-title">{{ $t('orders.pricelist') }}</span>
                                    <!-- Price list -->
                                    <b-form-select
                                        id="pricingModel"
                                        v-model="priceList"
                                        size="sm"
                                        class="mb-3"
                                    >
                                        <template slot="first">
                                            <option
                                                :value="null"
                                            >
                                                {{ $t('orders.select_pricelist') }}
                                            </option>
                                        </template>
                                        <!-- Loop price lists -->
                                        <option
                                            v-for="option in priceLists"
                                            :key="option.id"
                                            :value="option.id"
                                        >
                                            {{ option.name }}
                                        </option>
                                    </b-form-select>
                                    <div class="col-sm-12 button-container nopads">
                                        <b-button
                                            variant="primary"
                                            class="form-button"
                                            :disabled="loading"
                                            @click.stop="showPriceListEditor()"
                                        >
                                            {{ $t('orders.add_pricelist') }}
                                        </b-button>
                                    </div>
                                </div>
                                <!-- Area price list -->
                                <div
                                    v-else-if="selectAreaPriceList"
                                    class="nopads" >
                                    <span class="span-title">{{ $t('orders.pricelist') }}</span>
                                    <!-- Price list -->
                                    <b-form-select
                                        id="areaPriceList"
                                        v-model="areaPriceList"
                                        size="sm"
                                        class="mb-3"
                                    >
                                        <template slot="first">
                                            <option
                                                :value="null"
                                            >
                                                {{ $t('orders.select_pricelist') }}
                                            </option>
                                        </template>
                                        <!-- Loop price lists -->
                                        <option
                                            v-for="option in areaPriceLists"
                                            :key="option.id"
                                            :value="option.id"
                                        >
                                            {{ option.name }}
                                        </option>
                                    </b-form-select>
                                    <div class="col-sm-12 button-container nopads">
                                        <b-button
                                            variant="primary"
                                            size="sm"
                                            class="form-button"
                                            :disabled="loading"
                                            @click.stop="showAreaPriceListEditor()"
                                        >
                                            {{ $t('orders.add_pricelist') }}
                                        </b-button>
                                    </div>
                                </div>

                                <!-- Single price -->
                                <div
                                    v-else
                                    class="nopads" >
                                    <span class="span-title">{{ $t('orders.unit_price', {currency: currency}) }}</span>
                                    <b-form-input
                                        id="unitPriceAmount"
                                        v-model="orderUpdate.unit_price"
                                        type="number"
                                        size="sm"
                                        :disabled="!pricingModel"
                                    />
                                </div>
                            </div>
                        </b-row>
                        <b-row v-if="allowPricingEditing()" class="nopads pb-3">
                            <div class="col-sm-6 nopads pr-1">
                                        <span class="span-title">
                                            {{ $t('orders.cost_estimation') }}
                                        </span>
                                <b-form-input
                                    id="costEstimation"
                                    v-model="orderUpdate.cost_estimation"
                                    type="number"
                                    size="sm"
                                />
                            </div>
                            <div class="col-sm-6 nopads text-right pt-3">
                                <b-button
                                    variant="info"
                                    size="sm"
                                    class="form-button"
                                    :disabled="loading"
                                    @click.stop="showOrderPriceHistory"
                                >
                                    {{ $t('orders.show_order_price_history') }}
                                </b-button>
                            </div>
                        </b-row>
                    </b-collapse>
                </div>
                <!-- Attachments-->
                <hr class="mb-1 mt-1"/>
                <b-row class="nopads pb-2">
                    <b-col class="nopads" cols="10">
                        <h4 class="form-header">{{ $t('orders.attachments_and_info')}} ({{ orderUpdate.attachments ? orderUpdate.attachments.length : 0}})</h4>
                        <span class="tip-light"  style="color: #337003 !important;">{{ $t('orders.attachments_hint')}}</span>
                    </b-col>
                    <b-col class="nopads pt-2">
                        <font-awesome-icon
                            icon="arrow-down"
                            class="pointer right no-focus arrow-icon dont-print"
                            v-bind:class="{rotated : attachmentsVisible}"
                            v-b-toggle.attachFeat
                            @click.stop="attachmentsVisible = !attachmentsVisible"/>
                    </b-col>
                </b-row>
                <b-collapse id="attachFeat">
                    <div v-if="orderUpdate.attachments && orderUpdate.attachments.length">
                    <span class="span-title">{{ $t('common.attachments') }}</span>
                    <ol>
                        <li class="pointer"
                            v-for="item in orderUpdate.attachments"
                            :key="item.id">
                            <span v-on:click="downloadAttachment(item)">{{ item.file_original_name }}</span>
                            <b-button
                                variant="danger"
                                size="sm"
                                class="mb-1 ml-3 p-0 pl-1 pr-1"
                                style=""
                                @click.stop="removeAttachment(item)"
                            >
                                <i class="fa fa-trash" style="font-size: .8em; padding: 0; line-height: 1em;"/>
                            </b-button>
                        </li>
                    </ol>
                </div>
                    <!-- Attachment upload-->
                    <div class="pb-3">
                        <span class="span-title">{{ $t('work_assignment.add_attachment') }}</span>
                        <div
                            v-for="(path, counter) in attachments"
                            :key="counter"
                            class="col-sm-12 nopads"
                        >
                            <b-form-group
                                class="title"
                                label-for="counter"
                                v-if="counter === 0 || isAttachmentSet(attachments, counter-1)"
                            >
                                <b-form-file
                                    v-model="attachments[counter]"
                                    size="sm"
                                    :accept="getValidAttachmentTypes()"
                                    :placeholder="$t('common.attachment_placeholder')"
                                />
                            </b-form-group>
                        </div>
                    </div>
                </b-collapse>
                <hr class="mb-1 mt-1"/>
                <comment-container
                    class="pt-2 pb-2"
                    :topic="commentTopics.TOPIC_ORDERS"
                    :id="orderUpdate.id"
                    :user="user"
                />
                <hr class="mb-1 mt-1"/>
                <div class="col-12 text-right pr-4">
                <span v-if="orderUpdate.id > 0" class="ban">
                    {{!geomLoading && isOrderUpdateEdited ? $t('orders.order_edited') : null}}
                </span>
                </div>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="secondary"
                        class="form-button"
                        @click.stop="$emit('close')"
                    >
                          {{ $t('common.cancel') }}
                    </b-button>

                    <b-button
                        v-if="orderUpdate.id > 0"
                        variant="primary"
                        class="form-button"
                        @click.stop="copyOrder"
                    >
                          {{ $t('common.copy') }}
                    </b-button>

                    <b-button
                        variant="success"
                        class="form-button"
                        :disabled="loading"
                        @click.stop="submitOrder"
                    >
                         {{ $t('common.save') }}
                    </b-button>
                </div>
            </div>
        </b-form>

        <!-- Geometry importer -->
        <order-geometry
            v-if="showGeometryForm"
            :geometries="orderUpdate.geometries"
            :contract-id="orderUpdate.contract"
            :contract="contract"
            :order-id="orderUpdate.id"
            :is-observer="isObserver"
            :user="user"
            :addGeometriesToMapOnLoad="false"
            :allowLayersPostProcessing="true"
            @geometrySelected="setGeometry"
            @close="hideOrderGeometry"/>

        <!-- Price list addition -->
        <b-modal
            ref="pricelistModal"
            centered
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            hide-footer
            class="nopads"
            size="xl"
            body-class="p-0"
            :title="$t('pricelist.add')">
            <price-list-editor
                :price-list="newList"
                :hideTitle="true"
                @close="$refs.pricelistModal.hide()"
                @closeAndUpdate="priceListAdded"/>
        </b-modal>
        <b-modal
            ref="areaPricelistModal"
            centered
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            hide-footer
            class="nopads"
            size="xl"
            body-class="p-0"
            :hide-header="true">
            <area-price-list-editor
                :price-list="newAreaPriceList()"
                :hideTitle="true"
                @close="$refs.areaPricelistModal.hide()"
                @closeAndUpdate="areaPriceListAdded"/>
        </b-modal>

        <!-- Order price histories -->
            <order-price-history-modal
                v-if="showOrderPriceHistoryModal"
                :order-id="order.id"
                @close="hideOrderPriceHistory"
            />

        <!-- Order copy confirmation -->
        <b-modal
            id="copy_modal"
            ref="copy_modal"
            :hide-header=true
            :hide-footer=true
            @ok="doCopyOrder">
            <div class="col-sm-12 nopads pl-2 pr-2">
                <span class="pl-2 pb-2 title" style="text-transform: uppercase">{{ $t('orders.copy_order') }}</span>
                <span class="pl-2 pr-2 pb-2">{{ $t('orders.copy_confirm') }}</span>
            </div>
            <div class="col-12">
                <!-- Task type selection - only in add mode -->
                <span class="tip-light">
                      {{ $t('orders.contract_type_change') }}
                </span>
                <open-contract-filter
                    :from="(new Date()).toISOString()"
                    :contract="contract"
                    :showOnlyMyContractsOption="true"
                    :show-title="false"
                    :show-company="false"
                    @contractSelected="setCopyContract"/>
                <!-- Task type selection - only in add mode -->
                <span class="tip-light">
                      {{ $t('orders.task_type_change') }}
                </span>
                <div class="pl-1 pr-1">
                    <b-form-select
                        id="tasks"
                        v-model="newTaskTypeObject"
                        size="sm"
                        @change="prefillUnit"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('orders.select_task_type') }}
                            </option>
                        </template>
                        <option
                            v-for="option in contractTaskTypes"
                            :key="option.id"
                            :value="option"
                        >
                            {{ option.task_type.name }}, {{ option.amount }} {{ getUnitText(option.unit) }}
                        </option>
                    </b-form-select>
                </div>
                <!-- Executor selection  -->
                <span class="tip-light">
                      {{ $t('orders.executors_change') }}
                </span>
                <div class="pl-1 pr-1">
                    <multiselect
                        v-model="selectedExecutors"
                        class="multiselect_routa"
                        :placeholder="$t('orders.select_contractor')"
                        :deselect-label="$t('common.remove_selection')"
                        :select-label="$t('common.select')"
                        :close-on-select="false"
                        :multiple="true"
                        label="name"
                        track-by="id"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :options="executors"
                        :disabled="loading"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
                              <span class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                              </span>
                        </template>
                        <template
                            slot="option"
                            slot-scope="props"
                        >
                            <div class="option__desc">
                  <span class="option__title multiselect_detail_block">{{
                          props.option.name
                      }}</span>
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>
            </div>
            <div class="col-12 button-container">
                <b-button size="sm" class="form-button" variant="secondary" @click="cancelCopying">
                    {{ $t('common.no') }}
                </b-button>
                <b-button size="sm" class="form-button" variant="success" @click="doCopyOrder">
                    {{ $t('common.yes') }}
                </b-button>
            </div>
        </b-modal>

        <!-- External task selection modal -->
        <b-modal
            ref="extTaskModal"
            centered
            hide-footer
            class="nopads"
            :title="$t('orders.select_task')">
            <external-task-selector
                ref="extTaskSelector"
                :tasks="extTasks"
            />
            <div class="col-12 button-container">
                <b-button size="sm" class="form-button" variant="secondary" @click="$refs.extTaskModal.hide()">
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button size="sm" class="form-button" variant="success" @click="setExtTask()">
                    {{ $t('common.save') }}
                </b-button>
            </div>
        </b-modal>

        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {units, workManagementHelper} from '../mixins/WorkManagementMixin'
import {userHelper} from '../mixins/UserMixin'
import OrderWizard from './OrderWizard'
import {attachmentHelper} from "@/components/mixins/AttachmentMixin";
import {priceHelper} from "../mixins/PricingMixin";
import PriceListEditor from "@/components/pricingmodels/pricelists/PriceListEditor";
import PricingModelFilter from "../pricingmodels/PricingModelFilter"
import OrderGeometry from "./OrderGeometry";
import {measureDistanceMixin} from "../mixins/MeasureDistanceMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {commentTopics} from "@/components/mixins/CommentMixin";
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import OrderPriceHistoryModal from "@/components/order/OrderPriceHistoryModal";
import AreaPriceListEditor from "@/components/pricingmodels/pricelists/AreaPriceListEditor";
import ExternalTaskSelector from "@/components/velho/ExternalTaskSelector";
import CommentContainer from "@/components/comments/CommentContainer.vue";

export default {
    name: 'OrderEditor',
    components: {
        CommentContainer,
        ExternalTaskSelector,
        AreaPriceListEditor, OrderPriceHistoryModal,
        OpenContractFilter, PricingModelFilter, OrderGeometry, PriceListEditor, OrderWizard},
    mixins: [workManagementHelper, restApi, userHelper, commentTopics,
        attachmentHelper, priceHelper, measureDistanceMixin, units, timeUtils],
    props: {
        contract: {
            type: Object,
            default: null
        },
        order: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        isObserver: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            loading: false,
            planVisible: false,
            elyVisible: false,
            geometryVisible: false,
            costsVisible: false,
            attachmentsVisible: false,
            materialsVisible: false,
            executors: [],
            persons: [],
            contractTaskTypes: [],
            orderUpdate: JSON.parse(JSON.stringify(this.order)),
            originalOrderUpdate: {},
            attachments: new Array(100),
            pricingModel: null,
            priceLists: [],
            areaPriceLists: [],
            contracts: [],
            extTasks: [],
            contractSet: null,
            priceList: null,
            areaPriceList: null,
            newList: null,
            showGeometryForm: false,
            showOrderPriceHistoryModal: false,
            geometrySet: false,
            geomModified: false,
            geomLoading: false,
            selectPriceList: false,
            selectAreaPriceList: false,
            selectedExecutors: [],
            copyEnabled: false,
            newTaskTypeObject: null,
            closed: 50,
            aborted: 60
        }
    },
    watch: {
        pricingModel: function() {
            this.initSelectPriceList()
        },
        contractSet() {
            this.fetchContractTaskTypes(this.contractSet)
        }
    },
    computed: {
        currency() {
            return process.env.VUE_APP_CURRENCY || '€'
        },
        commentTopics() {
            return commentTopics
        },
        isOrderUpdateEdited() {
            return JSON.stringify(this.originalOrderUpdate) !== JSON.stringify(this.orderUpdate)
                || this.orderUpdate.pricing_model && (!this.pricingModel || this.pricingModel.id !== this.orderUpdate.pricing_model.id)
                || !this.orderUpdate.pricing_model && this.pricingModel && this.pricingModel.id
                || !this.orderUpdate.price_list && this.priceList && this.priceList.id
                || this.orderUpdate.price_list && this.priceList !== this.orderUpdate.price_list.id
                || !this.orderUpdate.area_price_list && this.areaPriceList && this.priceList.id
                || this.orderUpdate.area_price_list && this.areaPriceList !== this.orderUpdate.area_price_list.id
                || this.geomModified
        }
    },
    created: function () {
        if (this.orderUpdate.id > 0) {
            this.fetchPersons()
            this.fetchOrderGeometry()
        }
        if (this.contract) {
            this.setContract(this.contract, true)
            if(this.contract.harja_contract_id && this.orderUpdate.id < 0) {
                this.orderUpdate.external_upload = true
            }
        } else if(this.orderUpdate.contract) {
            // set person fetching when new order is being added
            // this way we prevent unnecessary fetching
            if(this.orderUpdate.id < 0) {
                this.fetchPersons()
            }
            this.fetchExecutors()
            this.fetchContractTaskTypes()
        } else {
            this.fetchExecutors()
        }
        if (this.orderUpdate.pricing_model) {
            this.pricingModel = this.orderUpdate.pricing_model
        }
        if (this.orderUpdate.price_list) {
            this.priceList = this.orderUpdate.price_list.id
        }
        if (this.orderUpdate.area_price_list) {
            this.areaPriceList = this.orderUpdate.area_price_list.id
        }
        if (!this.orderUpdate.complete_time) {
            this.orderUpdate.complete_time = this.getToday()
        }
        this.geometrySet = this.orderUpdate.geometries && this.orderUpdate.geometries.length > 0
        this.fetchPriceLists()
        this.fetchAreaPriceLists()
        this.newList = this.newPriceList()
    },

    methods: {
        fetchPriceLists: function () {
            this.loading = true
            this.restFetch(this.priceListUrl,this.handlePriceListResponse)
        },
        fetchAreaPriceLists: function () {
            this.loading = true
            this.restFetch(this.areaPriceListUrl,this.handleAreaPriceListResponse)
        },
        fetchContracts() {
            this.loading = true
            this.contracts = []
            let today = new Date()
            let params = {
                startDate: this.localTimeToUtcTime(today).toISOString(),
                endDate: this.getEndOfTheDay(today).toISOString()
            }
            this.restFetchParams(this.contractOpenUrl, params, this.handleContracts)
        },
        handleContracts(response) {
            this.loading = false
            if (response != null) {
                this.contracts = response.data
            }
        },
        initSelectPriceList () {
            if (this.pricingModel && this.pricingModel.unit1 && this.pricingModel.unit2 &&
                ((parseInt(this.pricingModel.unit1) === 5 || parseInt(this.pricingModel.unit1) === 6) &&
                    parseInt(this.pricingModel.unit2) === 103)) {
                this.selectPriceList = true
                this.selectAreaPriceList = false
            } else if  (this.pricingModel && this.pricingModel.unit1 && this.pricingModel.unit2 &&
                    parseInt(this.pricingModel.unit1) === 3 &&  parseInt(this.pricingModel.unit2) === 104)  {
                this.selectAreaPriceList = true
                this.selectPriceList = false
            } else {
                this.selectPriceList = false
                this.selectAreaPriceList = false
            }
        },
        handlePriceListResponse: function (response) {
            this.loading = false
            this.priceLists = response.data
        },
        handleAreaPriceListResponse: function (response) {
            this.loading = false
            if (response && response.data) {
                this.areaPriceLists = response.data
            } else {
                this.areaPriceLists = []
            }
        },
        showPriceListEditor: function () {
            this.$refs.pricelistModal.show()
        },
        showAreaPriceListEditor: function () {
            this.$refs.areaPricelistModal.show()
        },
        priceListAdded: function (newPriceList) {
            this.priceList = newPriceList.id
            this.fetchPriceLists()
            this.newList = this.newPriceList()
            this.$refs.pricelistModal.hide()
        },
        areaPriceListAdded: function (newPriceList) {
            this.areaPriceList = newPriceList.id
            this.fetchAreaPriceLists()
            this.$refs.areaPricelistModal.hide()
        },
        showOrderPriceHistory() {
            this.showOrderPriceHistoryModal = true
        },
        hideOrderPriceHistory() {
            this.showOrderPriceHistoryModal = false
        },
        setContract: function (contract, excludeTaskTypeReset) {
            this.orderUpdate.contract = contract.id
            this.orderUpdate.contract_name = contract.name
            this.orderUpdate.customer = contract.customer.id
            this.orderUpdate.contractor = contract.contractor.id
            this.orderUpdate.customer_name = contract.customer.name
            this.orderUpdate.contractor_name = contract.contractor.name
            if (!excludeTaskTypeReset) {
                this.orderUpdate.contract_task_type = {id: null}
            }
            this.fetchContractTaskTypes()
            this.fetchExecutors()
            this.fetchPersons()
        },

        setCopyContract: function (contract) {
            this.contractSet = contract ? contract.id : null
        },

        fetchContractTaskTypes: function (contractId) {
            this.loading = true
            this.$nextTick(() => {
                this.restFetchParams(this.contractTaskTypeUrl, {contract: contractId ? contractId : this.orderUpdate.contract}, this.handleContractTaskTypes)
            })
        },

        handleContractTaskTypes: function (response) {
            this.contractTaskTypes = response.data
            this.setContractTaskType()
            this.loading = false
        },

        setContractTaskType() {
            if(this.contractTaskTypes.length > 0 && this.newTaskTypeObject) {
                let found = this.contractTaskTypes.find(item => item.task_type.id === this.newTaskTypeObject.task_type.id)
                if(found) {
                    // set same contract task type from new contract if found
                    this.newTaskTypeObject = found
                } else {
                    // set first contract task type if similar is not found in new contract
                    this.newTaskTypeObject = this.contractTaskTypes[0]
                }
            }
        },

        fetchPersons: function () {
            this.restFetchParams(this.userUrl, {company: this.orderUpdate.contractor}, this.handlePersons)
            this.loading = true
        },

        handlePersons: function (response) {
            this.persons = response.data
            this.loading = false
        },

        fetchOrderGeometry: function () {
            this.restFetch(this.orderUrl+'/'+this.orderUpdate.id+'/geometry',this.handleOrderGeometry)
            this.geomLoading = true
        },

        handleOrderGeometry: function (response) {
            this.geomLoading = false
            if (response && response.data) {
                this.orderUpdate.geometries = response.data.length > 0 ? response.data : null
                this.geometrySet = this.orderUpdate.geometries && this.orderUpdate.geometries.length > 0
            }
            this.originalOrderUpdate = JSON.parse(JSON.stringify(this.orderUpdate));
        },

        fetchExecutors: function () {
            this.restFetch(this.contractorUrl, this.handleExecutors)
        },

        handleExecutors: function (response) {
            this.executors = response.data
        },

        prefillUnit: function () {
            this.$nextTick(() => {
                if (this.orderUpdate.unit === '') {
                    this.orderUpdate.unit = this.getSelectedTaskTypeUnit(this.orderUpdate.contract_task_type.id)
                }
            })
        },

        getSelectedTaskTypeUnit: function (id) {
            var unit = ''
            var index = this.contractTaskTypes.findIndex(obj => obj.id === id)
            if (index >= 0) {
                unit = this.contractTaskTypes[index].unit
            }
            return unit
        },

        updateStartDate: function (event) {
            if (event) {
                this.orderUpdate.planned_start = new Date(event)
            } else {
                this.orderUpdate.planned_start = undefined
            }
        },

        updateEndDate: function (event) {
            if (event) {
                // We need to add 23 h 59 minutes to the date to cover the whole day
                var endDate = new Date(event)
                endDate.setHours(23)
                endDate.setMinutes(59)
                endDate.setSeconds(59)
                this.orderUpdate.planned_ready = endDate
            } else {
                this.orderUpdate.planned_ready = undefined
            }
        },

        setDefaultValues: function (orderUpdate) {
            let order = this.jsonToItem(orderUpdate)
            if (order.customer === null || order.customer < 1) {
                EventBus.$emit('show-alert', this.$t('orders.invalid_name'))
                return undefined
            }
            if (order.contract === null || order.contract < 1) {
                EventBus.$emit('show-alert', this.$t('orders.invalid_name'))
                return undefined
            }
            if (order.contract_task_type === null || order.contract_task_type < 1) {
                EventBus.$emit('show-alert', this.$t('orders.invalid_name'))
                return undefined
            }
            if (order.status === this.closed || order.status === this.aborted) {
                if (!order.complete_time) {
                    if (order.status === this.closed) {
                        EventBus.$emit('show-alert', this.$t('orders.please_enter', { key: this.$t('orders.close_time').toLowerCase()}))
                        return
                    }
                    if (order.status === this.aborted) {
                        EventBus.$emit('show-alert', this.$t('orders.please_enter', { key: this.$t('orders.abort_time').toLowerCase()}))
                        return
                    }
                    return undefined
                }
            } else {
                order.complete_time = null
            }
            if (this.pricingModel) {
                if (!this.selectPriceList && order.unit_price) {
                    order.pricing_model = {id: this.pricingModel.id}
                    order.unit_price = parseFloat(order.unit_price)*100.0
                    order.price_list = undefined
                    order.area_price_list = undefined
                } else if (this.selectPriceList && this.priceList){
                    order.pricing_model = {id: this.pricingModel.id}
                    order.unit_price = undefined
                    order.price_list = {id: this.priceList}
                    order.area_price_list = undefined
                }  else if (this.selectAreaPriceList && this.areaPriceList){
                    order.pricing_model = {id: this.pricingModel.id}
                    order.unit_price = undefined
                    order.area_price_list = {id: this.areaPriceList}
                    order.price_list = undefined
                }  else {
                    EventBus.$emit('show-alert', this.selectPriceList ? this.$t('orders.select_pricelist') : this.$t('orders.enter_unit_price'))
                    return undefined
                }
            } else {
                order.pricing_model = null
                order.unit_price = undefined
                order.price_list = undefined
            }
            this.orderUpdate.executors.forEach(function (c) {
                c.work_order = {id: this.orderUpdate.id}
            }, this)
            if (order.planned_start && (order.planned_start instanceof Date)) {
                order.planned_start = order.planned_start.toISOString()
            }
            if (order.planned_ready && (order.planned_ready instanceof Date)) {
                order.planned_ready = order.planned_ready.toISOString()
            }
            if (order.geometries) {
                order.geometries.forEach(item => {
                    item.geometry = typeof item.geometry === 'object' && item.geometry !== null ? JSON.stringify(item.geometry) : item.geometry
                })
            }
            return order
        },

        copyOrder: function () {
          this.fetchContracts()
          this.$refs.copy_modal.show()
          this.newTaskTypeObject = this.orderUpdate.contract_task_type
        },

        cancelCopying() {
           this.$refs.copy_modal.hide()
           this.newTaskTypeObject = null
        },

        doCopyOrder: function () {
            this.$refs.copy_modal.hide()
            this.restAdd(this.orderUrl+'/'+this.orderUpdate.id+'/copy', {contractTaskType: this.newTaskTypeObject.id,
                    copyExecutors: this.selectedExecutors && this.selectedExecutors.length > 0 ? this.selectedExecutors : null},
                this.copySuccess, this.fail)
        },

        submitOrder: function () {
            var json = this.setDefaultValues(this.orderUpdate)
            if (json !== undefined) {
                this.loading = true
                if (this.orderUpdate.id < 1) {
                    this.restAdd(this.orderUrl, json, this.success, this.fail)
                } else {
                    this.restUpdate(this.orderUrl, json, this.success, this.fail)
                }
            }
        },

        success: function (data) {
            this.orderUpdate = this.createEditorOrder(data)
            this.uploadAttachment()
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
            this.copyEnabled = false
        },

        isSelected: function (contractorId) {
            return this.orderUpdate.executors.find(item => item.company.id === contractorId) !== undefined
        },

        updateExecutorSelection: function (company) {
            var index = this.orderUpdate.executors.findIndex(item => item.company.id === company.id)
            if (index >= 0) {
                this.orderUpdate.executors.splice(index, 1)
            } else {
                this.orderUpdate.executors.push({company: company})
            }
        },

        uploadAttachment: function () {
            let attachments = this.getValidAttachments(this.attachments)
            if (attachments.length > 0) {
                let formData = new FormData()
                attachments.forEach(file => {
                    if (file !== undefined && file !== null) {
                        formData.append('attachments[]', file)
                    }
                })
                this.restPostWithFile(this.orderAttachmentUrl + this.orderUpdate.id, {}, formData,
                    () => {
                        this.loading = false
                        this.$emit('closeAndUpdate')
                    }, this.fail
                )
            } else {
                this.loading = false
                this.$emit('closeAndUpdate')
            }
        },

        copySuccess: function (data) {
            this.orderUpdate = this.createEditorOrder(data)
            this.copyEnabled = false
            this.fetchOrderGeometry()
            this.loading = false
            EventBus.$emit('show-alert', this.$t('orders.copy_successful'), 'success')
        },

        downloadAttachment: function (item) {
            this.loading = true
            this.restFetch(this.orderAttachmentUrl + this.orderUpdate.id + '/' + item.id,
                response => {
                    this.saveFile(item.file_original_name, response.data)
                    this.loading = false
                }, () => {
                    this.loading = false
                })
        },

        removeAttachment: function (item) {
            if(this.orderUpdate.attachments && this.orderUpdate.attachments.length > 0) {
                var index = this.orderUpdate.attachments.findIndex(attachment => attachment.id === item.id)
                if(index >= 0) {
                    this.orderUpdate.attachments.splice(index, 1)
                }
            }
        },

        allowPricingEditing: function () {
            // Allowed only for contract's customer or contractor
            return this.orderUpdate.contractor === this.user.company.id ||
                this.orderUpdate.customer === this.user.company.id
        },

        showOrderGeometry: function () {
            this.showGeometryForm = true
        },

        removeOrderGeometry: function () {
            this.orderUpdate.geometries = []
            this.geometrySet = false
            this.geomModified = true
        },

        addOrderGeometry: function () {
            this.showGeometryForm = true
        },

        hideOrderGeometry: function () {
            this.showGeometryForm = false
        },

        setGeometry: function (geometries, geometryModified) {
            this.orderUpdate.geometries = [...geometries]
            if(geometryModified) {
                this.geomModified = geometryModified
            }
            this.geometrySet = this.orderUpdate.geometries && this.orderUpdate.geometries.length > 0
            this.hideOrderGeometry()
            if (geometries) {
                let calculatedGeometries = geometries.filter(item => item.calculate)
                if (calculatedGeometries.length > 0) {
                    this.orderUpdate.ordered_amount = this.calculateGeoJsonDataLength(calculatedGeometries)
                    this.orderUpdate.unit = units.KILOMETERS
                }
            }
        },
        fetchExtTasks() {
            this.loading = true
            this.restFetchParams(this.extTasksUrl, {contract: this.orderUpdate.contract}, this.handleTasks)
        },
        handleTasks(response) {
            this.loading = false
            if (response && response.data) {
                this.extTasks = response.data
                this.$refs.extTaskModal.show()
            }
        },
        setExtTask() {
            let et = this.$refs.extTaskSelector.getSelectedTask()
            if (!this.orderUpdate.external_tasks) {
                this.orderUpdate.external_tasks = []
            }
            this.orderUpdate.external_tasks.push({
                'external_task_id': et.id,
                'external_task_descr': et.selite,
                'external_type': et.type
            })
            this.$refs.extTaskModal.hide()
            this.$forceUpdate()
        },
        removeExtTask(index) {
            this.orderUpdate.external_tasks.splice(index, 1)
        }
    }
}
</script>
