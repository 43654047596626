<template>
    <div class="col-sm-12 nopads">
        <div>
            <div class="col-sm-12 search-form-title">
            <font-awesome-icon icon="search"/>
            {{ $t('common.search') }}
        </div>
        <b-form
            class="no-margin"
            @submit.prevent="onSubmit"
            @reset.prevent="onReset"
        >
            <!-- Company filter for admin -->
            <transition name="fade">
                <div
                    v-if="isAdmin"
                    class="col-sm-12"
                >
                    <company-filter v-model="company"/>
                </div>
            </transition>

            <contract-filter
                ref="contractFilter"
                :user="user"
                :show-only-my-contracts-option="true"
                @contractSet="setContract"
                @customerSet="setCustomer"
                @contractorSet="setContractor"
            />

            <b-row class="nopads">
                <div class="col-sm-6">
          <span class="search-title">
            {{ $t('ins_report.from_date') }}
          </span>
                    <datetime
                        v-model="startTime"
                        type="datetime"
                        format="dd.MM.yyyy HH:mm"
                        class="routa-input"
                        :placeholder="$t('observations_videos.search_input_format')"
                    />
                </div>

                <div class="col-sm-6">
          <span class="search-title">
            {{ $t('ins_report.to_date') }}
          </span>
                    <datetime
                        v-model="endTime"
                        type="datetime"
                        format="dd.MM.yyyy HH:mm"
                        class="routa-input"
                        :placeholder="$t('observations_videos.search_input_format')"
                    />
                </div>
            </b-row>
            <b-row v-if="isDangerReport">
                <div class="col-sm-6">
                    <b-form-group
                        class="nopads"
                        label-for="type"
                    >
                        <span class="span-title">{{ $t('danger_report.event_type') }}</span>
                        <b-form-select
                            id="nopads"
                            v-model="type"
                            size="sm"
                            class="sm-3"
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('danger_report.select_event_type') }}
                                </option>
                            </template>
                            <option
                                v-for="option in getDangerType()"
                                :key="option.value"
                                :value="option.value"
                            >
                                {{ option.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-sm-6">
                    <b-form-group
                        class="nopads"
                        label-for="type"
                    >
                        <span class="span-title">{{ $t('danger_report.handled') }}</span>
                        <b-form-select
                            id="nopads"
                            v-model="handled"
                            size="sm"
                            class="sm-3"
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('common.all') }}
                                </option>
                            </template>
                            <option
                                :value="true"
                            >
                                {{ $t('danger_report.handled') }}
                            </option>
                            <option
                                :value="false"
                            >
                                {{ $t('danger_report.unhandled') }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import CompanyFilter from '../company/CompanyFilter'
import ContractFilter from '../contract/ContractFilter'
import {dangerHelper} from '../mixins/DangerMixin'

export default {
    name: 'search-filter',
    components: {ContractFilter, CompanyFilter},
    mixins: [restApi, dangerHelper],
    props: {
        isAdmin: Boolean,
        isDangerReport: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            startTime: '',
            endTime: '',
            company: null,
            customer: null,
            contract: null,
            contractor: null,
            handled: null,
            type: null
        }
    },

    methods: {
        setContract: function (contract) {
            this.contract = contract
        },

        setCustomer: function (customer) {
            this.customer = customer
        },

        setContractor: function (contractor) {
            this.contractor = contractor
        },

        onReset: function () {
            this.startTime = null
            this.endTime = null
            this.company = null
            this.handled = null
            this.type = null
            this.$refs.contractFilter.reset()
        },

        onSubmit: function () {
            let params = {
                endTime: this.endTime,
                startTime: this.startTime,
                customer: this.customer,
                contract: this.contract
            }
            if (this.isAdmin) {
                params.company = this.company
            }
            if (this.isDangerReport) {
                params.type = this.type
                params.handled = this.handled
            }
            this.$emit('search', params)
        }
    }
}
</script>

<style scoped>

</style>
