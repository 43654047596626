<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 nopads">
        <transition name="fade">
            <div
                class="col-sm-12 pb-2 result-table"
            >
                <b-table
                    id="resultTable"
                    ref="resultTable"
                    :small="true"
                    :responsive="true"
                    :items="tableItems"
                    :fields="fields"
                    :bordered="true"
                    @row-clicked="emitEdit"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(id)="row">
                        {{ row.item.id }}
                    </template>
                    <template v-slot:cell(tasktype)="row">
                        {{ row.item.contract_task_type.task_type.name }}
                    </template>
                    <template v-slot:cell(customer)="row">
                        {{ row.item.contract_task_type.contract.customer.name }}
                    </template>
                    <template v-slot:cell(contract)="row">
                        {{ row.item.contract_task_type.contract.name }}
                    </template>
                    <template v-slot:cell(contractor)="row">
                        {{ row.item.contract_task_type.contract.contractor.name }}
                    </template>
                    <template v-slot:cell(orderer)="row">
                        <span v-if="row.item.created_by">
                            {{ row.item.created_by.last_name }}, {{ row.item.created_by.first_name }}
                        </span>
                    </template>
                    <template v-slot:cell(executor)="row">
                        <div
                            v-for="result of row.item.executors"
                            :key="result.id">
                            {{ result.company.name }}
                        </div>
                    </template>
                    <template v-slot:cell(createtime)="row">
                        {{ row.item.create_time ? toLocalTime(row.item.create_time) : '' }}
                    </template>
                    <template v-slot:cell(status)="row">
                        {{ getStatusString(row.item.status) }}
                    </template>
                    <template v-slot:cell(price)="row">
                        {{ row.item.unit_price ? ((row.item.unit_price/100).toFixed(3) + ' ' + currency +' / ') : ''}}
                        {{ row.item.pricing_model ? unitToText(row.item.pricing_model.unit1) : '' }}
                        {{ row.item.pricing_model && row.item.pricing_model.unit2 ? "/" + unitToText(row.item.pricing_model.unit2) : "" }}
                    </template>
                    <template v-slot:cell(has_geometries)="row">
                        <b-form-checkbox v-model="row.item.has_geometries" disabled>
                        </b-form-checkbox>
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {priceHelper} from "../mixins/PricingMixin";
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'OrderList',
    mixins: [workManagementHelper, priceHelper, timeUtils],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            sortBy: 'tasktype',
            sortDesc: false,
            tableItems: [],
            loading: false
        }
    },
    computed: {
        currency() {
            return process.env.VUE_APP_CURRENCY || '€'
        },
        fields() {
            return [
                {key: 'id', label: 'Id', sortable: true},
                {key: 'tasktype', label: this.$t('orders.task_type'), sortable: true},
                {key: 'customer', label: this.$t('orders.customer'), sortable: true},
                {key: 'contract', label: this.$t('orders.contract'), sortable: true},
                {key: 'contractor', label: this.$t('orders.contractor'), sortable: true},
                {key: 'orderer', label: this.$t('orders.ordered_by'), sortable: true},
                {key: 'info', label: this.$t('orders.info'), sortable: true},
                {key: 'executor', label: this.$t('orders.executor'), sortable: true},
                {key: 'createtime',
                    formatter: (value, key, item) => {
                        return item.create_time
                    },
                    label: this.$t('orders.create_time'),
                    sortable: true,
                    sortByFormatted: true
                },
                {key: 'status', label: this.$t('orders.status'), sortable: true},
                {key: 'object_number', label: this.$t('orders.object_number'), sortable: true},
                {key: 'price', label: this.$t('orders.unit_price', {currency: this.currency}), sortable: false},
                {key: 'has_geometries', label: this.$t('orders.has_geometries'), sortable: false},
            ]
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    watch: {
        results: function () {
            this.initTableItems()
        }
    },
    methods: {
        initTableItems() {
            this.tableItems = []
            if (this.results) {
                this.results.forEach(item => {
                    item._rowVariant = this.getStatusColorVariant(item.status)
                    this.tableItems.push(item)
                })
            }
        },
        canEdit: function (order) {
            return this.user.company.id === order.contract_task_type.contract.customer.id ||
                this.user.company.id === order.contract_task_type.contract.contractor.id
        },
        emitEdit: function (result) {
            let item = this.results.find(item => result.id === item.id)
            if (item) {
                this.$emit('edit', item)
            }
        },
    },
}
</script>
