<template>
    <div ref="container" class="content-container">
        <b-navbar variant="faded">
            <div class="header">
                <span class="title">{{ $t('spreader_data.title') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <keep-alive>
                <spreader-data-search
                    @search="fetchSpreaderData"
                />
            </keep-alive>
            <transition v-if="results && results.length > 0" name="fade">
                <spreader-material-charts :results="results"/>
            </transition>
            <spreader-data-list
                :results="results"
                @rowSelected="handleRowSelection"
                @showAll="showAllOnMap"
                @exportToExcel="exportToExcel"
                @removeItem="onRemoveItem"
            />
            <!-- Map modal for showing points -->
            <transition name="fade">
                <spreader-data-map
                    v-if="mapVisible"
                    :spreader-data="details"
                    :spreader-all-data="multipleDetails"
                    @close="mapVisible = false"
                />
            </transition>
            <!-- Harja submission modal -->
            <b-modal
                v-model="harjaSenderVisible"
                size="xl"
                dialog-class="harja-sender-modal"
                modal-class="nooverflow"
                body-class="p-0 m-0"
                :hide-footer="true"
                :title="$t('spreader_data.send_to_harja_title')"
            >
                <spreader-data-harja-sender
                    :user="this.user"
                    :isAdmin="this.isAdmin"
                    :item="spreaderDataToharjaItem"
                    :preSelectedOrder="prefillOrder"
                    @close="closeHarjaSender"
                    @triggerListUpdate="triggerListUpdate"
                    @closeAndUpdate="closeAndUpdate"
                />
            </b-modal>
            <!-- Harja removal modal -->
            <b-modal
                v-model="harjaRemoverVisible"
                :hide-footer="true"
                :hide-header-close="true"
                :title="$t('spreader_data.remove_external')"
            >
                <b-row class="col-12 mb-2">
                    <span>{{ $t('spreader_data.remove_text')}}</span>
                </b-row>
                <div class="col-12 text-right">
                    <b-button
                        variant="secondary"
                        class="form-button"
                        @click="closeRemoveModal">
                        {{ $t('common.cancel')}}
                    </b-button>
                    <b-button
                        variant="danger"
                        class="form-button"
                        @click="submitExternalRemoval">
                        {{ $t('spreader_data.remove_data')}}
                    </b-button>
                </div>

            </b-modal>

           <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import SpreaderDataSearch from "@/components/materials/spreaderdata/SpreaderDataSearch";
import SpreaderDataList from "@/components/materials/spreaderdata/SpreaderDataList";
import SpreaderDataHarjaSender from "@/components/materials/spreaderdata/SpreaderDataHarjaSender";
import {restApi} from "@/components/mixins/RestApiMixin";
import SpreaderMaterialCharts from "@/components/materials/spreaderdata/SpreaderMaterialCharts";
import SpreaderDataMap from "@/components/materials/spreaderdata/SpreaderDataMap";
import {spreaderDataMixin} from "@/components/mixins/SpreaderDataMixin";
import {mapHelper} from "@/components/mixins/MapMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import {EventBus} from "@/event-bus";
import {downloadHelper} from "@/components/mixins/DownloadMixin";

export default {
    name: 'SpreaderData',
    components: {
        SpreaderDataMap,
        SpreaderMaterialCharts,
        SpreaderDataList,
        SpreaderDataSearch,
        SpreaderDataHarjaSender
    },
    mixins: [restApi, spreaderDataMixin, mapHelper, timeUtils, downloadHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
    },
    data: function () {
        return {
            results: [],
            details: null,
            multipleDetails: [],
            markerSelections: [],
            loading: false,
            mapVisible: false,
            harjaSenderVisible: false,
            harjaRemoverVisible: false,
            spreaderDataToharjaItem: {},
            lastSearchedParams: null,
            prefillOrder: null
        }
    },
    mounted: function () {
        this.lastSearchedParams = {}
    },
    methods: {
        fetchSpreaderData: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.restFetchParams(this.spreaderDataUrl, this.lastSearchedParams, this.handleResponse)
        },
        handleResponse: function (response) {
            this.loading = false
            if (response && response.data) {
                this.results = response.data
            }
        },
        handleRowSelection: function (item, openHarjaSender = false) {
            if(openHarjaSender) {
                this.spreaderDataToharjaItem = item
                this.harjaSenderVisible = true
            } else {
                this.showDataOnMap(item)
            }
        },

        onRemoveItem(item) {
            this.spreaderDataToharjaItem = item
            this.harjaRemoverVisible = true
        },

        submitExternalRemoval() {
            if (this.spreaderDataToharjaItem) {
                this.loading = true
                this.restAdd(this.spreaderDataExtRemovalUrl, {items: [this.spreaderDataToharjaItem.id]},
                    this.removalSuccess, this.removalFailed)
            }
        },

        removalSuccess() {
            this.loading = false
            this.harjaRemoverVisible = false
            this.fetchSpreaderData(this.lastSearchedParams)
        },

        removalFailed() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('spreader_data.removal_error'))
        },

        closeRemoveModal() {
            this.harjaRemoverVisible = false
            this.spreaderDataToharjaItem = {}
        },

        showDataOnMap: function (item) {
            this.loading = true
            this.restFetch(this.spreaderDataUrl+"/"+item.id, this.handleDetails)
        },

        handleDetails(response) {
            this.loading = false
            if (response && response.data) {
                this.details = response.data
                this.mapVisible = true
            }
        },
        handleMultipleDetails(response) {
            this.loading = false
            if (response && response.data) {
                this.multipleDetails = response.data
                this.mapVisible = true
            } else if(response && response.status === 500) {
                EventBus.$emit('show-alert', this.$t('trip_list.err_too_much'))
            }
        },
        closeAndUpdate(order) {
            this.prefillOrder = order
            this.closeHarjaSender()
            this.fetchSpreaderData(this.lastSearchedParams)
        },
        triggerListUpdate() {
            this.fetchSpreaderData(this.lastSearchedParams)
        },
        closeHarjaSender: function () {
            this.spreaderDataToharjaItem = {}
            this.harjaSenderVisible = false
        },

        showAllOnMap() {
            if(this.results && this.results.length > 0) {
                let params = this.lastSearchedParams
                params.geometry = 1
                this.loading = true
                this.restFetchParams(this.spreaderDataUrl, params, this.handleMultipleDetails)
            }
        },

        exportToExcel() {
            this.loading = true
            this.restGetDownloadFile(this.spreaderDataXlsxUrl, {
                ...this.lastSearchedParams,
                ...this.getCommonReportParams(this.lastSearchedParams)
            }, this.excelDownloadSuccess, this.fileDownloadFail)
        },
        excelDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('spreader_data.report_file_name'))
        },
        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
    }
}
</script>
<style scoped>
    .nooverflow {
        overflow: hidden;
    }
    .harja-sender-modal {
        /** Show Bus event aler banner top of the modal */
        z-index: 4999 !important;
    }
</style>
