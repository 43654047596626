<template>
    <div ref="mapObserver" class="details-container">
        <div class="large_dynamic_width nopads" style="background-color: #FFFFFF; margin: auto">
            <div class="col-12 geom-title pb-1" ><h3 class="nopads">{{ $t('orders.geometry')}}</h3></div>
            <!-- Top control buttons -->
            <div class="col-12 text-left nopads pb-1">
                <b-button-toolbar>
                    <div class="row">
                    <!-- New geometry additions -->
                    <b-button-group class="mx-1 mt-1">
                        <b-dropdown
                            variant="primary"
                            size="sm"
                            id="user-dropdown"
                            :text="$t('orders.add_layer')">
                            <span class="d-inline-block">
                                <b-dropdown-item
                                    id="importNewGeometry"
                                    :disabled="loading || addShape || addGeoJson || preEditedGeometriesState !== null"
                                    @click="importNewGeometry"
                                >
                                    {{ $t('orders.add_geometry_from_file') }}
                                </b-dropdown-item>
                            </span>
                            <span class="d-inline-block">
                                <b-dropdown-item
                                    id="importNewGeometryJson"
                                    :disabled="loading || addShape || addGeoJson || preEditedGeometriesState !== null"
                                    @click="importNewGeometryGeoJson"
                                >
                                    {{ $t('orders.add_geometry_from_geojson_file') }}
                                </b-dropdown-item>
                            </span>
                            <span class="d-inline-block">
                                <b-dropdown-item
                                    id="addEmptyGeometry"
                                    @click="showGeometryModal = true"
                                    :disabled="preEditedGeometriesState !== null"
                                >
                                    {{ $t('orders.add_empty_geometry') }}
                                </b-dropdown-item>
                            </span>
                        </b-dropdown>
                    </b-button-group>
                    <!-- Copy & paste -->
                    <b-button-group class="mx-1 mt-1">
                    <span v-b-tooltip:fullScreenContainer.hover :title="$t('orders.copy_geometry_tooltip')" class="d-inline-block">
                        <b-dropdown
                            variant="primary"
                            size="sm"
                            id="user-dropdown-map"
                            :text="$t('common.copy')">
                        <span class="d-inline-block">
                            <b-dropdown-item
                                id="copyAllGeometries"
                                :disabled="loading"
                                @click="copyAllGeometries()"
                            >
                                {{ this.$t('orders.copy_all_geometry') }}
                            </b-dropdown-item>
                        </span>
                        <span class="d-inline-block">
                            <b-dropdown-item
                                id="copyInsidePolygon"
                                @click.stop="copyInsidePolygon()"
                                :disabled="loading"
                            >
                                {{ this.$t('orders.copy_geometry_inside_polygon') }}
                            </b-dropdown-item>
                        </span>
                        <span class="d-inline-block">
                            <b-dropdown-item
                                id="copySelectedGeometry"
                                @click.stop="copySelectedGeometry"
                                :disabled="loading"
                            >
                                {{ this.$t('orders.copy_selected_geometry') }}
                            </b-dropdown-item>
                        </span>
                    </b-dropdown>
                      </span>
                        <span v-b-tooltip:fullScreenContainer.hover :title="$t('orders.paste_geometry_tooltip')" class="d-inline-block">
                        <b-button
                            id="pasteGeometry"
                            variant="primary"
                            size="sm"
                            :disabled="$store.state.copiedGeometries === null"
                            @click.stop="pasteGeometry"
                        >
                            {{ $t('common.paste') }}
                        </b-button>
                    </span>
                        <span v-b-tooltip:fullScreenContainer.hover :title="$t('orders.export_geometry_tooltip')" class="d-inline-block">
                        <b-dropdown
                            variant="primary"
                            size="sm"
                            id="user-dropdown-map"
                            :text="$t('common.export')">
                        <span class="d-inline-block">
                            <b-dropdown-item
                                id="copyInsidePolygon"
                                @click.stop="exportGeometryInsidePolygon"
                                :disabled="loading"
                            >
                                {{ this.$t('orders.export_inside_polygon') }}
                            </b-dropdown-item>
                        </span>
                        <span class="d-inline-block">
                            <b-dropdown-item
                                id="copySelectedGeometry"
                                @click.stop="exportSelectedGeometry"
                                :disabled="loading"
                            >
                                {{ this.$t('orders.export_selected_geometry') }}
                            </b-dropdown-item>
                        </span>
                        <span class="d-inline-block">
                            <b-dropdown-item
                                id="copyAllGeometry"
                                @click.stop="exportAllGeometry"
                                :disabled="loading"
                            >
                                {{ this.$t('common.all') }}
                            </b-dropdown-item>
                        </span>
                    </b-dropdown>
                      </span>
                    </b-button-group>
                    <!-- Edit geometries -->
                    <b-button-group class="mx-1 mt-1">
<!--                        <span v-b-tooltip:fullScreenContainer.hover :title="$t('orders.delete_geometry_tooltip')" class="d-inline-block">-->
<!--                            <b-button-->
<!--                                id="removeGeometryPaths"-->
<!--                                variant="primary"-->
<!--                                size="sm"-->
<!--                                :disabled="loading || selectedLayers.length === 0"-->
<!--                                @click.stop="removeGeometryPaths"-->
<!--                            >-->
<!--                                {{ $t('orders.remove_geometry') }}-->
<!--                            </b-button>-->
<!--                        </span>-->
                        <span v-b-tooltip:fullScreenContainer.hover :title="$t('orders.export_geometry_tooltip')" class="d-inline-block">
                            <b-dropdown
                                variant="primary"
                                size="sm"
                                id="user-dropdown-map"
                                :text="$t('common.remove')">
                            <span class="d-inline-block">
                                <b-dropdown-item
                                    id="removeSelectedGeometry"
                                    @click.stop="removeGeometryPaths"
                                    :disabled="loading || selectedLayers.length === 0"
                                >
                                    {{ this.$t('orders.export_selected_geometry') }}
                                </b-dropdown-item>
                            </span>
                            <span class="d-inline-block">
                                <b-dropdown-item
                                    id="removeInsidePolygon"
                                    @click.stop="deleteGeometriesByPolygon"
                                    :disabled="loading || !polygonDrawing"
                                >
                                    {{ this.$t('orders.export_inside_polygon') }}
                                </b-dropdown-item>
                            </span>
                            </b-dropdown>
                      </span>
                        <span v-b-tooltip:fullScreenContainer.hover :title="$t('orders.restore_previous_geometry')" class="d-inline-block">
                            <b-button
                                id="restoreGeometryPath"
                                variant="primary"
                                size="sm"
                                :disabled="loading || this.removedGeometries.length === 0"
                                @click.stop="restoreGeometryPath"
                            >
                                {{ $t('orders.restore_previous_geometry') }}
                            </b-button>
                        </span>
                    </b-button-group>
                    <!-- Line drawing buttons -->
                    <b-button-group class="mx-1 mt-1">
                        <span v-b-tooltip:fullScreenContainer.hover :title="$t('orders.save_tooltip')" class="d-inline-block">
                            <b-button
                                id="addToGeometry"
                                variant="primary"
                                size="sm"
                                :disabled="loading || !addPolylineEnabled && !addPointEnabled"
                                @click.stop="preEditedGeometriesState === null && hasPropertiesInFeature(loadedGeometries[loadedGeometrySelected])
                                || hasGeometryProperties(loadedGeometries[loadedGeometrySelected]) ? addPropertiesToGeometry() : addToGeometry()"
                            >
                                {{ this.$t('common.save') }}
                            </b-button>
                        </span>
                        <span v-b-tooltip:fullScreenContainer.hover :title="$t('orders.edit_tooltip')" class="d-inline-block">
                            <b-button
                                id="editGeometry"
                                variant="primary"
                                size="sm"
                                :disabled="loading || selectedLayers.length > 1 || selectedLayers.length === 0 || activeFeature !== ''"
                                @click.stop="editGeometry(getFeatureGeometryType(selectedLayers[0].layer))"
                            >
                                {{ this.$t('common.edit') }}
                            </b-button>
                        </span>
                        <span v-b-tooltip:fullScreenContainer.hover :title="$t('orders.undo_tooltip')" class="d-inline-block">
                            <b-button
                                id="undoLastEdit"
                                variant="primary"
                                size="sm"
                                :disabled="
                                loading ||
                                (!addRoadByClick && (!addPolylineEnabled && !addPointEnabled) && geometryUpdates === 0) ||
                                (addRoadByClick && geometryUpdates === 0)"
                                @click.stop="handleUndoLastEdit"
                            >
                              {{ this.$t('common.undo') }}
                            </b-button>
                        </span>
                        <span v-b-tooltip:fullScreenContainer.hover :title="$t('orders.reset_drawing_tooltip')" class="d-inline-block">
                            <b-button
                                id="resetDrawing"
                                variant="primary"
                                size="sm"
                                :disabled="loading || !addPolylineEnabled && !addPointEnabled || activeFeature !== 'polyline' && activeFeature !== 'point'"
                                @click.stop="resetDrawing"
                            >
                                {{ this.$t('common.cancel') }}
                            </b-button>
                        </span>
                    </b-button-group>
                    </div>
                    <div class="row">
                    <!-- Road clicking buttons -->
                    <b-button-group class="mx-1 mt-1">
                        <span class="d-inline-block">
                            <b-button
                                id="setRoadAddDisabled"
                                variant="primary"
                                size="sm"
                                :disabled="loading || !addRoadByClick && !addRoadBySearch"
                                @click.stop="setRoadAddDisabled"
                            >
                                {{ this.$t('orders.selection_ready') }}
                            </b-button>
                        </span>
                    </b-button-group>
                    <!-- Map data switches -->
                    <b-button-group class="mx-1 mt-1">
                        <b-dropdown
                            variant="primary"
                            size="sm"
                            id="user-dropdown-map"
                            :text="$t('orders.map_data')">
                            <span class="d-inline-block">
                                <b-dropdown-item
                                    id="toggleObservations"
                                    :disabled="loading"
                                    @click="toggleObservations"
                                >
                                    {{ observationsEnabled ? this.$t('orders.hide_observations') : this.$t('orders.show_observations') }}
                                </b-dropdown-item>
                            </span>
                            <span class="d-inline-block">
                                <b-dropdown-item
                                    id="toggleStorages"
                                    @click.stop="toggleStorages"
                                    :disabled="loading"
                                >
                                    {{ !storagesEnabled ? this.$t('orders.show_storages') : this.$t('orders.hide_storages') }}
                                </b-dropdown-item>
                            </span>
                            <span class="d-inline-block">
                                <b-dropdown-item
                                    id="toggleAreas"
                                    @click.stop="toggleContractAreas"
                                    :disabled="loading"
                                >
                                    {{ !areasEnabled ? this.$t('orders.show_areas') : this.$t('orders.hide_areas') }}
                                </b-dropdown-item>
                            </span>
                        </b-dropdown>
                    </b-button-group>
                    </div>
                </b-button-toolbar>
            </div>
            <!-- Map and controls -->
            <div style="position: relative; height: 55vh; padding: 1em; text-align: left;">
                <map-container
                    ref="mapContainer"
                    :menu-items="menuItems"
                    :find-user="false"
                    :center="mapCenter"
                    @onMapChanged="mapChanged"
                    @onMapClicked="mapClicked"
                    @onGeoJsonClicked="onGeoJsonClicked"
                    @onGeoJsonHoverEvent="onGeoJsonHoverEvent"
                    @onGeoJsonHoverExitEvent="onGeoJsonHoverExitEvent"
                    @onZoomLevelChange="onZoomLevelChange"
                    @onMarkerTap="onMarkerTap"
                    @pointerCoordinateMove="pointerCoordinateMove"
                    @onDrag="onDrag"
                    @onDragEnd="onDragEnd"
                />

                <div class="map-controls">
                    <div class="map-controls__list col-12 col-lg-4 nopads">
                    <measure-road-map
                        v-if="measureRoadEnabled"
                        ref="measureRoad"
                        :map="map"
                        style="float: right; z-index: 9999; position: relative"
                        :draggable="true"
                        :confirm-text="$t('common.save')"
                        @close="closeRoadMeasurement"
                        @confirmSelection="setNewGeometryFromMeasure"
                    />
                    <measure-distance-map
                        v-if="measureDistanceEnabled"
                        ref="measureDistances"
                        :map="map"
                        style="float: right; z-index: 2"
                        :measure-distance-results="measureDistances"
                        :measure-distance-target-item="measureDistanceTargetItem"
                        :measure-distance-target-item-callback="measureDistanceTargetItemCallback"
                        :draggable="true"
                        @onRemoveMeasureDistances="removeMeasureDistances"
                    />
                    <selection-tool
                        v-if="polygonDrawing"
                        :polygonDrawing="polygonDrawing"
                        :showEditSelection="false"
                        :showingObservations="observationsEnabled"
                        @observationsToRoadGeometry="showObservationsToRoadGeometry"
                        @showSelectionSelector="showSelectionSelector"
                        @clearPolygon="clearPolygon"
                        @close="hidePolygonDrawing"
                    />
                    <observation-map
                        v-if="observationsEnabled"
                        ref="observations"
                        :map="map"
                        :user="user"
                        :is-observer="isObserver"
                        :selected-contract-ids="[contractId]"
                        :selected-order="orderId"
                    />
                    <material-storage-map
                        v-if="storagesEnabled"
                        ref="storages"
                        :map="map"
                        :selectedContractId="contractId"
                        :require-bounding-box="false"
                        :zoom-level-in-range="true"
                    />
                    <map-areas
                        v-if="areasEnabled"
                        ref="mapAreas"
                        :map="map"
                        :draw-all="true"
                        :company="user.company.id"
                        :selected-contract-ids="[contractId]"
                        :showLabels="true"
                    />
                    </div>
                </div>
                <road-search-wizard
                    :map="map"
                    v-if="showRoadSearchWizard"
                    @close="showRoadSearchWizard = false; loadedGeometrySelected = null;"
                    @roadSelected="addGeoJsonFromSearchToMap"
                />
                <address-search-wizard
                    :map="map"
                    :getCoordinate="true"
                    v-if="showAddressSearchWizard"
                    @pointFetched="addPointFromSearchToMap"
                    @close="showAddressSearchWizard = false; loadedGeometrySelected = null;"
                />
                <material-storage-editor-modal
                    v-model="editStorage"
                    @cancel="editStorage = null"
                    @closeAndUpdate="closeAndUpdateStorage"
                    :user="user"
                />

                <import-file-wizard
                    ref="importfilewizardref"
                    v-if="addGeoJson"
                    :show-only-geo-json-tab="true"
                    :user="user"
                    @close="closeImportFileWizard"
                    @addLayer="addLayerFromGeoJson"
                />
                <transition name="fade">
                <div v-if="addShape" class="col-12 file-dialog" style="z-index: 2999">
                    <shape-import-form
                        @filePicked="shapeFileSelected"/>
                    <div>
                        <span class="span-title pl-2">{{ $t('import_file_wizard.use_coordinate_system') }}: ETRS-TM35FIN (epsg: 3067)</span>
                    </div>
                    <div class="button-container pr-1">
                        <b-button
                            variant="secondary"
                            size="sm"
                            class="form-button"
                            @click.stop="cancel()"
                        >
                            {{ $t('common.cancel') }}
                        </b-button>
                        <b-button
                            variant="success"
                            size="sm"
                            class="form-button"
                            :disabled="!shpFile || !shxFile || !dbfFile || loading"
                            @click.stop="showGeometryOnMap"
                        >
                            {{ $t('orders.show_geometry_on_map') }}
                        </b-button>
                    </div>
                </div>
                </transition>
                <transition name="fade">
                    <order-geometry-layer-editor
                        v-if="showGeometryModal"
                        :show="showGeometryModal"
                        :isEditing="isEditingGeometry"
                        :excludedFields="excludedFields"
                        :geometryData="currentGeometry"
                        @close="closeGeometryModal"
                        @submit="saveGeometry"
                    />
                </transition>
                <transition name="fade">
                    <geometry-layer-download
                        v-if="geometriesForDownload !== null"
                        :geometry-layer="geometriesForDownload"
                        :excluded-fields="excludedFields"
                        :coordinateSystems="coordinateSystems"
                        :download-modal="showDownloadAsFileModal"
                        @downloadGeoJson="downloadGeoJson"
                        @downloadShape="downloadShape"
                        @close="closeDownloadGeometryModal"
                    />
                </transition>
                <transition name="fade">
                    <order-geometry-add-property-modal
                        :show-add-properties-modal="showAddPropertiesModal"
                        :loaded-geometry-selected="loadedGeometrySelected"
                        :loaded-geometries="loadedGeometries"
                        :selected-layer="selectedLayers[0]"
                        :add-point-enabled="addPointEnabled"
                        :add-polyline-enabled="addPolylineEnabled"
                        :new-properties="newProperties"
                        :excluded-fields="excludedFields"
                        @addProperty="addNewPropertyToExisting"
                        @onAddToGeometry="addToGeometry"
                        @removeProperty="removeExistingProperty"
                        @onResetDrawing="resetDrawing(true)"
                    />
                </transition>
            </div>
            <transition name="fade">
                <div v-if="loadedGeometries && loadedGeometries.length > 0">
                <b-row class="nopads">
                    <div class="col-2 file-title">
                        {{ $t('orders.geometry_layer') }}
                    </div>
                    <div class="col-1 file-title ml-1">
                        {{ $t('orders.geometry_color') }}
                    </div>
                    <div class="col-2 text-center file-title">
                        {{ $t('orders.geometry_label') }}
                    </div>
                    <div class="col-1 text-center file-title pr-3 pl-0" v-b-tooltip:fullScreenContainer.hover :title="$t('orders.calculate_tooltip')">
                        {{ $t('orders.geometry_calculate') }}
                    </div>
                    <div class="col-1 file-title pr-0 pl-1" v-b-tooltip:fullScreenContainer.hover :title="$t('orders.polylineends_tooltip')" >
                        {{ $t('orders.geometry_polyline_indicators') }}
                    </div>
                    <div class="col-1 file-title text-center pl-0" v-b-tooltip:fullScreenContainer.hover :title="$t('orders.layer_visibility_tooltips')" >
                        {{ $t('orders.geometry_visibility') }}
                    </div>
                    <div class="col-1 file-title pr-0 pl-0" v-b-tooltip:fullScreenContainer.hover :title="$t('orders.layer_distance_tooltips')" >
                        {{ $t('orders.geometry_km_distance') }}
                    </div>
                    <div class="col-2 file-title pr-0 pl-0 mr-2" v-b-tooltip:fullScreenContainer.hover :title="$t('orders.linewidth_tooltips')" >
                        {{ $t('orders.line_width_text') }}
                    </div>
                </b-row>
                <div v-for="(item, index) in sortGeometryAscending" :key="index" class="left pt-1">
                    <b-row class="nopads">
                        <div :id="'layer-name' + item.name + index" class="col-2">
                            <input class="editable-input" type="text" v-model="item.name" @click.stop="zoomToGeometry(item)">
                        </div>
                        <div class="col-1">
                            <span class="d-inline-block">
                                <color-picker :id="'layer-color' + item.name + index" @selectColor="(color) => selectColor(color, index)" :noRedColor="true" :defaultColor="item.color" />
                            </span>
                        </div>
                        <div class="col-2">
                            <span class="d-inline-block">
                                <b-form-select
                                    v-if="item.geometry && item.geometry.features && item.geometry.features[0] && item.geometry.features[0].properties"
                                    v-model="item.label"
                                    :id="'layer-label' + item.name + index"
                                    size="sm"
                                    class="sm-3"
                                    @change="updateLabel(index)"
                                >
                                    <template slot="first">
                                        <option :value="null">
                                            {{ $t('orders.select_geometry_label') }}
                                        </option>
                                    </template>
                                    <option
                                        v-for="(propKey) in getAllProperties(item)"
                                        :key="propKey"
                                        :value="propKey"
                                    >
                                        {{ propKey }}
                                    </option>
                                </b-form-select>
                            </span>
                        </div>
                            <div :id="'layer-calculate' + item.name + index" class="col-1 nopads text-center">
                                <span class="d-inline-block">
                                    <b-form-checkbox
                                        v-model="item.calculate"
                                        size="sm"/>
                                </span>
                            </div>
                        <div :id="'layer-polylineends' + item.name + index" class="col-1 nopads text-center">
                            <span class="d-inline-block">
                                <b-form-checkbox
                                    v-model="item.addPolylineEnds"
                                    size="sm"
                                    @change="refreshGeoJsonMapObjects(false)"
                                />
                            </span>
                        </div>
                        <div :id="'layer-visibility' + item.name + index" class="col-1 nopads text-center">
                                <span class="d-inline-block">
                                    <b-form-checkbox
                                        :checked="item.visibility"
                                        size="sm"
                                        @change="handleVisibilityChange(index)"
                                    />
                                </span>
                        </div>
                        <div :id="'layer-length' + item.name + index" class="col-1 nopads">
                                <span class="d-inline-block">
                                    {{ getLengthInKilometers(item.geometry) }}
                                </span>
                        </div>
                        <div v-if="isLineString(item.geometry)" :id="'layer-lineWidth' + item.name + index" class="col-1 nopads text-center">
                            <span class="d-inline-block">
                                <b-form-group label-for="lineWidth">
                                    <b-form-select
                                      id="lineWidth"
                                      v-model="item.lineWidth"
                                      size="sm"
                                      @change="changeLineWidth"
                                    >
                                    <template slot="first">
                                        <option :value="null">
                                            {{ $t('orders.select_line_width') }}
                                        </option>
                                    </template>
                                    <option
                                        v-for="option in lineWidthOptions"
                                        :key="option"
                                        :value="option"
                                    >
                                        {{ option }}
                                    </option>
                                    </b-form-select>
                                </b-form-group>
                            </span>
                        </div>
                        <div v-else class="col-1 nopads text-center"/>
                        <div :style="clickDisabledByGeometry(index) ? 'pointer-events: none;' : null" class="col-2 nopads pl-2">
                            <b-button-group vertical>
                                <b-button
                                    @click.stop="move(index, up)"
                                    :disabled="index === 0"
                                    class="btn custom-btn btn-primary"
                                >
                                    <i class="fa fa-chevron-up"/>
                                </b-button>

                                <b-button
                                    @click.stop="move(index, down)"
                                    class="btn custom-btn btn-primary"
                                    :disabled="index === sortGeometryAscending.length - 1"
                                >
                                    <i class="fa fa-chevron-down"/>
                                </b-button>

                            </b-button-group>
                            <b-button-group>
                                <span class="d-inline-block">
                                    <b-button
                                        :id="'removeGeometry' + item.name + index"
                                        variant="danger"
                                        size="sm"
                                        :disabled="clickDisabledByGeometry(index)"
                                        class="pt-1 pb-1 mx-1"
                                        @click.stop="removeGeometry(item, index)"
                                    >
                                        <i class="fa fa-trash" style="font-size: .8em; padding: 0; line-height: 1em;"/>
                                    </b-button>
                                </span>
                                <b-dropdown v-if="getLoadedGeometryType(item) === 'polyline'" :disabled="clickDisabledByGeometry(index)" class="mx-1" size="sm" variant="outline-primary" id="user-dropdown" no-caret>
                                    <template #button-content>
                                        <i class="fa fa-ellipsis-h" style="font-size: .8em; padding: 0; line-height: 1em;"/>
                                    </template>
                                    <span class="d-inline-block">
                                        <b-dropdown-item :id="'addRoadByDrawing' + item.name + index" @click="setNewGeometryByDrawing(index, getLoadedGeometryType(item))">
                                            {{ $t('map.add_road_by_drawing') }}
                                        </b-dropdown-item>
                                    </span>
                                    <span class="d-inline-block">
                                        <b-dropdown-item :id="'addRoadBySearch' + item.name + index" @click="openRoadSearchWizard(index)">
                                            {{ $t('map.add_road_by_search') }}
                                        </b-dropdown-item>
                                    </span>
                                    <span class="d-inline-block">
                                        <b-dropdown-item :id="'addRoadByClick' + item.name + index" @click="setRoadAddEnabled(index)">
                                            {{ $t('map.add_road') }}
                                        </b-dropdown-item>
                                    </span>
                                    <span class="d-inline-block">
                                        <b-dropdown-item :id="'addRoadByMeasurement' + item.name + index" @click="openRoadMeasurement(index)">
                                            {{ $t('map.add_road_by_measurement') }}
                                        </b-dropdown-item>
                                    </span>
                                    <span class="d-inline-block">
                                        <b-dropdown-item :id="'editLayer' + item.name + index" @click="openEditGeometryModal(item)">
                                            {{ $t('orders.edit_layer') }}
                                        </b-dropdown-item>
                                    </span>
                                    <span class="d-inline-block">
                                        <b-dropdown-item :id="'downloadGeometry' + item.name + index" @click="openDownloadGeometryModal(item)">
                                            {{ $t('trip.download_as_file') }}
                                        </b-dropdown-item>
                                    </span>
                                </b-dropdown>
                                <b-dropdown v-if="getLoadedGeometryType(item) === 'point'" :disabled="clickDisabledByGeometry(index)" class="mx-1" size="sm" variant="outline-primary" id="user-dropdown" no-caret>
                                    <template #button-content>
                                        <i class="fa fa-ellipsis-h" style="font-size: .8em; padding: 0; line-height: 1em;"/>
                                    </template>
                                    <span class="d-inline-block">
                                        <b-dropdown-item :id="'addPointByClick' + item.name + index" @click="setNewGeometryByDrawing(index, getLoadedGeometryType(item))">
                                            {{ $t('map.add_point_by_click') }}
                                        </b-dropdown-item>
                                    </span>
                                    <span class="d-inline-block">
                                        <b-dropdown-item :id="'addPointBySearch' + item.name + index" @click="openAddressSearchWizard(index)">
                                            {{ $t('map.add_point_by_search') }}
                                        </b-dropdown-item>
                                    </span>
                                    <span class="d-inline-block">
                                        <b-dropdown-item :id="'editLayer' + item.name + index" @click="openEditGeometryModal(item)">
                                            {{ $t('orders.edit_layer') }}
                                        </b-dropdown-item>
                                    </span>
                                    <span class="d-inline-block">
                                        <b-dropdown-item :id="'downloadGeometry' + item.name + index" @click="openDownloadGeometryModal(item)">
                                            {{ $t('trip.download_as_file') }}
                                        </b-dropdown-item>
                                    </span>
                                </b-dropdown>
                            </b-button-group>
                        </div>
                    </b-row>
                </div>
            </div>
            </transition>
            <hr class="ml-2 mr-2"/>
            <div class="col-12 button-container pb-2">
                <b-button
                    variant="secondary"
                    class="form-button"
                    size="sm"
                    :disabled="loading"
                    @click.stop="close"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    v-if="loadedGeometries || loadedGeometries.length > 0"
                    variant="success"
                    size="sm"
                    class="form-button"
                    :disabled="loading || addShape || addPolylineEnabled || addPointEnabled"
                    @click.stop="emitGeometry"
                >
                    {{ $t('common.save') }}
                </b-button>
            </div>

            <!-- Order copy confirmation -->
            <order-geometry-export-modal
                v-if="showExportGeometriesModal"
                @cancelCopy="cancelCopying"
                @copyGeometry="doCopyOrderGeometry"
            />
            <observation-to-road-geometry-modal
                ref="observationToRoadGeometryModal"
            />

            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
      </div>
    </div>
</template>

<script>
import {orderMixin} from "@/components/mixins/OrderMixin";
import {measureDistanceMixin} from "../mixins/MeasureDistanceMixin";
import {selectFromMapMixin} from "@/components/mixins/SelectFromMapMixin";
import {coordinateSystems} from "@/components/mixins/CoordinateConversionMixin";
import {geometryMixin} from '../mixins/GeometryMixin'
import MeasureRoadMap from "@/components/map/MeasureRoadMap";
import MeasureDistanceMap from "@/components/map/MeasureDistanceMap";
import MapContainer from "../map/MapContainer";
import {restApi} from "../mixins/RestApiMixin";
import {mapHelper} from '../mixins/MapMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {vehicleHelper} from "../mixins/VehicleMixin";
import ShapeImportForm from "../shapeimport/ShapeImportForm";
import {EventBus} from "@/event-bus";
import {mapState} from "vuex"
import ColorPicker from "@/components/ColorPicker"
import RoadSearchWizard from "../search/RoadSearchWizard";
import AddressSearchWizard from "../search/AddressSearchWizard";
import OrderGeometryAddPropertyModal from "./OrderGeometryAddPropertyModal";
import ObservationMap from "../observation/ObservationMap.vue";
import MaterialStorageMap from "../materialstorage/MaterialStorageMap.vue";
import MapAreas from "../area/MapAreas.vue";
import MaterialStorageEditorModal from "../materialstorage/MaterialStorageEditorModal.vue";
import ImportFileWizard from "@/components/shapeimport/ImportFileWizard.vue";
import SelectionTool from "@/components/map/SelectionTool.vue";
import {spatialMixin} from "@/components/mixins/SpatialMixin";
import ObservationToRoadGeometryModal from "../map/ObservationToRoadGeometryModal.vue";
import OrderGeometryLayerEditor from "@/components/order/OrderGeometryLayerEditor.vue";
import GeometryLayerDownload from "@/components/order/GeometryLayerDownload.vue";
import {downloadHelper} from "@/components/mixins/DownloadMixin";
import OrderGeometryExportModal from "@/components/order/OrderGeometryExportModal.vue";

export default {
    name: "OrderGeometry",
    components: {
        OrderGeometryExportModal,
        ObservationToRoadGeometryModal,
        OrderGeometryLayerEditor,
        GeometryLayerDownload,
        SelectionTool,
        ImportFileWizard,
        MaterialStorageEditorModal,
        MapAreas,
        ObservationMap, MaterialStorageMap, MeasureDistanceMap, OrderGeometryAddPropertyModal,
        ShapeImportForm, MapContainer,
        ColorPicker, MeasureRoadMap, RoadSearchWizard, AddressSearchWizard},
    mixins: [orderMixin, restApi, timeUtils, vehicleHelper, measureDistanceMixin, selectFromMapMixin,
        geometryMixin, mapHelper, spatialMixin, downloadHelper],
    props: {
        geometries: {
            type: Array,
            default() {
                return []
            }
        },
        contractId: {
            type: Number,
            default: null
        },
        contract: {
            type: Object,
            default: null
        },
        orderId: {
            type: Number,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        addGeometriesToMapOnLoad: {
            type: Boolean,
            default: true
        },
        allowLayersPostProcessing: {
            type: Boolean,
            default: false
        },
    },
    data: function () {
        return {
            addShape: false,
            addGeoJson: false,
            newName: null,
            newColor: null,
            labelShown: false,
            exportGeometries: [],
            geometriesForDownload: null,
            showExportGeometriesModal: false,
            orderOptions: [],
            contractSet: null,
            lineWidthOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            newProperties: null,
            map: null,
            loading: false,
            drawnFigure: false,
            shpFile: null,
            shxFile: null,
            dbfFile: null,
            showRoadSearchWizard: false,
            showAddressSearchWizard: false,
            showAddPropertiesModal: false,
            measureDistances: [],
            measureDistanceEnabled: false,
            measureDistanceTargetItem: null,
            addPolylineEnabled: false,
            addPointEnabled: false,
            polygonDrawing: false,
            addRoadByClick: false,
            addRoadBySearch: false,
            preEditedGeometriesState: null,
            geometryUpdates: 0,
            newGeometryType: "Point",
            newPolyLineId: -1,
            newPolygonId: -1,
            selectedGeometryItems: [],
            activeFeature: "",
            newFeature: "",
            removeFeature: true,
            editStorage: null,
            showGeometryModal: false,
            isEditingGeometry: false,
            currentGeometry: {},
            showConfirmRemoveFeature: false,
            showDownloadAsFileModal: false,
            geoJsonToAdd: null,
            loadedGeometrySelected: null,
            storagesEnabled: false,
            areasEnabled: false,
            measureRoadEnabled: false,
            observationsEnabled: false,
            loadedGeometries: [],
            geometryModified: false,
            selectedLayers: [],
            removedGeometries: [],
            addLineEnds: false,
            mapCenter: null,
            up: -1,
            down: 1,
            observer: null,
        }
    },

    watch: {
        addRoadByClick() {
            if(this.addRoadByClick) {
              this.addGeometryByClickToast()
            }
        },
        addPolylineEnabled() {
            if(this.addPolylineEnabled) {
                this.addGeometryToast()
            }
        },
        contractSet() {
            this.fetchOrderOptions(this.contractSet)
        },
    },
    created() {
        this.loadedGeometries = [];
        const hasGeometries = this.geometries && this.geometries.length > 0
        if(hasGeometries && this.geometries[0].geometry.features.length > 0 && this.geometries[0].geometry.features[0].geometry.coordinates.length > 0) {
            this.mapCenter = this.setMapCenterByGeometryType(this.geometries[0])
        } else if(this.getMapSettingsIn(['center'])){
            let center = this.getMapSettingsIn(['center'])
            this.mapCenter = {
                y: center.lat,
                x: center.lng
            }
        } else if (this.user && this.user.company.location) {
            this.mapCenter = {
                lon: this.user.company.location.x,
                lat: this.user.company.location.y,
            }
        }
        if(hasGeometries) {
            // Try to show the map as soon as possible
            // Time indexing to next event loop, so it won't block the UI in this event loop
            setTimeout(() => this.indexGeometries(this.geometries), 0)
        }
    },
    mounted() {
        if(this.addGeometriesToMapOnLoad) {
            this.$nextTick(() => {
                this.map = this.$refs.mapContainer.getMap()
                if (this.geometries && this.geometries.length > 0) {
                    this.addGeoJsonMapObjects(null, null, this.allowLayersPostProcessing)
                }
            })
        }
        this.fetchOrderOptions(this.contractId)
    },
    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
    computed: {
        ...mapState({
            selectedMap: state => state.mapSettings.baseMap,
        }),
        sortGeometryAscending() {
            const geometries = this.loadedGeometries;
            return geometries.sort((a, b) => a.showOrder - b.showOrder)
        },

        coordinateSystems() {
            return coordinateSystems
        },

        menuItems() {
            return [
                !this.measureDistanceEnabled ? {
                    text: this.$t('map.measure_distance'),
                    onClick: this.initMeasureDistance
                } : {
                    text: this.$t('map.remove_measurements'),
                    onClick: this.removeMeasureDistances
                },
                !this.polygonDrawing ? {
                    text: this.$t('map.select_assets'),
                    onClick: this.enablePolygonDrawing
                } : {
                    text: this.$t('map.end_selection'),
                    onClick: this.showSelectionSelector
                }
            ]
        },
        hasPolylines() {
            const polylines = this.getPolyline(this.OTHER);
            return polylines && polylines.length > 0;
        },
        addByDrawingEnabled() {
            return this.addPolylineEnabled || this.addPointEnabled
        }
    },
    methods: {
        move(index, direction) {
            const prevGeom = this.sortGeometryAscending[index+ direction];
            let geoIndex = this.loadedGeometries.findIndex(value => value.importedItemIndex === prevGeom.importedItemIndex);

            [this.loadedGeometries[index].showOrder, this.loadedGeometries[geoIndex].showOrder] = [this.loadedGeometries[geoIndex].showOrder, this.loadedGeometries[index].showOrder]
            this.refreshGeoJsonMapObjects(false)
        },

        close: function () {
            this.selectedLayers = []
            this.$emit('close')
        },

        clickDisabledByGeometry(loadedGeometryIndex) {
            // you can only do changes to geometry that is currently selected
            if(this.loadedGeometrySelected !== null) {
                return loadedGeometryIndex !== this.loadedGeometrySelected;
            }
            return false
        },

        handleUndoLastEdit() {
            if (this.addByDrawingEnabled) {
                this.undoLastEdit(this.OTHER);
            } else {
                this.undoLastFeature();

            }
        },

        getAllProperties(item) {
            let allProperties = [];
            item.geometry.features.forEach(feature => {
                Object.keys(feature.properties).forEach(property => {
                    if (!this.excludedFields.includes(property) && !allProperties.includes(property)) {
                        allProperties.push(property);
                    }
                });
            });
            return allProperties
        },

        addNewPropertyToExisting(propertyName, propertyValue) {
            this.newProperties = {
                ...this.newProperties,
                [propertyName]: propertyValue !== undefined ? propertyValue : ""
            };
        },

        removeExistingProperty(propertyKey) {
            let updatedProperties = { ...this.newProperties };
            if (propertyKey in updatedProperties) {
                delete updatedProperties[propertyKey];
            }
            this.newProperties = updatedProperties
        },

        getLoadedGeometryType(loadedGeometry) {
            if (loadedGeometry.type) {
                switch (loadedGeometry.type) {
                    case 'Point':
                        return 'point';
                    case 'LineString':
                        return 'polyline';
                }
            } else if (loadedGeometry.geometry && loadedGeometry.geometry.features.length > 0) {
                const firstFeature = loadedGeometry.geometry.features[0];
                if (firstFeature.geometry && firstFeature.geometry.type) {
                    switch (firstFeature.geometry.type) {
                        case 'Point':
                            return 'point';
                        case 'LineString':
                            return 'polyline';
                    }
                }
            } else if(this.selectedLayers && this.selectedLayers[0]) {
                if(this.selectedLayers[0].layer.geometry.type) {
                    switch (this.selectedLayers[0].layer.geometry.type) {
                        case 'Point':
                            return 'point';
                        case 'LineString':
                            return 'polyline';
                    }
                }
            }
            return null;
        },

        getFeatureGeometryType(feature) {
            if (feature.geometry) {
                switch (feature.geometry.type) {
                    case 'Point':
                        return 'point';
                    case 'MultiPoint':
                        return 'point';
                    case 'LineString':
                        return 'polyline';
                }
            }
            return null;
        },

        getNextItemIndex() {
            let nextIndex = 0
            this.loadedGeometries.forEach(item => {
                if (item.importedItemIndex >= nextIndex) {
                    nextIndex = item.importedItemIndex + 1
                }
            })
            return nextIndex
        },

        getGeometryLength(geometry) {
            let length = 0
            if(geometry && geometry.features && geometry.features.length > 0) {
                geometry.features.forEach(feature => {
                    // LineString
                    if(feature && feature.geometry && feature.geometry.coordinates && feature.geometry.coordinates.length > 0
                        && feature.geometry.type === 'LineString') {
                        length += this.calculateLineStringLength(feature.geometry.coordinates)
                    }
                    // MultiLineString
                    if(feature.geometry.type === 'MultiLineString' && feature && feature.geometry &&
                        feature.geometry.coordinates && feature.geometry.coordinates.length > 0) {
                        feature.geometry.coordinates.forEach(coordinates => {
                            if (coordinates instanceof Array) {
                                length += this.calculateLineStringLength(coordinates)
                            }
                        })
                    }
                })
            }
            return length ? length : 0;
        },


        getLengthInKilometers(geometry) {
            let lengthInKilometers = null
            let geometryLength = this.getGeometryLength(geometry)
            if(geometryLength > 0) {
                geometryLength = geometryLength / 1000
                geometryLength = geometryLength.toFixed(2)
                lengthInKilometers = geometryLength + ' ' + this.$t('contracts.unit_km')
            }
            return lengthInKilometers
        },

        toggleStorages() {
            this.storagesEnabled = !this.storagesEnabled
        },

        showSelectionSelector() {
            let polygon = this.getPolygon() ? this.getPolygon() : null
            // select geometry items inside polygon
            this.selectedGeometryItems = this.selectGeometryAssets(this.loadedGeometries, polygon, this.isPointInPolygon)
            if(this.selectedGeometryItems.length > 0) {
                this.selectedGeometryItems.forEach(selectedItem => {
                    this.onGeoJsonClicked(selectedItem.featureItem, null, selectedItem.featureItem.properties.id, selectedItem.featureItem.properties.importedItemIndex)
                })
                this.refreshGeoJsonMapObjects(false)
            }
            this.selectedGeometryItems = []
            this.clearPolygon()
            this.polygonDrawing = false
        },

        getPolygon: function () {
            // Outer ring needed only
            let rings = this.$refs.mapContainer.getMap().getPolygonBoundaries(this.newPolygonId, this.OTHER)
            if (rings.length > 0) {
                let result = rings[0].map(item => [item['lng'], item['lat']])
                // Must add last to equal first
                result.push([rings[0][0]['lng'], rings[0][0]['lat']])
                return result
            }
            return null
        },

        clearPolygon: function () {
            if (this.newPolygonId > 0) {
                this.$refs.mapContainer.getMap().removePolygon(this.newPolygonId, this.OTHER)
            }
            this.newPolygonId = -1
        },

        hidePolygonDrawing() {
            this.polygonDrawing = false
            this.clearPolygon()
        },

        enablePolygonDrawing() {
            this.newPolygonId = -1
            this.polygonDrawing = true
            this.$bvToast.toast(`${this.$t('map.select_assets_info')}`, {
                title: this.$t('map.select_assets'),
                toaster: 'b-toaster-bottom-center',
                id: 'map-selection-toast',
                autoHideDelay: 10000,
                solid: true
            });
        },

        changeLineWidth() {
            this.refreshGeoJsonMapObjects(false)
        },

        toggleContractAreas() {
            if(this.areasEnabled) {
                this.$refs.mapAreas.clearAreas()
            }
            this.areasEnabled = !this.areasEnabled
        },

        resetGeometry() {
            this.resetFeature()
            this.addRoadByClick = false
            this.loadedGeometrySelected = null
        },

        resetDrawing(resetLoadedGeometriesState = false) {
            // reset loaded geometries state if wanted and canceled
            if(this.preEditedGeometriesState !== null && resetLoadedGeometriesState) {
                this.loadedGeometries = this.preEditedGeometriesState
                // set selected layer as unselected after reset
                this.updateGeometryFeatureSelected(this.loadedGeometries, this.selectedLayers[0].layer.properties.id,
                    this.selectedLayers[0].layer.properties.importedItemIndex, false);
            }
            this.resetGeometry()
            this.removeActiveFeature(this.OTHER)
            this.setRoadAddDisabled()
            this.selectedLayers = []
            this.addPolylineEnabled = false
            this.addPointEnabled = false
            this.loadedGeometrySelected = null
            this.newProperties = null
            this.showAddPropertiesModal = false
            this.preEditedGeometriesState = null
            this.polygonDrawing = false
            this.refreshGeoJsonMapObjects(false)
        },

        setRoadAddEnabled(index) {
            this.resetDrawing()
            this.loadedGeometrySelected = index
            this.addRoadByClick = true
        },

        toggleObservations() {
            this.observationsEnabled = !this.observationsEnabled
        },

        setRoadAddDisabled() {
            this.loadedGeometrySelected = null
            if(this.measureRoadEnabled) {
                this.$refs.measureRoad.reset()
            }
            this.addRoadByClick = false
            this.addRoadBySearch = false
            this.measureRoadEnabled = false
            this.geometryUpdates = 0
        },

        openRoadSearchWizard(index) {
            this.resetDrawing()
            this.addRoadBySearch = true
            this.loadedGeometrySelected = index
            this.showRoadSearchWizard = true
        },

        openAddressSearchWizard(index) {
            this.resetDrawing()
            this.loadedGeometrySelected = index
            this.showAddressSearchWizard = true
        },

        openRoadMeasurement(index) {
            this.resetDrawing()
            this.addRoadByClick = false
            this.loadedGeometrySelected = index
            this.initMeasureRoad()
        },

        closeRoadMeasurement() {
            this.loadedGeometrySelected = null
            this.setRoadAddDisabled()
        },

        setNewGeometryFromMeasure(geometry, properties) {
            let polylines = geometry.map(item => [item['lon'], item['lat']])
            if(polylines && polylines.length > 0) {
                if (this.loadedGeometrySelected !== null) {
                    this.geoJsonToAdd = JSON.stringify(this.addFeatureCollection(polylines, null, null, properties))
                }
                this.setNewGeometryFromSearch(this.loadedGeometrySelected)
            }
        },

        getLayerIndex(layer) {
            return this.loadedGeometries.findIndex(item => item.importedItemIndex === layer.importedItemIndex)
        },

        setNewGeometryByDrawing(index, geometryType) {
            this.resetDrawing()
            this.addRoadByClick = false
            this.setEnabledFeature(geometryType)
            this.loadedGeometrySelected = index
        },

        setNewGeometryFromSearch(importedItemIndex, focusOnLayer = false, pointGeometry = null) {
            if (this.geoJsonToAdd || this.hasPolylines || pointGeometry) {
                let geomObject = null
                if(this.geoJsonToAdd) {
                    geomObject = JSON.parse(this.geoJsonToAdd)
                } else if(this.hasPolylines) {
                    geomObject = this.addFeatureCollection(this.getPolyline(this.OTHER))
                }
                if (this.loadedGeometrySelected !== null) {
                    let filteredGeometry = this.loadedGeometries[this.loadedGeometrySelected];
                    if (filteredGeometry && filteredGeometry.geometry && filteredGeometry.geometry.features && filteredGeometry.geometry.features.length > 0) {
                        let feature = this.addFeature(pointGeometry ? pointGeometry : geomObject.features[0].geometry.coordinates, filteredGeometry.geometry.features.length,
                            filteredGeometry.importedItemIndex, pointGeometry ? null : geomObject.features[0].properties, pointGeometry ? 'point' : 'polyline')
                        filteredGeometry.geometry.features.push(feature)
                    } else {
                        let properties = geomObject.features[0].properties ? geomObject.features[0].properties : {addedOn: new Date().getTime()}
                        filteredGeometry.geometry = this.addFeatureCollection(pointGeometry ? pointGeometry : geomObject.features[0].geometry.coordinates, 0,
                            filteredGeometry.importedItemIndex, properties, pointGeometry ? 'point' : 'polyline')
                    }
                    // indicate that there are updates to geometries
                    this.geometryUpdates++
                    this.refreshGeoJsonMapObjects(focusOnLayer)
                } else {
                    this.handleGeoJson(JSON.stringify(geomObject))
                }
                if (!this.addRoadByClick && !this.addRoadBySearch && !this.measureRoadEnabled) {
                    // Keep the road selection mode enabled
                    this.loadedGeometrySelected = null
                }
                this.geoJsonToAdd = null
                this.showRoadSearchWizard = false
                this.showAddressSearchWizard = false
                this.addPolylineEnabled = false
                this.geometryModified = true
                this.removeActiveFeature(this.OTHER)
                if(this.$refs.measureRoad) {
                    this.$refs.measureRoad.reset()
                }
            }
        },


        addPropertiesToGeometry(editMode = false, selectedLayer = null) {
            const geometry = this.loadedGeometrySelected >= 0 ? this.loadedGeometries[this.loadedGeometrySelected] : null;
            if (geometry) {
                let properties = {};
                if (this.hasGeometryProperties(geometry)) {
                    if(selectedLayer !== null) {
                        properties = selectedLayer.layer.properties
                    } else {
                        properties = geometry.geometryProperties.reduce((obj, prop) => {
                            obj[prop] = "";
                            return obj;
                        }, {});
                    }
                } else if (selectedLayer !== null) {
                    properties = selectedLayer.layer.properties
                } else if(this.hasPropertiesInFeature(geometry)) {
                    properties = Object.keys(geometry.geometry.features[0].properties).reduce((acc, key) => {
                        acc[key] = editMode ? geometry.geometry.features[0].properties[key] : "";
                        return acc;
                    }, {});
                }
                this.newProperties = properties;
                this.showAddPropertiesModal = true;
            }
        },

        addToGeometry() {
            let geometry = null
            if(this.addPolylineEnabled) {
                geometry = this.getPolyline(this.OTHER)
            } else if(this.addPointEnabled) {
                geometry = this.getPoint()
            }
            if (geometry) {
                if (this.loadedGeometrySelected !== null) {
                    let filteredGeometry = this.loadedGeometries[this.loadedGeometrySelected];
                    let geometryType = this.getLoadedGeometryType(filteredGeometry)
                    if (filteredGeometry && filteredGeometry.geometry) {
                        let feature = null
                        if(this.showAddPropertiesModal && this.selectedLayers && this.selectedLayers[0]) {
                            feature = this.addExistingFeature(geometry, this.newProperties, geometryType)
                            feature.properties.selected = false
                        } else {
                            feature = this.addFeature(geometry, filteredGeometry.geometry.features.length, filteredGeometry.importedItemIndex, this.newProperties, geometryType)
                        }
                        filteredGeometry.geometry.features.push(feature)
                    } else {
                        filteredGeometry.geometry = this.addFeatureCollection(geometry, 0, filteredGeometry.importedItemIndex, this.newProperties, geometryType)
                    }
                    this.loadedGeometrySelected = null
                } else {
                    if(this.selectedLayers && this.selectedLayers.length > 0) {
                        let filteredGeometry = this.loadedGeometries.find(geometry => geometry.importedItemIndex === this.selectedLayers[0].importedItemIndex);
                        let geometryType = this.getFeatureGeometryType(this.selectedLayers[0].layer)
                        let feature = this.addExistingFeature(geometry, this.selectedLayers[0].layer.properties, geometryType)
                        feature.properties.selected = false
                        filteredGeometry.geometry.features.push(feature)
                    } else {
                        let geometry = this.addFeatureCollection(geometry, null, null, null, this.addPolylineEnabled ? 'polyline' : 'point')
                        this.handleGeoJson(JSON.stringify(geometry))
                    }
                }
                this.geometryModified = true
                this.resetDrawing()
            }
        },

        hasGeometryProperties(loadedGeometry) {
            return loadedGeometry && loadedGeometry.geometryProperties && loadedGeometry.geometryProperties.length > 0
        },

        hasPropertiesInFeature(loadedGeometry) {
            if (!loadedGeometry || !loadedGeometry.geometry || loadedGeometry.geometry.features.length === 0) {
                return false;
            }
            const properties = loadedGeometry.geometry.features[0].properties;
            if (!properties) {
                return false;
            }
            for (const prop in properties) {
                if (properties.hasOwnProperty(prop) && prop !== 'importedItemIndex' && prop !== 'id') {
                    return true;
                }
            }
            return false;
        },

        editGeometry(geometryType) {
            this.setEnabledFeature(geometryType)
            this.preEditedGeometriesState= JSON.parse(JSON.stringify(this.loadedGeometries))
            this.filterLoadedGeometries()
            this.refreshGeoJsonMapObjects(false)
            if(this.selectedLayers && this.selectedLayers.length > 0) {
                let selectedLayer = this.selectedLayers[0]
                this.loadedGeometrySelected = this.loadedGeometries.findIndex(geometry => geometry.importedItemIndex === selectedLayer.importedItemIndex);
                this.addPropertiesToGeometry(true, selectedLayer)
                this.selectedLayers.forEach(selectedLayer => {
                    if(selectedLayer.layer && selectedLayer.layer.geometry
                        && selectedLayer.layer.geometry.coordinates && selectedLayer.layer.geometry.coordinates.length > 0) {
                        if(geometryType === 'polyline') {
                            selectedLayer.layer.geometry.coordinates.forEach(coordinate => {
                                this.drawPolyline(this.OTHER, {lat: coordinate[1], lng: coordinate[0]})
                            })
                        } else if(geometryType === 'point' && selectedLayer.layer.geometry.coordinates[1] && selectedLayer.layer.geometry.coordinates[0]) {
                            this.drawPoint(this.OTHER, {lat: selectedLayer.layer.geometry.coordinates[1], lng: selectedLayer.layer.geometry.coordinates[0]})
                        }
                    }
                })
            }
        },

        closeGeometryModal() {
            this.showGeometryModal = false;
            this.isEditingGeometry = false;
            this.currentGeometry = {}
        },
        saveGeometry(updatedGeometry) {
            if (this.isEditingGeometry) {
                // Handle updating existing geometry
                const index = this.loadedGeometries.findIndex(g => g.id === updatedGeometry.id);
                if (index !== -1) {
                    this.$set(this.loadedGeometries, index, updatedGeometry);
                }
            } else {
                // Handle adding new geometry
                this.loadedGeometries.push({
                    name: updatedGeometry.name,
                    label: null,
                    geometry: null,
                    color: updatedGeometry.color ? updatedGeometry.color : "#B200FF",
                    type: updatedGeometry.type,
                    geometryProperties: updatedGeometry.properties,
                    calculate: false,
                    visibility: true,
                    lineWidth: 3,
                    addPolylineEnds: false,
                    importedItemIndex: this.getNextItemIndex()
                })
                this.geometryModified = true
            }
            this.closeGeometryModal();
            this.refreshGeoJsonMapObjects(false)
        },

        openEditGeometryModal(loadedGeometry) {
            this.isEditingGeometry = true;
            this.currentGeometry = { ...loadedGeometry };
            this.showGeometryModal = true;
        },

        openDownloadGeometryModal(item) {
            this.showDownloadAsFileModal = true
            this.geometriesForDownload = item
        },

        closeDownloadGeometryModal() {
            this.showDownloadAsFileModal = false
            this.geometriesForDownload = null
        },

        updateGeometryFeatureSelected(array, index, importedItemIndex, value) {
            array.some(loadedGeometry => {
                return loadedGeometry.geometry.features.some((feature) => {
                    if (feature.properties.id === index && feature.properties.importedItemIndex === importedItemIndex) {
                        feature.properties.selected = value;
                        return true;
                    }
                });
            });
        },

        updateAllLabelPositions() {
            if(this.loadedGeometries && this.loadedGeometries.length > 0) {
                for (let i = 0; i < this.loadedGeometries.length; i++) {
                    let visibility = this.loadedGeometries[i] && this.loadedGeometries[i].visibility
                    this.updateLabel(i, visibility)
                }
            }
        },

        findGeometryFeature(array, index, importedItemIndex) {
            let foundFeature;
            array.some(loadedGeometry => {
                return loadedGeometry.geometry.features.some(feature => {
                    if (feature.properties.id === index && feature.properties.importedItemIndex === importedItemIndex) {
                        foundFeature = {feature: feature, loadedGeometry: loadedGeometry};
                        return true;
                    }
                });
            });
            return foundFeature;
        },

        refreshGeoJsonMapObjects(focusOnLayer = true) {
            this.hideImportedObjects()
            this.addGeoJsonMapObjects(focusOnLayer)
        },

        clearGeometrySelection() {
            this.loadedGeometries.forEach(layer => {
                if (layer && layer.geometry) {
                    layer.geometry.features.forEach(geometry => {
                        geometry.properties.selected = false
                    })
                }
            })
            this.selectedLayers = []
        },

        undoLastFeature() {
            if(this.loadedGeometrySelected !== null) {
                let selectedGeometry = this.loadedGeometries[this.loadedGeometrySelected]
                if(selectedGeometry && selectedGeometry.geometry && selectedGeometry.geometry.features && selectedGeometry.geometry.features.length > 0 && this.geometryUpdates > 0) {
                    selectedGeometry.geometry.features.splice(selectedGeometry.geometry.features.length - 1, 1);
                    this.geometryUpdates--
                    this.refreshGeoJsonMapObjects(false)
                }
            }
        },

        onGeoJsonClicked(feature, layer, index, importedItemIndex) {
            if(this.addRoadByClick || this.addRoadBySearch || this.addByDrawingEnabled || this.measureRoadEnabled) {
                return
            }
            let layersUnSelected = false
            // check if there are layers selected
            if(this.selectedLayers.length > 0) {
                // ... loop through them
                 this.selectedLayers.forEach((selectedLayer, idx) => {
                    // check if we click a layer that is already selected on map
                    if(selectedLayer.importedItemIndex === importedItemIndex && selectedLayer.index === index) {
                        // unselect already selected layer by looping through features
                        this.updateGeometryFeatureSelected(this.loadedGeometries, index, importedItemIndex, false);
                        // delete that layer from selectedLayers array
                        this.selectedLayers.splice(idx, 1)
                        // set layerUnSelected value true so selection function is done
                        layersUnSelected = true

                    }
                })
            }
            // if user clicked a layer that is not selected then set it as selected
            if(!layersUnSelected && importedItemIndex !== undefined && index !== undefined) {
                const foundItem = this.findGeometryFeature(this.loadedGeometries, index, importedItemIndex);
                let geometryLayer = {  ...foundItem.loadedGeometry,
                    geometry: {...foundItem.loadedGeometry.geometry, features: [foundItem.feature] },
                    }
                // add selected layer to selectedLayer array with its ids and objectIndex
                this.selectedLayers = [...this.selectedLayers, {
                    layer: foundItem.feature,
                    index: index,
                    importedItemIndex: importedItemIndex,
                    loadedGeometry: geometryLayer
                }];
                // set loadedGeometries property selected true so map highlights it as selected
                let geometryType = this.getFeatureGeometryType(this.selectedLayers[0].layer)
                if(geometryType === 'point' && !this.polygonDrawing) {
                    this.editGeometry(geometryType)
                } else {
                    this.updateGeometryFeatureSelected(this.loadedGeometries, index, importedItemIndex, true);
                }

            }
            // refresh map
            this.hideImportedObjects()
            this.addGeoJsonMapObjects(false, false)
        },

        filterLoadedGeometries() {
            // loop through all loadedGeometries and filter them by selectLayers
            for (let i = 0; i < this.loadedGeometries.length; i++) {
                if(this.loadedGeometries[i].geometry && this.loadedGeometries[i].geometry.features) {
                    this.loadedGeometries[i].geometry.features = this.loadedGeometries[i].geometry.features.filter((feature) => {
                        let shouldKeepFeature = true;
                        this.selectedLayers.forEach((fd) => {
                            if (fd.index === feature.properties.id && fd.importedItemIndex === feature.properties.importedItemIndex) {
                                shouldKeepFeature = false;
                            }
                        });
                        return shouldKeepFeature;
                    });
                }
            }
        },

        removeGeometryPaths() {
            if (this.selectedLayers.length > 0) {
                if(this.activeFeature === 'point') {
                    this.resetDrawing()
                }
                this.filterLoadedGeometries()
                // set selectedLayers to removedGeometries so user can restore them if needed
                this.removedGeometries = this.selectedLayers
                // set selected layer empty
                this.selectedLayers = []
                this.geometryModified = true
                // render map
                this.hideImportedObjects()
                this.addGeoJsonMapObjects(false)
            }
        },

        restoreGeometryPath() {
            // add geojson layer to map
            // check if there are removed geometries
            if (this.removedGeometries.length > 0) {
                // get the latest removed layer from removed geometries
                const removedLayer = this.removedGeometries.pop()
                // check if removed layer is true and has needed content in it
                if(removedLayer && removedLayer.layer) {
                    // set removed layer selected value false that way it is not selected on map when restored
                    removedLayer.layer.properties.selected = false
                    // find the geometry index where the feature was deleted
                    const findImportedItemIndex = (array, importedItemIndex) => {
                        let foundIndex;
                        array.some((loadedGeometry,index) => {
                            if(loadedGeometry.importedItemIndex === importedItemIndex) {
                                foundIndex = index
                                return true
                            }
                        });
                        return foundIndex;
                    };
                    const foundIndex = findImportedItemIndex(this.loadedGeometries, removedLayer.importedItemIndex)
                    // set removed layer back to loadedGeometries to its right place
                    if(typeof foundIndex !== undefined) {
                        this.loadedGeometries[foundIndex].geometry.features.forEach((feature,index) => {
                            feature.properties.id = index
                        })
                        removedLayer.layer.id = this.loadedGeometries[foundIndex].geometry.features.length
                        this.loadedGeometries[foundIndex].geometry.features.push(
                            removedLayer.layer
                        )
                    }
                    this.geometryModified = true
                }
                // refresh map
                this.hideImportedObjects()
                this.addGeoJsonMapObjects(false)
            }
        },

        setColor(color) {
            this.newColor = color
        },

        selectColor(color, index) {
            this.hideImportedObjects()
            this.loadedGeometries[index] = {
                ...this.loadedGeometries[index],
                color: color
            }
            this.geometryModified = true
            this.refreshGeoJsonMapObjects(false)
        },

        onZoomLevelChange() {
            this.updateAllLabelPositions()
        },

        onDrag: function (data) {
            if (this.measureDistanceEnabled) {
                this.updateMeasureDistance(data)
                this.geometryModified = true
            }
        },

        onDragEnd: function (data) {
            if (this.measureDistanceEnabled) {
                this.updateMeasureDistance(data)
                this.geometryModified = true
            }
            if (this.measureRoadEnabled) {
                this.$refs.measureRoad.addMeasurePoint(data)
            }
        },

        onMarkerTap(data) {
            switch (data.type) {
                case this.OBSERVATION:
                    this.showObservationDetails(data)
                    break
                case this.MATERIAL_STORAGE:
                    this.editStorage = JSON.parse(JSON.stringify(this.$refs.storages.getStorage(data.id)))
                    break
            }
        },

        showObservationDetails: function (data) {
            if (this.observationsEnabled) {
                this.$refs.observations.onMarkerTap(data)
            }
        },

        closeAndUpdateStorage() {
            this.editStorage = null
            this.$refs.storages.updateStorages(true)
        },

        mapClicked: function (coord) {
            this.addPoints(this.OTHER, coord)
            if(this.addRoadByClick) {
                this.addGeometryFromClick(coord)
            }
            if (this.measureDistanceEnabled) {
                this.addMeasureDistance(coord)
            }
            if (this.measureRoadEnabled) {
                this.$refs.measureRoad.addMeasurePoint(coord)
            }
            if(this.polygonDrawing) {
                this.addPointToPolygon(coord)
            }
        },

        addPointToPolygon: function (point) {
            // If not exist yet, create one and add to map
            if (this.newPolygonId < 0) {
                this.newPolygonId = Date.now()
                this.$refs.mapContainer.getMap().newPolygon(this.newPolygonId, point.lat, point.lng, this.OTHER)

            } else {
                this.$refs.mapContainer.getMap().addPointToPolygon(this.newPolygonId, point.lat, point.lng, this.OTHER)
            }
        },

        pointerCoordinateMove(coord) {
            this.mouseCoordinate = coord
        },

        onGeoJsonHoverEvent(feature, layer,) {
            this.showLabel(layer, 'roadNameLabel', this.mouseCoordinate)
        },

        onGeoJsonHoverExitEvent() {
            this.map.removeGeoLabels('roadNameLabel')
        },

        addGeometryFromClick(coord) {
            this.fetchRoad(coord)
        },

        mapChanged(map) {
            this.map = map
            // refresh map when map is changed
            this.hideImportedObjects()
            this.addGeoJsonMapObjects(false, true, this.allowLayersPostProcessing)
        },
        hideImportedObjects: function () {
            this.map.hideGeoJsonObjects()
            this.map.removeAllLineEndIndicators()
        },
        shapeFileSelected: function (shpFile, shxFile, dbfFile) {
            this.shpFile = shpFile
            this.shxFile = shxFile
            this.dbfFile = dbfFile
        },
        showGeometryOnMap: function () {
            let formData = new FormData()
            let files = [this.shpFile, this.shxFile, this.dbfFile]
            files.forEach(file => {
                if (file !== undefined && file !== null) {
                    formData.append('files[]', file)
                }
            })
            this.loading = true
            this.restPostWithFile(this.convertShapeToGeoJsonUrl, {}, formData, this.handleGeoJson, this.onFailedRequest)
        },
        handleGeoJson: function(file, name){
            if (file) {
                let geomObject = JSON.parse(file)
                // set artificial ids and indexes when new geojson is added that way we can manipulate it on map
                geomObject.features.forEach((feature, index) => {
                    feature.properties.id = index
                    feature.properties.importedItemIndex = this.loadedGeometries.length
                })
                this.loadedGeometries.push({
                    name: name ? name : this.shpFile ? this.shpFile.name : `uusi_tiegeometria(${this.loadedGeometries.length}).shp`,
                    label: null,
                    geometry: geomObject,
                    color: '#b200ff',
                    calculate: false,
                    visibility: true,
                    lineWidth: 3,
                    showOrder: this.loadedGeometries.length + 1,
                    addPolylineEnds: false,
                    importedItemIndex: this.loadedGeometries.length
                })
                this.refreshGeoJsonMapObjects(false)
                this.addShape = false
                this.addGeoJson = false
                this.geometryModified = true
            }
            this.loading = false
            this.zoomToGeometry(this.loadedGeometries[this.loadedGeometries.length - 1])
        },
        handleVisibilityChange(index) {
            this.loadedGeometries = this.loadedGeometries.map((item, i) =>
                i === index ? { ...item, visibility: !item.visibility } : item
            );
            this.refreshGeoJsonMapObjects(false);
        },
        addGeoJsonFromSearchToMap: function (geoJson) {
            this.geoJsonToAdd = geoJson
            this.setNewGeometryFromSearch(this.loadedGeometrySelected, true)
        },
        addPointFromSearchToMap(coord) {
            if(coord) {
                let coordinate = [coord.lon, coord.lat]
                this.showAddressSearchWizard = false
                this.setNewGeometryByDrawing(this.loadedGeometrySelected, 'point')
                this.drawPoint(this.OTHER, {lat: coordinate[1], lng: coordinate[0]})
                this.geometryModified = true
            }

        },
        showCopyOrderGeometryModal: function () {
            this.showExportGeometriesModal = true
        },
        doCopyOrderGeometry: function (selectedOrderIds) {
            this.showExportGeometriesModal = false
            this.loading = true
            let geometries = JSON.parse(JSON.stringify(this.exportGeometries))
            if (geometries) {
                let convertedGeometries = this.convertGeometriesForServer(geometries)
                convertedGeometries.forEach(item => {
                    item.geometry = typeof item.geometry === 'object' && item.geometry !== null ? JSON.stringify(item.geometry) : item.geometry
                })
                this.restUpdate(this.orderGeometriesUrl + '/copy', {orders: selectedOrderIds, geometries: convertedGeometries},
                    this.exportSuccess, this.fail)
            }
        },
        fetchOrderOptions: function (contractId) {
            this.loading = true
            this.$nextTick(() => {
                this.restFetchParams(this.orderUrl, {contract: contractId}, this.handleOrderOptions)
            })
        },
        handleOrderOptions: function (response) {
            this.orderOptions = response.data
            this.loading = false
        },
        cancelCopying() {
            this.showExportGeometriesModal = false
            this.exportGeometries = []
        },
        exportSuccess: function () {
            this.loading = false
            this.exportGeometries = []
            EventBus.$emit('show-alert', this.$t('orders.geometry_export_successful'), 'success')
        },
        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },
        downloadShape: function (geometryLayer, targetCoordinateSystem) {
            this.loading = true
            this.restPostDownloadFile(this.convertGeoJsonToShapeUrl, {geometry_layer: geometryLayer, target_coordinate_system: targetCoordinateSystem}, this.downloadAsFileSuccess, this.fail)
        },
        downloadAsFileSuccess: function (response) {
            const fileName = this.geometriesForDownload ? this.geometriesForDownload.name + '.zip' : this.$t('orders.shapefile_default_name')
            this.loading = false
            this.downloadZipFile(response, fileName)
        },
        fetchRoad(coord) {
            this.loading = true
            this.restFetchParams(this.roadGeometryLocationUrl, {
                lat: coord.lat,
                lon: coord.lng,
                geoJson: 1,
            }, this.handleResponse, this.onError)
        },

        handleResponse: function (response) {
            this.loading = false
            if (response && response.data) {
                let feature = response.data.features[0]
                if (this.loadedGeometrySelected !== null) {
                    this.geoJsonToAdd = JSON.stringify(this.addFeatureCollection(feature.geometry.coordinates, null, null, feature.properties))
                }
                this.setNewGeometryFromSearch(this.loadedGeometrySelected)
            }
        },
        onFailedRequest: function(){
            this.loading = false
            EventBus.$emit('show-alert', this.$t('import_file_wizard.import_failed'))
        },
        addGeoJsonMapObjects(focusOnLayer = true, updateLabel = true, postProcess = false) {
            const processGeometries = () => {
                this.sortGeometryAscending.forEach((item, index) => {
                    if(updateLabel) {
                        this.updateLabel(index, item.visibility)
                    }
                    if(item.visibility) {
                        this.map.addGeoJsonObjects(JSON.stringify(item.geometry), focusOnLayer, item.color, item.addPolylineEnds,
                            item.transparency ? item.transparency : 1, item.lineWidth ? item.lineWidth : 3)
                    }
                })
            }
            if(postProcess) {
                // Execute the map layers processing after the map is loaded and visible for the user. It will also show the spinner until the processing is done.
                // With huge geoJson the processing can take a while and the user might think that the application is not responding.
                this.observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if(entry.isIntersecting) {
                            processGeometries()
                            this.observer.disconnect();
                        }
                    });
                }, {root: null, threshold: 0.05});
                this.observer.observe(this.$refs.mapObserver);
            } else {
                processGeometries()
            }
        },
        emitGeometry() {
            // Clear selections
            this.clearGeometrySelection()
            // Ensure there are no empty layers
            if (this.hasEmptyLayers()) {
                EventBus.$emit('show-alert', this.$t('orders.error_empty_layer'))
            } else if (this.loadedGeometries) {
                // Emit as json string
                let results = this.convertGeometriesForServer(this.loadedGeometries)
                this.$emit('geometrySelected', results, this.geometryModified)
            }
        },
        indexGeometries(geometries) {
            this.loadedGeometries = []
            let start = this.getHighestArrayValue(geometries, "show_order")
             geometries.forEach((item, index) => {
                // make a true copy of the geometry not just referencing it that way we don't mutate props values
                let changedGeometry = JSON.parse(JSON.stringify(item.geometry))
                // insert temporary ids and object indexes so selection can be possible
                changedGeometry.features.forEach((feature, geomIndex) => {
                    // Convert array to object if feature.properties is empty
                    if (feature.properties && Array.isArray(feature.properties) && feature.properties.length === 0) {
                        feature.properties = {};
                    }

                    // Add new properties
                    feature.properties.id = geomIndex;
                    feature.properties.importedItemIndex = index;
                });
                this.loadedGeometries.push({
                    id: item.id,
                    name: item.name ? item.name : ('Geom' + (index + 1)),
                    label: item.label,
                    geometry: changedGeometry,
                    addPolylineEnds: item.addPolylineEnds ? item.addPolylineEnds : item.add_polyline_ends ? item.add_polyline_ends : false,
                    calculate: item.calculate,
                    color: item.color ? item.color : '#b200ff',
                    lineWidth: item.lineWidth ? item.lineWidth : item.line_width ? item.line_width : 3,
                    visibility: item.visibility ? item.visibility : false,
                    showOrder: !item.show_order ? this.getNumber(start) + 1 : item.show_order,
                    importedItemIndex: index
                });
            })
        },
        getNumber(start) {
            const showOrder = this.getHighestArrayValue(this.loadedGeometries, "showOrder")
            return start < showOrder ? showOrder : start
        },

        cutDecimals(coordinates) {
            if (Array.isArray(coordinates[0])) {
                return coordinates.map(innerArray => this.cutDecimals(innerArray));
            } else {
                // Parse float once to round to desired precision - toFixed returns string, so need to parsefloat again to have the final value as double
                return coordinates.map(coord => parseFloat(parseFloat(coord).toFixed(7)));
            }
        },

        processFeatureCoordinates(features) {
            features.forEach((feature) => {
            feature.geometry.coordinates = this.cutDecimals(feature.geometry.coordinates);
            });
        },

        convertGeometriesForServer(geometries) {
            let results = []
            geometries.forEach((item, index) => {
                let name = item.name ? item.name : ('Geom' + (index+1))
                let label = item.label
                // add geometry where features are not empty
                if(item.geometry.features.length > 0) {
                    if(item.hasOwnProperty('geometryProperties')) {
                        delete item.geometryProperties
                    }
                    let changedGeometry = item.geometry
                    this.processFeatureCoordinates(changedGeometry.features);
                    changedGeometry.features.forEach((feature) => {
                        if(feature.properties.hasOwnProperty('id')) {
                            delete feature.properties.id
                        }
                        if(feature.properties.hasOwnProperty('importedItemIndex')) {
                            delete feature.properties.importedItemIndex
                        }
                    });
                    results.push({
                        name: name,
                        label: label,
                        geometry: changedGeometry,
                        add_polyline_ends: item.addPolylineEnds ? item.addPolylineEnds : false,
                        calculate: item.calculate ? item.calculate : false,
                        visibility: item.visibility ? item.visibility : false,
                        color: item.color ? item.color : '#b200ff',
                        show_order: item.showOrder,
                        line_width: item.lineWidth ? item.lineWidth : 3
                    })
                }
            })
            return results
        },
        importNewGeometry() {
            this.shpFile = null
            this.shxFile = null
            this.dbfFile = null
            this.addShape = true
            this.geometryModified = true
        },
        importNewGeometryGeoJson() {
            this.addGeoJson = true
        },
        addLayerFromGeoJson(geoJson, name) {
            this.handleGeoJson(geoJson, name)
        },
        closeImportFileWizard() {
            this.addGeoJson = false
        },
        removeGeometry(item, index) {
            this.map.removeGeoLabels(this.loadedGeometries[index].name)
            this.loadedGeometries.splice(index, 1)
            this.geometryModified = true
            this.hideImportedObjects()
            this.addGeoJsonMapObjects(false)
            // filter selectedLayers so that all deleted selected layers are gone
            this.selectedLayers = this.selectedLayers.filter(selectedLayer => selectedLayer.importedItemIndex !== index)
            this.resetDrawing()
        },
        deleteGeometriesByPolygon() {
            if(this.loadedGeometries && this.loadedGeometries.length > 0) {
                let newGeometries = []
                this.loadedGeometries.forEach((item) => {
                    if (item.visibility) {
                        let manipulatedGeo = this.getOutsideParts(this.getPolygon(), item.geometry)
                        if (manipulatedGeo && manipulatedGeo.features && manipulatedGeo.features.length > 0) {
                            // Update ids and geometries
                            manipulatedGeo.features.forEach((feature, index) => feature.properties.id = index + 1)
                            item.geometry = manipulatedGeo
                            newGeometries.push(item)
                        }
                    } else {
                        // Nothing to do with invisible, keep as is
                        newGeometries.push(item)
                    }
                })
                this.loadedGeometries = newGeometries
                this.hideImportedObjects()
                this.addGeoJsonMapObjects(false)
            }
            this.clearPolygon()
            this.polygonDrawing = false
        },
        copyAllGeometries() {
            this.$store.commit('storeCopiedGeometries', JSON.stringify(this.loadedGeometries))
            EventBus.$emit('show-alert', this.$t('orders.geometry_copy_successful'), 'success')
        },
        copySelectedGeometry() {
            let copiedLayers = this.getCopiedSelectedLayers()
            if(copiedLayers && copiedLayers.length > 0) {
                this.$store.commit('storeCopiedGeometries', JSON.stringify(copiedLayers))
                EventBus.$emit('show-alert', this.$t('orders.geometry_copy_successful'), 'success')
            }
        },
        copyInsidePolygon() {
            let copiedLayers = this.getCopiedLayersInsidePolygon()
            if(copiedLayers && copiedLayers.length > 0) {
                this.$store.commit('storeCopiedGeometries', JSON.stringify(copiedLayers))
                EventBus.$emit('show-alert', this.$t('orders.geometry_copy_successful'), 'success')
            }
            this.selectedGeometryItems = []
            this.clearPolygon()
            this.polygonDrawing = false
        },
        exportSelectedGeometry() {
            this.exportGeometries = this.getCopiedSelectedLayers()
            if(this.exportGeometries && this.exportGeometries.length > 0) {
                this.showCopyOrderGeometryModal()
            }
        },
        exportGeometryInsidePolygon() {
            this.exportGeometries = this.getCopiedLayersInsidePolygon()
            if(this.exportGeometries && this.exportGeometries.length > 0) {
                this.showCopyOrderGeometryModal()
            }
            this.selectedGeometryItems = []
            this.clearPolygon()
            this.polygonDrawing = false
        },
        exportAllGeometry() {
            this.exportGeometries = JSON.parse(JSON.stringify(this.loadedGeometries))
            if(this.exportGeometries && this.exportGeometries.length > 0) {
                this.showCopyOrderGeometryModal()
            }
        },
        getCopiedSelectedLayers() {
            let copiedLayers = [];
            if(this.selectedLayers && this.selectedLayers.length > 0) {
                this.selectedLayers.forEach(selectedLayer => {
                    if(selectedLayer && selectedLayer.loadedGeometry) {
                        copiedLayers.push(selectedLayer.loadedGeometry)
                    }
                })
            }
            return copiedLayers
        },
        getCopiedLayersInsidePolygon() {
            let copiedLayers = [];
            this.loadedGeometries.forEach(item => {
                if (item.visibility) {
                    let copiedItem = JSON.parse(JSON.stringify(item))
                    let manipulatedGeo = this.getInsidePolygon(this.getPolygon(), item.geometry)
                    if (manipulatedGeo) {
                        copiedItem.geometry = manipulatedGeo
                        copiedLayers.push(copiedItem)
                    }
                }
            });
            return copiedLayers
        },
        pasteGeometry() {
            let geometries = JSON.parse(this.$store.state.copiedGeometries);
            geometries.forEach(element => {
                let found = this.loadedGeometries.find(item =>
                    item.hasOwnProperty('name') &&
                    element.hasOwnProperty('name') &&
                    item.name === element.name
                );

                if (found) {
                    // If geometry with the same name is found, append features to the existing geometry
                    element.geometry.features.forEach((feature, index) => {
                        // Ensure unique IDs for each feature added
                        feature.properties.id = found.geometry.features.length + index;
                        feature.properties.importedItemIndex = found.importedItemIndex;
                        found.geometry.features.push(feature);
                    });
                } else {
                    // If geometry is not found, proceed with your existing logic
                    let counter = 1;
                    while (found) {
                        const lastIndexSeparator = element.name.lastIndexOf('_');
                        if (lastIndexSeparator > 0) {
                            // Check if last part is a number
                            const trailer = element.name.substring(lastIndexSeparator + 1);
                            if (!isNaN(trailer)) {
                                // Update counter and remove trailer
                                counter = parseInt(trailer, 10) + 1;
                                element.name = element.name.substring(0, lastIndexSeparator);
                            }
                        }
                        element.name += `_${counter}`;
                        found = this.loadedGeometries.find(item => item.name === element.name);
                    }

                    // Set artificial IDs and indexes for the new geometry
                    element.geometry.features.forEach((feature, index) => {
                        feature.properties.id = index;
                        feature.properties.importedItemIndex = this.loadedGeometries.length;
                    });
                    element.importedItemIndex = this.loadedGeometries.length;

                    this.loadedGeometries.push(element);
                }

                // Set 'selected' attribute to false for all features
                element.geometry.features.forEach(feature => {
                    feature.properties.selected = false;
                });
            });
            this.geometryModified = true
            this.hideImportedObjects()
            this.addGeoJsonMapObjects()
        },
        isFeatureInView(feature, map) {
            const bounds = map.getViewBounds();
            const geometry = feature.geometry;
            const inViewCoords = [];
            if (geometry.type === 'Polygon') {
                const coords = geometry.coordinates[0];
                for (let i = 0; i < coords.length; i++) {
                    const coord = coords[i];
                    if (coord[0] >= bounds.getWest() &&
                        coord[0] <= bounds.getEast() &&
                        coord[1] >= bounds.getSouth() &&
                        coord[1] <= bounds.getNorth()) {
                        inViewCoords.push(coord);
                    }
                }
            } else if (geometry.type === 'LineString') {
                const coords = geometry.coordinates;
                for (let i = 0; i < coords.length; i++) {
                    const coord = coords[i];
                    if (coord[0] >= bounds.getWest() &&
                        coord[0] <= bounds.getEast() &&
                        coord[1] >= bounds.getSouth() &&
                        coord[1] <= bounds.getNorth()) {
                        inViewCoords.push(coord);
                    }
                }
            } else {
                return false;
            }
            if (inViewCoords.length > 0) {
                const middleIndex = Math.floor(inViewCoords.length * 0.15);
                return inViewCoords[middleIndex];
            } else {
                return false;
            }
        },
        showLabel(layer, labelType, coordinates) {
            if(layer && layer.importedItemIndex !== null) {
                const objIndex = this.loadedGeometries.findIndex((item) => item.importedItemIndex === layer.importedItemIndex)
                let geometry = this.loadedGeometries[objIndex].geometry
                if(geometry) {
                    let roadName = null;
                    geometry.features.forEach(feature => {
                        if (feature && feature.properties.id === layer.id) {
                            Object.keys(feature.properties).some(property => {
                                const lowerCaseRoadNames = this.roadNames.map(road => road.toLowerCase());

                                if (lowerCaseRoadNames.includes(property.toLowerCase())) {
                                    roadName = feature.properties[property];
                                    return true;
                                }
                            });
                        }
                    });
                    if(roadName) {
                        this.map.addGeoLabel(coordinates.lat, coordinates.lng, roadName, labelType)
                    }
                }
            }
        },
        updateLabel(index, visibility = true) {
            if (this.loadedGeometries) {
                // Draw labels on the map
                let labelKey = this.loadedGeometries[index].label ? this.loadedGeometries[index].label : null
                let collectionKey = this.loadedGeometries[index].name
                // Remove previous ones
                this.map.removeGeoLabels(collectionKey)
                if (labelKey && visibility) {
                    // Add labels to map
                    let geomObject = this.loadedGeometries[index].geometry
                    geomObject.features.forEach(feature => {
                        let lat, lon
                        let label = feature.properties[labelKey] ? feature.properties[labelKey].toString() : null
                        if (label && label.length > 0) {
                            let featureInView = this.isFeatureInView(feature, this.map)
                            let defaultPos = {}
                            if (Array.isArray(feature.geometry.coordinates[0])) {
                                if(Array.isArray(feature.geometry.coordinates[0][0])) {
                                    defaultPos.lat = feature.geometry.coordinates[0][0][1]
                                    defaultPos.lon = feature.geometry.coordinates[0][0][0]
                                } else {
                                    defaultPos.lat = feature.geometry.coordinates[0][1]
                                    defaultPos.lon = feature.geometry.coordinates[0][0]
                                }
                                lat = featureInView ? featureInView[1] : defaultPos.lat
                                lon = featureInView ? featureInView[0] : defaultPos.lon
                            } else {
                                lat = feature.geometry.coordinates[1]
                                lon = feature.geometry.coordinates[0]
                            }
                            this.map.addGeoLabel(lat, lon, label, collectionKey)
                        }
                    })
                }
            }
        },
        cancel() {
            this.addShape = false
        },
        hasEmptyLayers() {
            let hasEmptyLayers = false;
            this.loadedGeometries.forEach(item => {
                if (!item.geometry || !item.geometry.features || item.geometry.features.length < 1) {
                    hasEmptyLayers = true
                }
            })
            return hasEmptyLayers
        },

        async showObservationsToRoadGeometry() {
            if (!this.$refs.observations) return;

            const isInSelection = observation => {
                if (!observation.geometry) return false;
                return this.isGeometryInPolygon(observation.geometry, this.getPolygon())
            }
            let observations = this.$refs.observations
                .getVisibleObservations()
                .filter(isInSelection)
            if (observations.length === 0) return;

            let geometry = await this.$refs.observationToRoadGeometryModal.createRoadGeometryFrom(observations, this.getNextItemIndex())
            if (geometry == null) return;
            this.clearPolygon()

            this.loadedGeometries.push(geometry)
            this.geometryModified = true
            this.resetDrawing()
        },
    }
}
</script>
<style>
.layer-name {
    font-size: .9em;
}

.file-dialog {
    background-color: #FFFFFF;
    border: solid 1px #404041;
    border-radius: .2em;
    width: 80%;
    margin: auto;
    padding: 1em;
}

.geom-title {
    padding: .5em;
    text-align: left;
}

.file-title {
    margin-top: .5em;
    font-size: .8em;
    font-weight: bold;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.custom-btn {
    padding: 0.1rem 0.2rem;
    font-size: 0.6rem;
    line-height: 1;
    border-radius: 0.2rem;
}

.editable-input {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 2px 5px;
    outline: none;
    width: 100%;
}

.editable-input:focus {
    border-bottom: 1px solid #007BFF;
}

.editable-input::placeholder {
    color: #999;
}

</style>
