<template>
    <div class="map-item-filter">
        <div
            class="col-sm-12 nopads vehicle-list"
        >
            <span class="vehicle-list-title" @click.stop="toggleList">
              {{ $t('street_lights.title') }}
            </span>
            <div class="vehicle-list-caret" :class="{ caret_open: listOpen }" @click.stop="toggleList">
                <font-awesome-icon icon="caret-down"/>
            </div>
            <div
                class="col-sm-12 nopads map-accordion-content"
                v-bind:class="{ 'map-accordion-open': listOpen }"
            >
                <div v-bind:class="{ 'm-2': listOpen }">
                    <b-row v-bind:class="{ 'multiselect-expand': multiselectOpen }">
                        <multiselect
                            v-model="station"
                            :deselect-label="$t('common.remove_selection')"
                            :select-label="$t('common.select')"
                            class="condition-multiselect"
                            :close-on-select="true"
                            :multiple="false"
                            label="name"
                            track-by="id"
                            :searchable="true"
                            :internal-search="true"
                            :selected-label="$t('common.selected')"
                            :open-direction="'below'"
                            :options="powerStations"
                            :max-height="270"
                            :placeholder="$t('street_lights.select_station')"
                            @open="multiselectOpen = true"
                            @close="multiselectOpen = false"
                        >
                            <template slot="singleLabel" slot-scope="props">
                                <b-row class="nopads">
                                    <b-col style="padding-top: 2px;">
                                        <span class="option__title" style="font-weight: 400">{{ props.option.id }} - {{ props.option.location_name }}</span>
                                    </b-col>
                                </b-row>
                            </template>
                            <template slot="option" slot-scope="props">
                                <b-row class="nopads">
                                    <b-col class="pt-2">
                                        <span class="option__title pt-2">{{ props.option.id }} - {{ props.option.location_name }}</span>
                                    </b-col>
                                </b-row>
                            </template>
                            <span slot="noResult">{{ $t('common.not_found') }}</span>
                            <span slot="noOptions">{{ $t('common.no_results') }}</span>
                        </multiselect>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {restApi} from '../mixins/RestApiMixin'
export default {
    name: "StreetLightMapFilter",
    mixins: [restApi],
    props: {
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            isOpen: true,
            listOpen: false,
            multiselectOpen: false,
            powerStations: [],
            station: null,
        };
    },
    mounted() {
        this.fetchStations()
    },
    methods: {
        toggleList: function () {
            this.listOpen = !this.listOpen
        },
        fetchStations() {
            this.powerStations = []
            let params = {}
            if (this.selectedContractIds && this.selectedContractIds > 0) {
                params.contract = this.selectedContractIds
            }
            this.restFetchParams(this.powerStationMapUrl, params, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch(response) {
            this.loading = false
            if (response) {
                this.powerStations = response.data
            }
        }
    },
    watch: {
        station(val) {
            this.$emit('stationSelected', val)
        },
        selectedContractIds() {
            this.fetchStations()
        }
    },
}
</script>
<style lang="scss" scoped>
.multiselect-expand {
    background: #FAA633;
    height: 300px;
}

.condition-multiselect ::v-deep .multiselect__content-wrapper {
    max-height: unset !important;
    height: 260px !important;
}
</style>
