<template>
    <div class="col-sm-12">
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
        <span class="item-title-add">
          {{ $t('common.add_new') }}
        </span>
                <font-awesome-icon
                    icon="calendar-check"
                    class="card-body-icon"
                />
            </div>
        </div>

        <transition name="fade">
            <div
                v-if="results && results.length"
                class="col-sm-12 data-container"
            >
                <div
                    v-for="result of results"
                    :key="result.id"
                    class="data-list-item"
                >
                    <div class="col-sm-12 item-header">
                        <div class="item-img-cont">
                            <font-awesome-icon icon="coins"/>
                        </div>
                        <div
                            class="item-text-cont"
                        >
                              <span class="item-title">
                                  {{ getTitle(result)}}
                              </span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {priceHelper} from '../mixins/PricingMixin'

export default {
    name: 'PricingModelList',
    mixins: [priceHelper],
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            },
        },
        isAdmin: Boolean
    },
    methods: {
        getTitle: function(item) {
            return process.env.VUE_APP_CURRENCY+ ' / ' + this.unitToText(item.unit1)  + (item.unit2 ? ' / ' + this.unitToText(item.unit2) : '')
        }
    }
}
</script>
