<template>
    <div class="search-form p-2">
        <!-- Material use -->
        <h4 class="ml-4 form-header" style="color: #70a901">{{ $t('spreader_data.report') }}</h4>
        <b-row class="nopads pl-3 pt-3">
            <b-form-checkbox v-model="onlyExported" name="exportSwitch" switch />
             <span>
              {{ $t('spreader_data.only_exported') }}
            </span>
        </b-row>
        <b-row class="nopads">
            <b-col>
                <table class="table mt-4">
                    <tr>
                        <th class="material-table-header">{{ this.$t('material_reports.material') }}</th>
                        <th class="material-table-header">kg</th>
                        <th class="material-table-header">l</th>
                        <th class="material-table-header">{{ this.$t('spreader_data.liquid_dry_material_mass') }} (kg)</th>
                    </tr>
                    <tr v-for="(data, index) in materialsData" v-bind:key="index">
                        <td style="color: #606062; text-transform: uppercase">{{ data.material }}</td>
                        <td>{{ data.weight ? data.weight.toFixed(1) : '' }}</td>
                        <td>{{ data.liters ? data.liters.toFixed(1) : '' }}</td>
                        <td>{{ data.dryWeight ? data.dryWeight.toFixed(1) : '' }}</td>
                    </tr>
                </table>
            </b-col>
            <!-- This needs to be updated as liquids could include kgs and liters -->
            <!--
            <b-col>
                <bar-chart
                    class="routa-bars dont-print"
                    ref="barMaterials"
                    v-if="this.results.length"
                    :labels="labels"
                    :colors="colors"
                    :data="materialsData"
                    :title="$t('spreader_data.amount')"
                ></bar-chart>
            </b-col>
            -->
        </b-row>
    </div>
</template>

<script>
//import BarChart from '../../chart/BarChart'
import {timeUtils} from "../../mixins/TimeUtils";
import {spreaderDataMixin} from "@/components/mixins/SpreaderDataMixin";

export default {
    name: 'spreader-material-charts',
    //components: {BarChart},
    mixins: [timeUtils, spreaderDataMixin],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            labels: [],
            colors: [],
            materialsData: [],
            onlyExported: false,
        }
    },
    mounted: function () {
        this.formatData()
    },
    watch: {
        results() {
            this.formatData()
        },
        onlyExported() {
            this.formatData()
        }
    },
    methods: {
        formatData: function () {
            let materials = []
            let materialWeights = []
            let materialLiters = []
            let materialDryWeights = []
            let labels = []
            let colors = []
            this.results.forEach(item => {
                if (item.export_status === 1 || !this.onlyExported) {
                    // Separate each materials to own column and sum amounts
                    const dryMaterialName = this.getMaterialName(item.material_dry)
                    const dryAmount = item.total_dry_amount
                    const liquidMaterialName = this.getMaterialName(item.material_liquid)
                    const liquidAmount = item.total_liquid_amount
                    if (dryMaterialName) {
                        const key = 'm_' + item.material_dry
                        if (materials[key]) {
                            materials[key] += dryAmount
                        } else {
                            materials[key] = dryAmount
                            colors[key] = this.getMaterialColor(item.material_dry)
                            labels[key] = dryMaterialName
                        }
                        // Material weights
                        if (materialWeights[key]) {
                            materialWeights[key] += dryAmount
                        } else {
                            materialWeights[key] = dryAmount
                        }

                    }
                    if (liquidMaterialName) {
                        const key = 'm_' + item.material_liquid
                        if (materials[key]) {
                            materials[key] += liquidAmount
                        } else {
                            materials[key] = liquidAmount
                            colors[key] = this.getMaterialColor(item.material_liquid)
                            labels[key] = liquidMaterialName
                        }
                        if (item.material_liquid_unit.toLowerCase().localeCompare('kg') === 0) {
                            // Material weights summary
                            if (materialWeights[key]) {
                                materialWeights[key] += liquidAmount
                            } else {
                                materialWeights[key] = liquidAmount
                            }
                        } else if (item.material_liquid_unit.toLowerCase().localeCompare('l') === 0) {
                            // Material liters summary
                            if (materialLiters[key]) {
                                materialLiters[key] += liquidAmount
                            } else {
                                materialLiters[key] = liquidAmount
                            }
                        }
                        // Dry material in liquid summary
                        let dryLiquidAmount = item.liquid_dry_material_mass
                        if (materialDryWeights[key]) {
                            materialDryWeights[key] += dryLiquidAmount
                        } else {
                            materialDryWeights[key] = dryLiquidAmount
                        }

                    }
                }
            })

            this.materialsData = []
            this.labels = []
            this.colors = []
            for (let key in materials) {
                this.materialsData.push({
                        material: labels[key],
                        weight: materialWeights[key],
                        liters: materialLiters[key],
                        dryWeight: materialDryWeights[key]
                })
                this.labels.push(labels[key])
                this.colors.push(colors[key])
            }
        }
    }
}
</script>
<style>
.material-table-header {
    color: #70a901;
    font-style: normal;
}
</style>
