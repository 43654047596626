<template>
    <div v-if="areaWorkCosts && areaWorkCosts.length > 0" class="nopads pt-3">
        <span class="section-title">{{ $t('menu.area_work') }}</span>
        <!-- Summary -->
        <b-row class="nopads" v-if="areaCosts && areaCosts.length > 0">
            <div class="col-12 text-center nopads">
                <table class="table table-sm">
                    <tr class="border-horizontal">
                        <td class="table-title table-caps">{{$t('areas.area')}}</td>
                        <td class="table-value table-caps text-right">{{$t('orders.task_type')}}</td>
                        <td class="table-value table-caps text-right">{{$t('road_reports.count')}}</td>
                        <td class="table-value table-caps text-right">{{ currency }}</td>
                    </tr>
                    <tr v-for="(item, index) in areaCosts" v-bind:key="index">
                      <td class="table-title">{{ item.name }}</td>
                        <td class="table-title text-right">{{ item.task_type }}</td>
                      <td class="table-title text-right">{{ item.pcs }}</td>
                      <td class="table-value">{{ (item.cost/100).toFixed(2) }} {{ currency }}</td>
                  </tr>
                    <tr class="border-horizontal">
                        <td class="table-title table-caps">{{$t('common.total')}}</td>
                        <td class="table-title table-caps"></td>
                        <td class="table-title table-caps"></td>
                        <td class="table-value table-caps">{{ totalCost.toFixed(2).toLocaleString() }} {{ currency }}</td>
                    </tr>
                </table>
            </div>
        </b-row>
        <area-work-list
            :results="areaWorkCosts"
        />
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import AreaWorkList from "@/components/area/areawork/AreaWorkList";

export default {
    name: "ContractReportAreaWorkCosts",
    components: {AreaWorkList},
    mixins: [restApi],
    props: {
        value: {
            type: Number,
            default: null
        },
        searchParams: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data: function () {
        return {
            areaWorkCosts: [],
            areaCosts: [],
            totalCost: 0
        }
    },
    computed: {
        currency() {
            return process.env.VUE_APP_CURRENCY || '€'
        }
    },
    watch: {
        searchParams: function () {
            this.fetchAreaCosts()
        }
    },
    mounted() {
        this.fetchAreaCosts()
    },
    methods: {
        fetchAreaCosts: function () {
            this.areaWorkCosts = []
            if (this.searchParams && this.searchParams.dateTo && this.searchParams.dateFrom && this.searchParams.contract) {
                let params = {
                    from: this.searchParams.dateFrom,
                    to: this.searchParams.dateTo,
                    contract: this.searchParams.contract,
                    cost: 1
                }
                this.restFetchParams(this.areaWorkUrl, params, this.handleSuccess, this.handleFail)
            }
        },
        handleSuccess: function (response) {
            this.areaWorkCosts = response.data
            this.calculateSummaries()
        },
        handleFail: function () {
        },
        calculateSummaries: function () {
            this.totalCost = 0
            this.areaCosts = []
            this.areaWorkCosts.forEach(item => {
                this.totalCost += item.cost
                let areaCostItem = this.areaCosts.find(areaItem => areaItem.id === item.area_id && areaItem.task_type_id === item.task_type_id)
                if (areaCostItem) {
                    areaCostItem.cost += item.cost
                    areaCostItem.pcs++
                } else {
                    this.areaCosts.push({
                        id: item.area_id,
                        name: item.area_name,
                        task_type: item.task_type_name,
                        task_type_id: item.task_type_id,
                        cost: item.cost,
                        pcs: 1})
                }
            })
            this.totalCost = this.totalCost/100
            this.$emit('input', this.totalCost)
        }
    }
}
</script>

<style scoped>

.table-title {
    font-size: .9em;
    text-align: left;
    font-weight: bold;
}

.table-value {
    text-align: right;
}

.table-caps {
    background: #ececec;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
}

/deep/ th {
    font-style: italic;
}


</style>
