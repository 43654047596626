<template>
    <div class="col-sm-12 editor-form">
        <b-form
            class="no-margin"
            @submit.prevent="setImportedContractData"
        >
            <span class="hint-text pl-1 pb-1">{{ $t('contracts.imported_contract_data_fetch_editor_decription')}}</span>
            <span class="item-detail-title pl-1 pt-1">{{ $t('contracts.basic_info') }}</span>
            <b-row v-if="importedContractData.name">
                <b-row>
                    <div class="col-sm-6">
                        <b-form-group
                            class="nopads  pl-1"
                        >
                            <span class="span-title">{{ $t('contracts.name') }}</span>
                            <b-form-input
                                id="name"
                                :value="importedContractData.name"
                                type="text"
                                size="sm"
                                readonly
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-1 mt-4">
                        <b-form-checkbox
                            switch
                            v-model="nameSelected"
                        />
                    </div>
                </b-row>
            </b-row>
            <b-row v-if="importedContractData.start_date || importedContractData.end_date">
                <b-row class="col-12 nopads">
                    <div class="col-sm-5 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('trip_list.start_time') }}</span>
                        <b-form-input
                            id="startDate"
                            :value="importedContractData.start_date"
                            type="text"
                            size="sm"
                            readonly
                        />
                    </div>
                    <div class="col-sm-5 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('trip_list.end_time') }}</span>
                        <b-form-input
                            id="endDate"
                            :value="importedContractData.end_date"
                            type="text"
                            size="sm"
                            readonly
                        />
                    </div>
                    <div class="col-sm-2 nopads text-right">
                        <span class="span-title pb-1">{{ $t('common.update') }}</span>
                        <b-form-checkbox
                            switch
                            v-model="scheduleSelected"
                        />
                    </div>
                </b-row>
            </b-row>
            <hr class="divider"/>
            <span class="item-detail-title pl-1 pt-1">{{ $t('menu.task_types') }}</span>
            <span class="hint-text pl-1 pb-2">{{ $t('contracts.imported_contract_missing_task_types')}}</span>
            <b-row v-if="importedContractData.task_types">
                <b-row class="nopads">
                    <b-form-checkbox-group v-model="selectedTaskTypes" class="checkbox-grid" size="sm">
                        <b-form-checkbox v-for="option in importedContractData.task_types" :key="option.id" :value="option.id" size="sm" class="grid-item">
                            {{ option.name }}
                        </b-form-checkbox>
                    </b-form-checkbox-group>
                </b-row>
            </b-row>
            <b-row v-if="importedContractData.task_types" class="nopads pb-2">
                <div class="col-12 text-right">
                    <b-button
                        class="form-button"
                        size="sm"
                        variant="outline-secondary"
                        :disabled="selectedTaskTypes.length < 1"
                        @click.stop="clearTaskTypeSelection">
                        {{ $t('areas.deselect_all')}}
                    </b-button>
                    <b-button
                        class="form-button"
                        size="sm"
                        variant="outline-primary"
                        :disabled="selectedTaskTypes.length === importedContractData.task_types.length"
                        @click.stop="selectAllTaskTypes">
                        {{ $t('areas.select_all')}}
                    </b-button>
                </div>
            </b-row>
            <hr class="divider"/>
            <span class="item-detail-title pl-1 pt-1">{{ $t('menu.orders') }}</span>
            <b-row v-if="importedContractData.orders">
                <span class="hint-text pb-2">{{ $t('contracts.open_orders_from_imported_contract_data') }}</span>
                <b-row class="nopads">
                    <b-form-checkbox-group v-model="selectedOrders" class="checkbox-grid" size="sm">
                        <b-form-checkbox v-for="option in orderOptions" :key="option.value" :value="option.value" size="sm" class="grid-item" :disabled="!isTaskTypeSelected(option.value)">
                            {{ option.text }}
                        </b-form-checkbox>
                    </b-form-checkbox-group>
                </b-row>
            </b-row>
            <b-row v-if="orderOptions && orderOptions.length > 0" class="nopads pb-2">
                <div class="col-12 text-right">
                    <b-button
                        class="form-button"
                        size="sm"
                        variant="outline-secondary"
                        :disabled="selectedOrders.length < 1"
                        @click.stop="clearOrderSelection">
                        {{ $t('areas.deselect_all')}}
                    </b-button>
                    <b-button
                        class="form-button"
                        size="sm"
                        variant="outline-primary"
                        :disabled="selectedTaskTypes.length !== importedContractData.task_types.length && selectedOrders.length !== orderOptions.length"
                        @click.stop="selectAllOrders">
                        {{ $t('areas.select_all')}}
                    </b-button>
                </div>
            </b-row>

            <b-row v-if="!importedContractData || importedContractData.length === 0">
                    <em>{{ $t('contracts.imported_contract_data_not_found') }}</em>
            </b-row>
            <hr class="divider"/>
            <div class="col-sm-12 text-right mt-3">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    :disabled="!selectionValidated()"
                >
                    {{ $t('contracts.save_data_to_contract') }}
                </b-button>
            </div>
        </b-form>

        <!-- Price list addition -->
        <b-modal
            ref="pricelistModal"
            centered
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            hide-footer
            class="nopads"
            size="lg"
            body-class="p-0"
            :title="$t('pricelist.add')">
            <price-list-editor
                :price-list="newList"
                :hideTitle="true"
                @close="$refs.pricelistModal.hide()"
                @closeAndUpdate="priceListAdded"/>
        </b-modal>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '@/components/mixins/RestApiMixin'
import {timeUtils} from '@/components/mixins/TimeUtils'
import {priceHelper} from "@/components/mixins/PricingMixin";
import PriceListEditor from "@/components/pricingmodels/pricelists/PriceListEditor";
import {EventBus} from '@/event-bus'

export default {
    name: 'contract-data-importer',
    components: {PriceListEditor},
    mixins: [restApi, timeUtils, priceHelper],
    props: {
        contractObject: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            loading: 0,
            contract: null,
            importedContractData: [],
            selectedOrders: [],
            priceLists: [],
            newList: null,
            nameSelected: false,
            scheduleSelected: false,
            selectedTaskTypes: [],
            orderOptions: []
        }
    },
    watch: {
        selectedTaskTypes: function (newVal) {
            // Filter out any IDs in selectedOrders that are not present in selectedTaskTypes
            this.selectedOrders = this.selectedOrders.filter(orderId => newVal.includes(orderId));
            /*
            if (this.allTaskTypes && this.selectedTaskTypes.length !== this.importedContractData.task_types) {
                this.allTaskTypes = false
            }
             */
        }
    },

    mounted() {
        this.fetchImportedContractData()
        this.fetchPriceLists()
        this.newList = this.newPriceList()
    },

    methods: {
        selectAllTaskTypes() {
            this.selectedTaskTypes = this.importedContractData.task_types.map(item => item.id)
        },
        selectAllOrders() {
            this.selectedOrders = this.orderOptions.map(item => item.value)
        },
        clearTaskTypeSelection() {
            this.allTaskTypes = false
            this.selectedTaskTypes = []
        },
        clearOrderSelection() {
            this.allOrders = false
            this.selectedOrders = []
        },
        fetchPriceLists: function () {
            this.loading++
            this.restFetch(this.priceListUrl,this.handlePriceListResponse)
        },
        handlePriceListResponse: function (response) {
            this.loading--
            this.priceLists = response.data
        },
        priceListAdded: function (newPriceList) {
            this.priceList = newPriceList.id
            this.fetchPriceLists()
            this.showPriceListEditor = false
            this.newList = this.newPriceList()
            this.$refs.pricelistModal.hide()
        },
        showPriceListEditor: function () {
            this.$refs.pricelistModal.show()
        },
        selectionValidated() {
            return this.scheduleSelected || this.nameSelected || this.selectedTaskTypes.length > 0
        },
        isTaskTypeSelected: function (id) {
            return this.selectedTaskTypes.find(item => item === id) !== undefined
        },
        fetchImportedContractData() {
            this.loading++
            this.restFetchParams(this.contractImportUrl, {contract: this.contractObject.id}, this.handleResponse, this.fail)
        },
        handleResponse(response) {
            this.loading--
            if(response.data) {
                if(response.data.orders && response.data.orders.length > 0) {
                    response.data.orders.map(order => {
                        if(order.hasOwnProperty('price_list')) {
                            order.price_list = null
                        }
                    })
                }
                this.importedContractData = response.data
                this.initOrderOptions()
            }
        },

        initOrderOptions() {
            this.orderOptions = []
            this.importedContractData.orders.forEach(order => this.orderOptions.push( {
                "text": order.contract_task_type.task_type.name + ' (' + order.unit_price +' '+process.env.VUE_APP_CURRENCY+')',
                "value": order.contract_task_type.task_type.id
            }))
        },

        setImportedContractData() {
            this.loading++
            let json = this.setValues()
            this.restUpdate(this.contractImportUrl, json , this.handleSetImportedContractData, this.setImportedContractDataFail)
        },
        handleSetImportedContractData() {
            this.loading--
            EventBus.$emit('show-alert', this.$t('common.alert_update_success'), 'success')
            this.$emit('closeSuccessModal')
        },
        setValues() {
            let contractChanges = {}
            if(this.importedContractData.name && this.nameSelected) {
                contractChanges.name = this.importedContractData.name
            }
            if(this.importedContractData.start_date && this.scheduleSelected) {
                contractChanges.start_date = this.importedContractData.start_date
            }
            if(this.importedContractData.end_date && this.scheduleSelected) {
                contractChanges.end_date = this.importedContractData.end_date
            }
            if(this.selectedTaskTypes.length > 0) {
                contractChanges.task_types = this.selectedTaskTypes
            }
            if(this.selectedOrders > 0) {
                contractChanges.orders = this.importedContractData.orders
                    .filter(order => this.selectedOrders.includes(order.contract_task_type.task_type.id));
            }
            return {
                contract: this.contractObject.id,
                contract_changes: contractChanges
            }
        },
        setImportedContractDataFail() {
            this.loading--
            EventBus.$emit('show-alert', this.$t('contracts.imported_contract_data_update_failed'))
        },
        fail: function () {
            this.loading--
            EventBus.$emit('show-alert', this.$t('contracts.imported_contract_data_fetching_failed'))
        },
    }
}
</script>

<style scoped>
.checkbox-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
    gap: 10px; /* Adjust spacing between checkboxes */
}

.grid-item {
    display: flex;
    align-items: center;
    width: 100%; /* Ensures full width within grid cell */
}
</style>
