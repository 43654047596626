<template>
    <div>
        <div class="col-12 mt-1" style="background: #f6f5f1; border: 1px #e8e6de solid;">
            <b-row class="nopads pt-1 pb-1">
                <b-col class="nopads pl-1 item-detail-title">
                    {{ $t('daily_summary.price_list_weight_combined') }}
                </b-col>
                <b-col class="nopads pl-1 item-detail-text">
                    {{ totalWeight.toFixed(2) }} {{ results.weight_unit }}
                </b-col>
            </b-row>
            <b-row class="nopads pt-1">
                <b-col class="nopads pl-1 item-detail-title">
                    {{ $t('daily_summary.price_list_sum_combined', {currency: currency}) }}
                </b-col>
                <b-col class="nopads pl-1 item-detail-text">
                    {{ totalSum.toFixed(2) }} {{ currency }}
                </b-col>
            </b-row>
        </div>

        <b-table
            v-if="fields && fields.length > 0"
            size="sm"
            class="mt-3"
            show-empty
            :small="true"
            :responsive="true"
            :fields="fields"
            :items="tableItems"
            style="cursor: pointer"
        >
            <template slot="empty">
                <div class="text-center my-2">
                    {{ $t('common.no_results') }}
                </div>
            </template>
            <template v-slot:cell(price)="row">
                {{ (row.item.price * results.multiplier / 100).toFixed(2) }}
            </template>
            <template v-slot:cell(sum)="row">
                {{ row.value && row.value > 0 ? row.value.toFixed(2) : '' }}
            </template>
        </b-table>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {deliveryNoteHelper, units} from "@/components/mixins/DeliveryNoteMixin";
import {restApi} from "@/components/mixins/RestApiMixin";

export default {
    name: "DeliveryNotePriceList.vue",
    mixins: [deliveryNoteHelper, units, restApi],
    props: {
        notes: {
            type: Array,
            default() {
                return []
            }
        },
        summaryItem: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            fields: [],
            tableItems: [],
            totalWeight: 0,
            totalSum: 0,
            results: {},
            loading: false
        }
    },
    computed: {
        currency() {
            return process.env.VUE_APP_CURRENCY || '€'
        }
    },
    mounted() {
        this.fetchDeliveryPriceList()
    },
    methods: {
        initTableItems: function () {
            this.tableItems = []
            this.totalWeight = 0
            this.totalSum = 0
            let lastPrice = null
            this.results && Object.keys(this.results).length !== 0 && this.notes && this.notes.length > 0 &&
            this.results.prices.forEach(item => {
                if (item.distance_to) {
                    let weight = this.getWeight(item.distance_from, item.distance_to, this.notes,
                        this.results.weight_unit, this.results.distance_unit)
                    let sum = 0
                    if (item.distance_to) {
                        sum = this.getSum(weight, item.price * this.results.multiplier/ 100)
                    }
                    this.totalWeight += weight
                    this.totalSum += sum
                    this.tableItems.push(
                        {
                            distance_from: item.distance_from,
                            distance_to: item.distance_to,
                            multiplier: (item.price * this.results.multiplier / 100).toFixed(3),
                            weight: weight.toFixed(2),
                            sum: sum
                        }
                    )
                    lastPrice = item
                } else if (lastPrice) {
                    let lastRowSum = 0
                    let lastRowWeight = 0
                    this.notes.forEach(note => {
                        let distance = 0
                        let sum = 0
                        if (note.trips) {
                            note.trips.forEach(trip => {
                                distance += trip.mode === 3 ? trip.length : 0
                            })
                        }
                        if (distance >= item.distance_from && note.unit === 't') {
                            sum += this.getSumWithLast(note.amount, distance, item.distance_from, item.price, this.results.multiplier, lastPrice )
                            lastRowSum += sum
                            lastRowWeight += note.amount
                            this.totalWeight += note.amount
                            this.totalSum += sum
                        }
                    })
                    this.tableItems.push(
                        {
                            distance_from: item.distance_from,
                            distance_to: item.distance_to,
                            multiplier: (item.price * this.results.multiplier / 100).toFixed(3),
                            weight: lastRowWeight.toFixed(2),
                            sum: lastRowSum
                        }
                    )
                }
            })
        },
        initFieldItems() {
            this.fields = [
                {key: 'distance_from', label: this.$t('daily_summary.price_list_from') +
                    ' ' + '(' + this.results.distance_unit + ')', sortable: true},
                {key: 'distance_to', label: this.$t('daily_summary.price_list_to') +
                        ' ' + '(' +  this.results.distance_unit + ')', sortable: true},
                {key: 'multiplier', label: this.$t('daily_summary.price_list_multiplier'), sortable: true},
                {key: 'weight', label: this.results.weight_unit && (this.results.weight_unit === units.TONS ?
                        this.$t('daily_summary.price_list_tons') :
                        this.$t('daily_summary.price_list_kilograms')) + ' (' + this.results.weight_unit + ')', sortable: true},
                {key: 'sum', label: this.$t('daily_summary.price_list_sum', {currency: process.env.VUE_APP_CURRENCY}), sortable: true}
            ]
        },
        getWeight(distance_from, distance_to, notes, weight_unit, distance_unit) {
            let amount = 0
            notes && notes.length > 0 && notes.forEach(note => {
                let tripLengthSum = 0;
                note.trips && note.trips.length > 0 && note.trips.forEach(trip => {
                    if (trip.mode === 3) {
                        tripLengthSum += distance_unit === 'm' ? this.kilometersToMeters(trip.length) : trip.length
                    }
                })
                if (tripLengthSum > distance_from && tripLengthSum <= distance_to ||
                    tripLengthSum > distance_from && distance_to === undefined) {
                    if(note.unit === weight_unit) {
                        amount += note.amount
                    } else if(note.unit === units.TONS && weight_unit === units.KILOGRAMS) {
                        amount += this.tonsToKilograms(note.amount)
                    } else if(note.unit === units.KILOGRAMS && weight_unit === units.TONS) {
                        amount += this.kilogramsToTons(note.amount)
                    }
                }
            })
            return amount
        },
        getSum(weight, multiplier) {
            return multiplier * weight;
        },
        getSumWithLast(weight, distance, distanceFrom, price, multiplier, lastPrice) {
            let distanceDelta = distance - distanceFrom
            let basePriceValue = (weight * lastPrice.price * multiplier) / 100
            let addedPrice = (weight * price * multiplier * Math.ceil(distanceDelta)) / 100
            return basePriceValue + addedPrice
        },
        fetchDeliveryPriceList() {
            if (this.summaryItem) {
                this.loading = true
                this.restFetch(
                    this.orderUrl + "/" + this.summaryItem.order.id + "/pricelist",
                    this.fetchPriceListSuccess,
                    this.fetchFail
                )
            }
        },
        fetchPriceListSuccess: function (response) {
            this.results = response.data
            this.loading = false
            this.initTableItems()
            this.initFieldItems()
        },
        fetchFail: function () {
            this.loading = false
        }
    }
}
</script>
