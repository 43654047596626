<template>
    <div class="favorite-container">
        <div class="text-center nopads">
            <h4 class="cut-text wrap-text" style="color: #7FC000;">{{ contract ? contract.name : ' '}}</h4>
            <span class="item-detail-title nopads" style="color: #9e9d9d; font-size: .9em">{{ contract ? contract.customer.name : ' '}}</span>
        </div>
        <!-- DATA PART -->
        <div style="height: 10em; padding-top: 1em; padding-bottom: 1em">
            <span v-if="loading">{{ $t('common.loading')}}</span>
            <div v-else-if="contract" class="text-center">
                <span class="favorite-content-title">{{ $t('favorites.distance_yesterday')}}</span>
                <span class="favorite-content-text">{{ totalDistance }} ({{ workDistance }}) km</span>
                <span class="favorite-content-title">{{ $t('favorites.cost_yesterday')}}</span>
                <span class="favorite-content-text">{{ cost }} {{ currency }}</span>
            </div>
        </div>
        <!-- BOTTOM BUTTON ROW -->
        <div class="nopads text-center">
            <b-row v-if="!loading && contract" class="favorite-buttons">
                <b-tooltip :target="contract.id + 'info'" triggers="hover" placement="top">{{ $t('favorites.contract_info')}}</b-tooltip>
                <b-tooltip :target="contract.id + 'report'" triggers="hover" placement="top">{{ $t('daily_summary.title')}}</b-tooltip>
                <b-tooltip :target="contract.id + 'route'" triggers="hover" placement="top">{{ $t('menu.fleet_trips')}}</b-tooltip>
                <b-tooltip :target="contract.id + 'diary'" triggers="hover" placement="top">{{ $t('contracts.external.work_diary')}}</b-tooltip>
                <b-tooltip :target="contract.id + 'remove'" triggers="hover" placement="top">{{ $t('favorites.remove')}}</b-tooltip>
                <b-col><font-awesome-icon :id="contract.id + 'info'" class="favorite-button" icon="info-circle" @click="$emit('shortcutClick', 'contracts', contract)"/></b-col>
                <b-col><font-awesome-icon :id="contract.id + 'report'" class="favorite-button" icon="tasks" @click="$emit('shortcutClick', 'tripReports', contract.id)"/></b-col>
                <b-col><font-awesome-icon :id="contract.id + 'route'" class="favorite-button" icon="route" @click="$emit('shortcutClick', 'searchTrips', contract)"/></b-col>
                <b-col><font-awesome-icon :id="contract.id + 'diary'" class="favorite-button" icon="clock" @click="$emit('shortcutClick', 'workDiary', contract)"/></b-col>
                <b-col><font-awesome-icon :id="contract.id + 'remove'" class="favorite-button" icon="trash" @click="$emit('remove')"/></b-col>
            </b-row>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'ContractFavorite',
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            contract: null,
            totalDistance: "-",
            workDistance: "-",
            cost: "-",
            loading: false,
            error: false
        }
    },
    props: {
        id: {
            type: Number,
            default: null
        }
    },
    computed: {
        currency() {
            return process.env.VUE_APP_CURRENCY || '€'
        }
    },
    watch: {
        id: function() {
            this.contract = null
            this.fetchContract()
        }
    },
    mounted: function () {
        this.fetchContract()
    },

    methods: {

        fetchContract: function () {
            this.restFetchParams(this.contractUrl, {id: this.id}, this.handleResponse)
        },

        handleResponse: function (response) {
            if (response && response.data && response.data.length === 1) {
                this.contract = response.data[0]
                this.fetchKeyFigures()
            } else {
                this.error = true
            }
            this.loading = false
        },

        fetchKeyFigures: function () {
            let params = {
                contract: this.id,
                from: this.getYesterday(),
                to: this.getYesterday()
            }
            this.restFetchParams(this.contractDailyTripSummaryFiguresUrl, params, this.parseFigures)
        },

        parseFigures(response) {
            if (response && response.data) {
                this.cost = response.data.cost
                this.totalDistance = parseFloat(response.data.work_distance) + parseFloat(response.data.resettle_distance)
                this.totalDistance = this.totalDistance.toFixed(1)
                this.workDistance = parseFloat(response.data.work_distance).toFixed(1)
            }
        }

    }
}
</script>
