<template>
    <div class="col-12 nopads chart-area">
        <!-- Costs -->
        <b-row class="nopads mb-4">
            <div class="col-12 text-center mt-2">
                <span class="report-title mt-0">{{ $t('daily_summary.cost_title') }}</span>
                <span class="report-descr mt-0">{{ $t('daily_summary.cost_descr') }}</span>
            </div>
            <div class="col-sm-6">
                <b-card
                    header-bg-variant="dark"
                    header-text-variant="white"
                    border-variant="dark"
                    align="center"
                    :header="$t('daily_summary.cost_total')"
                    style="margin-top: 4em; margin-bottom: 5em">
                    <b-card-text class="summary-number">{{ parseFloat(totalCost).toFixed(2) }} {{ currency }}</b-card-text>
                </b-card>
            </div>
            <div class="col-md-6 nopads">
                <bar-chart
                    class="routa-bars dont-print"
                    style="margin-top: 1em"
                    ref="barCosts"
                    v-if="this.costData.length"
                    :labels="labels"
                    :colors="colors"
                    :data="costData"
                    :title="titleCost"
                ></bar-chart>
                <img
                    class="print-only routa-bars-print"
                    id="barCostImage"
                />
            </div>

            <b-row class="col-12 mt-4">
                <!-- Driving distance -->
                <b-col sm="4" class="text-center">
                    <span class="report-title mt-0">{{ $t('trip_list.trip_length') }}</span>
                    <span class="report-descr mt-0">{{ $t('trip_list.distance_descr') }}</span>
                    <div class="report-total">
                        {{ this.getReportTotal(this.distanceData) }}
                    </div>
                </b-col>
                <!-- Road distance covered -->
                <b-col sm="4" class="text-center">
                    <span class="report-title mt-0">{{ $t('trip_list.road_distance') }}</span>
                    <span class="report-descr mt-0">{{ $t('trip_list.road_distance_descr') }}</span>
                    <div class="report-total">
                        {{ this.getReportTotal(this.roadDistanceData) }}
                    </div>
                </b-col>
                <!-- Driving Time -->
                <b-col sm="4" class="text-center">
                    <span class="report-title mt-0">{{ $t('trip_list.duration') }}</span>
                    <span class="report-descr mt-0">{{ $t('trip_list.time_descr') }}</span>
                    <div class="report-total">
                        {{ this.getReportTotal(this.durationData) }}
                    </div>
                </b-col>
            </b-row>
            <!-- Driving distance -->
            <b-row class="col-12">
            <b-col sm="4" class="nopads chart-container">
                <bar-chart
                    class="routa-bars dont-print"
                    ref="barDistance"
                    v-if="this.distanceData.length"
                    :labels="labels"
                    :colors="colors"
                    :data="distanceData"
                    :title="titleDistance"
                ></bar-chart>
                <img
                    class="print-only routa-bars-print"
                    id="barDistanceImage"
                />
            </b-col>
            <!-- Road distance covered -->
            <b-col sm="4" class="nopads chart-container">
                <bar-chart
                    class="routa-bars dont-print"
                    ref="barRoadDistance"
                    v-if="this.roadDistanceData.length"
                    :labels="labels"
                    :colors="colors"
                    :data="roadDistanceData"
                    :title="titleRoadDistance"
                ></bar-chart>
                <img
                    class="print-only routa-bars-print"
                    id="barRoadDistanceImage"
                />
            </b-col>
            <!-- Driving Time -->
            <b-col sm="4" class="nopads chart-container">
                <bar-chart
                    class="routa-bars dont-print"
                    ref="barDuration"
                    v-if="this.durationData.length"
                    :labels="labels"
                    :colors="colors"
                    :data="durationData"
                    :title="titleDuration"
                    unit="km"
                ></bar-chart>
                <img
                    class="print-only routa-bars-print"
                    id="barDurationImage"
                />
            </b-col>
        </b-row>
        <b-row class="col-12 mt-4">
            <!-- Vehicle reservation time -->
            <b-col sm="4" class="nopads chart-container">
                <span class="report-title mt-0">{{ $t('trip_list.vehicle_allocation') }}</span>
                <span class="report-descr mt-0">{{ $t('trip_list.allocation_descr') }}</span>
                <div class="report-total">
                    {{ this.getReportTotal(this.reservationData) }}
                </div>
            </b-col>
        <!-- Average speeds -->
        <b-col sm="4" class="nopads chart-container">
            <span class="report-title mt-0">{{ $t('trip_list.avg_speed') }}</span>
            <span class="report-descr mt-0">{{ $t('trip_list.avg_speed_descr') }}</span>
        </b-col>
        <!-- CO2 emissions -->
        <b-col sm="4" class="nopads chart-container">
            <span class="report-title mt-0">{{ $t('co2_emissions.title') }}</span>
            <span class="report-descr mt-0">{{ $t('co2_emissions.description') }}</span>
            <div class="report-total pb-1">
                {{ this.getEmissionTotal(this.emissionData) }}
            </div>
        </b-col>
        </b-row>
            <b-row class="col-12 mt-4">
                <!-- Vehicle reservation time -->
                <b-col sm="4" class="nopads chart-container">
                    <bar-chart
                        class="routa-bars dont-print"
                        ref="barReservation"
                        v-if="this.reservationData.length"
                        :labels="labels"
                        :colors="colors"
                        :data="reservationData"
                        :title="titleReservation"
                        unit="min"
                    ></bar-chart>
                    <img
                        class="print-only routa-bars-print"
                        id="barReservationImage"
                    />
                </b-col>
                <!-- Average speeds -->
                <b-col sm="4" class="nopads chart-container">
                    <bar-chart
                        class="routa-bars dont-print"
                        ref="barAverageSpeeds"
                        v-if="this.averageSpeedData.length"
                        :labels="labels"
                        :colors="colors"
                        :data="averageSpeedData"
                        :title="titleAverageSpeed"
                        unit="min"
                    ></bar-chart>
                    <img
                        class="print-only routa-bars-print-large"
                        id="barAverageSpeedsImage"
                    />
                </b-col>
                <!-- CO2 emissions -->
                <b-col sm="4" class="nopads chart-container">
                    <bar-chart
                        class="routa-bars dont-print"
                        ref="barEmission"
                        v-if="this.emissionData.length"
                        :labels="labels"
                        :colors="colors"
                        :data="emissionData"
                        :title="titleEmission"
                    />
                    <img
                        class="print-only routa-bars-print"
                        id="barEmissionImage"
                        alt="CO2-emissions bar chart"
                    />
                </b-col>
            </b-row>
        </b-row>
    </div>
</template>

<script>
import BarChart from '../chart/BarChart'
import {timeUtils} from "../mixins/TimeUtils";

export default {
    name: 'contract-trip-summary-charts',
    components: {BarChart},
    mixins: [timeUtils],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            labels: [],
            colors: [],
            costData: [],
            totalCost: 0,
            distanceData: [],
            roadDistanceData: [],
            durationData: [],
            reservationData: [],
            averageSpeedData: [],
            emissionData: [],
            titleCost: this.$t('daily_summary.vehicle_costs'),
            titleDistance: this.$t('trip_list.trip_length'),
            titleRoadDistance: this.$t('trip_list.road_length'),
            titleDuration: this.$t('trip_list.duration'),
            titleReservation: this.$t('trip_list.vehicle_allocation'),
            titleAverageSpeed: this.$t('trip_list.avg_speed'),
            titleEmission: this.$t('co2_emissions.chart_info'),
        }
    },
    computed: {
        currency() {
            return process.env.VUE_APP_CURRENCY || '€'
        }
    },
    mounted: function () {
        this.formatData()
    },
    watch: {
        results() {
            this.formatData()
        },
    },
    methods: {
        formatData: function () {
            let distances = []
            let roadDistances = []
            let durations = []
            let reservations = []
            let averageSpeeds = []
            let costs = []
            let emissions = []
            let labels = []
            let colors = []
            this.results.forEach(item => {
                    let key = null
                    if (item.order) {
                        key = item.order.task_type.id
                    } else {
                        key = 'no_order'
                        labels[key] = this.$t('daily_summary.no_order')
                        colors[key] = 'rgba(0,0,0,0.28)'
                    }
                    if (!distances.hasOwnProperty(key)) {
                        distances[key] = {value: 0, unit: 'km'}
                        roadDistances[key] = {value: 0, unit: 'km'}
                        durations[key] = {value: 0, unit: 'min'}
                        reservations[key] = {value: 0, unit: 'min'}
                        averageSpeeds[key] = {distance: 0, duration: 0}
                        costs[key] = {value: 0, unit: process.env.VUE_APP_CURRENCY}
                        emissions[key] = {value: 0, unit: 'g'}
                    }
                    if (item.order) {
                        labels[key] = item.order.task_type.name
                        colors[key] = item.order.task_type.color
                    }
                    let distance = item.work_distance + item.resettle_distance
                    distances[key].value += distance
                    roadDistances[key].value += item.covered_road_distance ? item.covered_road_distance : 0
                    durations[key].value += Math.round(item.duration / 60)
                    reservations[key].value += item.vehicle_allocation_time
                    averageSpeeds[key].distance += distance
                    averageSpeeds[key].duration += item.duration
                    costs[key].value += item.cost ? item.cost : 0
                    if (item.co2_emissions && item.co2_emissions > 0) {
                        emissions[key].value += item.co2_emissions * distance
                    }
                }
            )
            // Round costs to two decimals
            costs.forEach(item => {
                item.value = parseFloat(item.value).toFixed(2)
            })
            this.costData = []
            this.distanceData = []
            this.roadDistanceData = []
            this.durationData = []
            this.emissionData = []
            this.reservationData = []
            this.labels = []
            this.colors = []
            this.totalCost = 0;
            for (let key in distances) {
                distances[key].value = distances[key].value.toFixed(2)
                roadDistances[key].value = roadDistances[key].value ? roadDistances[key].value.toFixed(2) : 0
                this.costData.push(costs[key])
                this.totalCost += parseFloat(costs[key].value)
                this.distanceData.push(distances[key])
                this.roadDistanceData.push(roadDistances[key])
                this.durationData.push(durations[key])
                this.reservationData.push(reservations[key])
                this.emissionData.push(emissions[key])
                this.averageSpeedData.push({
                    value: (averageSpeeds[key].distance / (averageSpeeds[key].duration / 3600)).toFixed(1),
                    unit: 'km/h'
                })
                this.labels.push(labels[key])
                this.colors.push(colors[key])
            }
        },
        generateImages: function () {
            document.getElementById("barCostImage").src = this.$refs.barCosts.getChartAsImage();
            document.getElementById("barDistanceImage").src = this.$refs.barDistance.getChartAsImage();
            document.getElementById("barRoadDistanceImage").src = this.$refs.barRoadDistance.getChartAsImage();
            document.getElementById("barDurationImage").src = this.$refs.barDuration.getChartAsImage();
            document.getElementById("barReservationImage").src = this.$refs.barReservation.getChartAsImage();
            document.getElementById("barAverageSpeedsImage").src = this.$refs.barAverageSpeeds.getChartAsImage();
            if (this.$refs.barEmission) {
                document.getElementById("barEmissionImage").src = this.$refs.barEmission.getChartAsImage();
            }
        },
        getReportTotal: function(reportData) {
            let total = reportData.reduce((acc, dataValue) => parseInt(dataValue.value) + acc, 0);
            let unit = reportData[0] && reportData[0].unit ? reportData[0].unit : '';
            if (unit === 'min'){
                total = this.getWorkTime(total)
                unit = ''
            }
            return `${this.$t('daily_summary.report_total')} ${total} ${unit}`;
        },
        getEmissionTotal: function(reportData) {
            const total = reportData.reduce((acc, dataValue) => parseInt(dataValue.value) + acc, 0)/1000;
            return     `${this.$t('daily_summary.report_total')} ${total.toFixed(1)} kg`;
        }
    }
}
</script>
<style>
.report-total {
    font-weight: bold;
    font-size: 0.9em;
    color: #5b5b5c;
    text-align: center !important;
    margin: .2em;
}
</style>
