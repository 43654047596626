<template>
    <base-modal @cancel="$emit('close')" style="z-index: 50">
        <div class="details-container">
            <div
                class="details dynamic_width"
            >
                <div class="coordinate-map"
                     style="height:80vh;"
                >
                    <map-container
                        ref="map"
                        :lat="coordinate ? coordinate.lat : 62.7"
                        :lon="coordinate ? coordinate.lng : 25.2"
                        :center="mapCenter"
                        @onMapClicked="mapClicked"
                        @onDragEnd="onDragEnd"
                        @onMapChanged="onMapChanged"
                    />
                    <div class="map-see-through-container pl-1 pb-1 pr-1" >
                        <b-col style="width: 3em">
                            <b-row v-if="!observationMode || observationMode && savedGeometry.point" class="draw-button-row">
                                <b-btn
                                    class="draw-button"
                                    @click.stop="setEnabledFeature('point')"
                                    :class="{ active: addPointEnabled }"
                                    variant="info">
                                    <font-awesome-icon icon="map-pin"
                                    />
                                    <div class="btn-text">{{ $t('common.point') }}</div>
                                </b-btn>
                            </b-row>
                            <b-row v-if="!observationMode || observationMode && savedGeometry.line_string" class="draw-button-row">
                                <b-btn class="draw-button"
                                       variant="info"
                                       :class="{ active: addPolylineEnabled }"
                                       @click.stop="setEnabledFeature('polyline')">
                                    <img
                                        src="@/assets/draw_multiline.svg"
                                        style="width: 1em; height: 1em;"
                                        alt="Draw multiline"/>
                                    <div class="btn-text">{{ $t('common.line') }}</div>
                                </b-btn>
                            </b-row>
                            <b-row v-if="!observationMode" class="draw-button-row">
                                <b-btn
                                    variant="info"
                                    @click.stop="setEnabledFeature('polygon')"
                                    :class="{ active: addPolygonEnabled }"
                                    class="draw-button">
                                    <font-awesome-icon icon="draw-polygon"/>
                                    <div class="btn-text">{{  $t('common.area') }}</div>
                                </b-btn>
                            </b-row>
                        </b-col>
                    </div>
                </div>
                <div class="col-sm-12 button-container pt-2 pb-0 mb-0">
                    <b-button
                        variant="secondary"
                        class="result-button"
                        size="sm"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        variant="outline-info"
                        class="result-button"
                        size="sm"
                        :disabled="!drawnFigure"
                        @click.stop="undoLastEdit(type)"
                    >
                        {{ $t('common.undo') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="result-button"
                        size="sm"
                        :disabled="!drawnFigure"
                        @click.stop="saveFeature"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </div>
        </div>
        <b-modal
            v-model="showConfirmRemoveFeature"
            size="sm"
            centered
            ok-variant="success"
            :hide-header="true"
            :cancel-title="$t('common.cancel')"
            :ok-title="$t('common.continue')"
            @ok="continueRemoveFeature(type)"
            @hide="cancelRemoveFeature()"
        >
            <div class="modal-body">
                <span>{{ $t('map.remove_drawn_geometry') }}</span>
            </div>
        </b-modal>
    </base-modal>
</template>

<script>

import BaseModal from '../BaseModal'
import {geometryMixin} from '../mixins/GeometryMixin'
import {mapHelper} from '../mixins/MapMixin'
import {restApi} from '../mixins/RestApiMixin'
import MapContainer from "../map/MapContainer";
import {measureDistanceMixin} from "../mixins/MeasureDistanceMixin";

export default {
    name: "DrawGeometryWizard",
    components: {MapContainer, BaseModal},
    mixins: [
        mapHelper,
        restApi,
        measureDistanceMixin,
        geometryMixin
    ],
    props: {
        type: {
            type: Number,
            required: true
        },
        coordinate: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        observationMode: {
            type: Boolean,
            default: false
        },
        savedGeometry: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            geometry: null,
            drawnFigure: false,
            addPolygonEnabled: false,
            addPolylineEnabled: false,
            addPointEnabled: true,
            map: null,
            mapCenter: null,
            newPolygonId: -1,
            newPolyLineId: -1,
            newPointId: -1,
            polylinePoints: [],
            activeFeature: "",
            newFeature: "",
            removeFeature: true,
            showConfirmRemoveFeature: false,
        }
    },
    mounted: function () {
        if (this.map && this.type) {
            this.map.zoomToGroup(this.type)
            return
        }
        if(this.savedGeometry && this.savedGeometry.geometry) {
            this.mapCenter = this.savedGeometry.geometry.point
        } else if(this.getMapSettingsIn(['center'])){
            this.mapCenter = this.getMapSettingsIn(['center'])
        } else if (this.user && this.user.company.location) {
            this.mapCenter = {
                lon: this.user.company.location.x,
                lat: this.user.company.location.y,
            }
        } else if (this.center) {
            this.mapCenter = this.center
        }
    },
    methods: {
        onMapChanged(map) {
            this.map = map;
            if(this.savedGeometry){
                this.geometry = this.savedGeometry
                this.drawExistingFeature(this.map.getMarkerStore(), this.type)
                this.map.zoomToGroup(this.type)
            }
            else {
                this.map.zoomToPosition(this.mapCenter.lat, this.mapCenter.lng)
            }
        },
        onDragEnd(coord) {
            if(coord && coord.position) {
                const coordConversion = {lat: coord.position.y, lng: coord.position.x}
                if (this.newPointId > 0) {
                    this.geometry = coordConversion
                } else {
                    this.addPoints(this.type, coordConversion)
                }
            }
        },
        mapClicked: function (coord) {
            if (this.newPointId < 0) {
                this.addPoints(this.type, coord)
            }
        },
        saveFeature() {
            if (!this.addFeatureToAssignment()) {
                this.$emit('close')
            }
        }
    }
}

</script>

<style>
@import "../../styles/modal.css";
.map-see-through-container {
    padding-top: 1em;
    background: rgba(255, 255, 255, 0);
    z-index: 2;
    position: relative;
    width: fit-content;
}
.draw-button{
    min-width: 3.5em;
}
.draw-button-row{
    width: inherit;
}
.btn-text {
    font-size: .7em;
    font-weight: bold;
    color: #FFFFFF;
}
</style>
