<template>
    <div class="nopads">
        <b-form-group
            class="title nopads"
            label-for="order"
        >
            <span v-if="showTitle" class="span-title">{{ $t('observations.order') }}</span>
            <b-form-select
                id="order"
                v-model="selectedOrder"
                size="sm"
                :options="getAvailableOrders()"
                :disabled="loading > 0"
                @input="handleInput"
            >
                <template slot="first">
                    <option :value="null">
                        {{ $t('contracts.select_order') }}
                    </option>
                </template>
            </b-form-select>
        </b-form-group>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {orderMixin} from "../mixins/OrderMixin"

export default {
    name: 'ContractOrderFilter',
    mixins: [restApi, orderMixin],
    props: {
        value: {
            type: Object,
            default: function () {
                return null
            }
        },
        preSelectedOrder: {
            type: Number,
            default: function () {
                return null
            }
        },
        contractId: {
            type: Number,
            default: function () {
                return null
            }
        },
        openOnly: {
            type: Boolean,
            default: false
        },
        openOrPlannedOnly: {
            type: Boolean,
            default: false
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        onlyEly: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            loading: 0,
            orders: [],
            orderOptions:[],
            selectedOrder: null,
        }
    },
    watch: {
        isEly: function () {
            if(this.selectedOrder && this.selectedOrder.external_tasks === 0) {
                this.selectedOrder = null
            }
        },
        contractId: function () {
            this.fetchOrders()
        },
        preSelectedOrder: function () {
            this.selectedOrder = this.preSelectedOrder
            if (!this.selectedOrder && this.orders && this.orders.length > 0) {
                let exists = this.orders.find(item => item.id === this.selectedOrder)
                if (!exists) {
                    this.selectedOrder = null
                }
            }
        },
        selectedOrder() {
            if (this.orders && this.orders.length > 0) {
                this.$emit('input', this.selectedOrder ? this.orders.find(item => item.id === this.selectedOrder) : null)
            }
        }
    },

    mounted() {
        this.selectedOrder = this.preSelectedOrder
        if (this.contractId) {
            this.fetchOrders()
        }
    },

    methods: {
        getAvailableOrders: function () {
            return this.onlyEly ? this.orderOptions.filter(order => order.isEly) : this.orderOptions
        },
        handleInput: function (e) {
            if (this.orders && this.orders.length > 0) {
                this.$emit('orderSelected', this.orders.find(item => item.value === e))
            }
        },

        fetchOrders: function () {
            this.orders = []
            if (this.contractId) {
                this.loading++
                let params = {
                    contract: this.contractId
                }
                if (this.openOnly) {
                    params.open = 1
                }
                if(this.openOrPlannedOnly) {
                    params.open_or_planned = 1
                }
                this.restFetchParams(this.orderUrl, params, this.handleOrders)
            }
        },

        handleOrders: function (response) {
            this.loading--
            if (response && response.data) {
                this.orders = response.data
                this.orders.forEach(order => this.orderOptions.push(
                    {
                        value: order.id,
                        text: order.id + ' - ' + this.getOrderStringWithInfo(order),
                        isEly: order.external_tasks.length > 0
                    }
                ))
            }
            if (this.selectedOrder) {
                let exists = this.orders.find(item => item.id === this.selectedOrder)
                if (!exists) {
                    this.selectedOrder = null
                } else {
                    this.$emit('orderSelected', this.orders.find(item => item.id === this.selectedOrder))
                }
            }
        },

        reset: function () {
            this.orders = []
            this.selectedOrder = null
            this.orderOptions = []
        },

        getOrderInfo(order) {
          if (order.info) {
              return order.info.length > 40 ? (order.info.substr(0,35) + '...') : order.info
          }
          return ''
        }
    }
}
</script>
